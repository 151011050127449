import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import {
  apiKey,
  fetch_warehouse,
  check_product_transfer,
  TransferInventory,
  transfer_child_product_fetch,
} from "../../../Api";
import { debounce } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AssignWare from "./Inventory_ProductsModal";
import Error from "../../../Messages/Error";
import Quagga from "quagga";
import Webcam from "react-webcam";
import soundEffect from "../../../Assets/sounds/beep.mp3";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import toast, { Toaster } from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { IoMdArrowRoundBack } from "react-icons/io";
import SmallItemTransfer from "./SmallItemTransfer";
import { IoAlertCircleOutline } from "react-icons/io5";
import { Label, Popup } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { BsUpcScan } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import SubmitLoader from "../../../Components/SubmitLoader";
import { useScreenWidth } from "../../../useScreenWidth";
import { FaTrashAlt } from "react-icons/fa";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function AddStockTransfer() {
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const isWideScreen = useScreenWidth();
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [warehouses, seswarehouses] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState("");
  const [transferdate, setTransferDate] = useState(getCurrentDate());
  const searchListRef = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const navigate = useNavigate();
  const [warehouse, seswarehouse] = useState("");
  const [swarehouse, setSWarehouse] = useState("");
  const webcamRef = useRef(null);
  const [scanData, setScanData] = useState("");
  const [scanInputData, setScanInputData] = useState("");
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);
  const [curr_series, setCurr_series] = useState("");
  const [openRemoveP, setOpenRemoveP] = useState(false);
  const inputRef = useRef(null);

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  useEffect(() => {
    fetchWarehouse();
  }, []);

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        seswarehouses(response.data.warehouses);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
      });
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
  };

  const handleDetected = useCallback((result) => {
    const scannedCode = result.codeResult.code;
    setScanData(scannedCode);
    setIsCameraOpen(false);
  }, []);

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const handleScanChange = (e) => {
    const scanquery = e.target.value;
    if (scanquery.length <= 5) {
      return;
    }
    if (scanquery.length > 5) {
      if (isValidProductSku(scanquery)) {
        let seriesExists = false;
        const [productSku, series] = scanquery.split("_");

        setScanInputData(scanquery);
        for (const product of selectedProducts) {
          if (
            product.sku === productSku &&
            product.additional_info?.includes(parseInt(series, 10))
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(productSku);
          setAdditionalInfoToRemove(parseInt(series, 10));
          setOpenRemoveP(true);
          return;
        } else {
          fetchProductData(scanquery);
        }

        // Call handleSeriesClick if newValue contains "_"
        if (scanquery.includes("_")) {
          handleSeriesClick(productSku, parseInt(series, 10));
        }
      } else {
        playSound();
      }
    }
  }; // Adjust the delay as needed

  const handleInputChange = (scanData) => {
    const newValue = scanData;

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    if (newValue.length > 5) {
      if (isValidProductSku(newValue)) {
        let seriesExists = false;
        const [productSku, series] = newValue.split("_");

        for (const product of selectedProducts) {
          if (
            product.sku === productSku &&
            product.additional_info?.includes(parseInt(series, 10))
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(productSku);
          setAdditionalInfoToRemove(parseInt(series, 10));
          setOpenRemoveP(true);
          return;
        } else {
          fetchProductData(newValue);
          setIsCameraOpen(false);
          playSound();
        }

        // Call handleSeriesClick if newValue contains "_"
        if (newValue.includes("_")) {
          handleSeriesClick(productSku, parseInt(series, 10));
        }
      } else {
        playSound();
      }
    }
    setScanData("");
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      const data = {
        product_value: sku,
        warehouse_pk: parseInt(warehouse),
      };
      axios
        .post(check_product_transfer, data, {
          headers: {
            "API-Key": apiKey,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const productData = response.data.product;

          if (!productData || productData.length === 0) {
            toast.error("No product data available");
            return; // Exit early if there's no data
          }
          addScannedProduct(sku, productData);
          // Clear the scan data
          setScanData("");
          playSound();

          setKhaliKaroKey((prevKey) => prevKey + 1);
        })
        .catch((error) => {
          // console.log(error);
          toast.error(error.response.data.message);
          playSound();
          setKhaliKaroKey((prevKey) => prevKey + 1);
          setScanData("");
        });
    }, 400),
    [warehouse, selectedProducts]
  );

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      const [baseSku, additionalInfoString] = sku.split("_");
      const additionalInfo = parseInt(additionalInfoString, 10);

      if (!productData) {
        toast.error("Product not found for the scanned SKU.");
        return;
      }
      let splitSeries = sku.split("_")[1];
      let splittedSeries = parseInt(splitSeries);

      setSelectedProducts((prev) => {
        const existingProduct = prev.find((item) => item.sku === baseSku);

        if (existingProduct) {
          // Check if splittedSeries already exists in selectedSeries
          if (existingProduct.selectedSeries.includes(splittedSeries)) {
            setSkuToRemove(baseSku);
            setAdditionalInfoToRemove(splittedSeries);
            setOpenRemoveP(true);
            return prev.map((item) =>
              item.sku === baseSku
                ? {
                    ...item,
                    quantity: item.quantity + 1, // Increment quantity
                  }
                : item
            );
          } else {
            // If it does not exist, add it to selectedSeries
            return prev.map((item) =>
              item.sku === baseSku
                ? {
                    ...item,
                    selectedSeries: [
                      ...(item.selectedSeries || []),
                      splittedSeries,
                    ],
                  }
                : item
            );
          }
        } else {
          // If the product does not exist, add a new entry
          toast.success("Product added successfully!");
          return [
            ...prev,
            {
              sku: baseSku,
              product_name: productData.product_name,
              product_sku: productData.product_sku,
              selectedSeries: [splittedSeries],
              in_stock: productData.in_stock || 0,
              product_status: 1,
            },
          ];
        }
      });
    }, 300),
    [] // dependencies
  );

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0, // Needs to be 0 when used with `decodeSingle`
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"],
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected]);

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const fetchProducts = useCallback(() => {
    axios
      .get(transfer_child_product_fetch, {
        params: {
          search: searchQuery,
          warehouse_pk: parseInt(warehouse),
        },
        headers: {
          "API-Key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const fetchedProducts = response.data.data;
        // console.log(
        //   "Response of check_product_transfer",
        //   response.data.data
        // );

        // Extract specific keys from the fetched products
        const selectedKeysProducts = fetchedProducts.map((product) => ({
          product_name: product.product_name,
          product_sku: product.product_sku,
          selectedSeries: [],
          sku: product.product_sku.split("-")[1],
          in_stock: product.in_stock,
          available_warehouses: product.available_in_other,
          product_status: 0,
        }));

        setProducts(selectedKeysProducts);

        setDisplay(true);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [searchQuery, warehouse]);

  const handleSearchChange = useCallback(
    debounce((e) => {
      const query = e.target.value;
      setSearchQuery(query);
    }, 500), // Adjust the delay as needed
    [warehouse, warehouse]
  );

  useEffect(() => {
    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery, fetchProducts]);

  const handleCheckboxChange = (product) => {
    // console.log(product.in_stock);
    if (product.in_stock < 1) {
      toast.error("Stock not available !");
      return;
    }

    setSelectedProducts((prevSelected) => {
      const productInSelected = prevSelected.find(
        (p) => p.product_sku === product.product_sku
      );

      if (productInSelected) {
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        return [
          ...prevSelected,
          { ...product, selectedSeries: [], smallQty: 0 },
        ];
      }
    });
  };

  const handleSeriesClick = (productSku, series) => {
    setSelectedProducts((prevSelected) => {
      return prevSelected.map((product) => {
        if (product.product_sku === productSku) {
          const isSelected = product.selectedSeries.includes(series);
          return {
            ...product,
            selectedSeries: isSelected
              ? product.selectedSeries.filter((s) => s !== series)
              : [...product.selectedSeries, series],
          };
        }
        return product;
      });
    });
  };

  let selectedWarehouse = "";
  const [openConfirmChange, setOpenConfirmChange] = useState(false);
  const handleWarehouseChange = (event) => {
    selectedWarehouse = event.target.value;
    if (selectedProducts.length > 0) {
      // toast.error("Oops! You can't change warehouse");
      setOpenConfirmChange(true);

      return;
    }
    seswarehouse(selectedWarehouse);
  };

  const handleCloseConfirmChange = () => {
    setOpenConfirmChange(false);
  };

  const handleConfirmChange = () => {
    setSelectedProducts([]);
    setOpenConfirmChange(false);
    setSearchQuery("");
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
      setSearchQuery("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCloseRemove = () => {
    setOpenRemoveP(false);
  };

  const handleRemoveProduct = () => {
    let ProductSku = "KTT-" + scanInputData.split("_")[0];
    let seriesToRemove = scanInputData.split("_")[1];
    let seriesToRemoveInt = parseInt(seriesToRemove);

    setSelectedProducts((prevSelected) => {
      return (
        prevSelected
          .map((product) => {
            if (product.product_sku === ProductSku) {
              const updatedSeries = product.selectedSeries.filter(
                (series) => series !== seriesToRemoveInt
              );

              // If the updated series is empty, return null
              if (updatedSeries.length === 0) {
                return null;
              }

              return {
                ...product,
                selectedSeries: updatedSeries,
              };
            }
            return product;
          })
          // Filter out any products that are null (which indicates they have an empty series)
          .filter((product) => product !== null)
      );
    });

    setOpenRemoveP(false);
  };

  const [p_sku, setSKU] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [inventory, setInven] = useState([]);
  const [unallocated, setUnallocated] = useState(0);

  const [refreshKey, setRefreshKey] = useState(0);

  const handleTransfer = () => {
    setSubmitLoader(true);
    let formattedData = {
      target_warehouse: parseInt(warehouse),
      // source_warehouse: parseInt(swarehouse),
      user_name: userDetails.name,
      note: notes,
      transfer_date: transferdate,
      transfer_data: selectedProducts.map((product) => {
        return {
          product_sku: product.product_sku,
          quantity:
            product.selectedSeries && product.selectedSeries.length > 0
              ? product.selectedSeries.length
              : product.transferDetails && product.transferDetails.length > 0
              ? product.transferDetails.reduce(
                  (sum, detail) => sum + (detail.quantity || 0),
                  0
                )
              : 0,
          selected_series: product.selectedSeries || [],
          inventory_detail: product.transferDetails || [],
        };
      }),
    };

    // console.log(formattedData);

    try {
      axios
        .post(TransferInventory, formattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            navigate("/stock_transfer");
            setSubmitLoader(false);
            setProducts([]);
            setDisplay(false);
            setSelectedProducts([]);
            setRefreshKey((prevKey) => prevKey + 1);
            seswarehouse("");
            setNotes("");
            toast.success(`Stock Transferred Successfully!`);
          } else {
            toast.error(response.data.message);
            setSubmitLoader(false);
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
          setSubmitLoader(false);
        });
    } catch (error) {
      // toast.error(error.response.data.message);
      console.log("Something went wrong!");
      setSubmitLoader(false);
    }
  };

  const [KhaliKaroKey, setKhaliKaroKey] = useState(0);
  const [inputStatus, setInputStatus] = useState(0);

  const [ScanFocus, setScanFocus] = useState(1);

  const handleScanButtonClick = () => {
    setInputStatus((prevStatus) => (prevStatus === 0 ? 1 : 0));
    setScanFocus((prevValue) => (prevValue === 0 ? 1 : 0)); // Toggle between 1 and 0
    setKhaliKaroKey((prevKey) => prevKey + 1);
  };

  // Focus the input when itemDisplay is 0
  useEffect(() => {
    if (ScanFocus === 0) {
      inputRef.current.focus(); // Autofocus the input field
    }
  }, [ScanFocus]); // Depend on itemDisplay so it runs when itemDisplay changes

  // console.log(selectedProducts);
  const [openSmallModal, setOpenSmallModal] = useState(false);
  const [smallWarehouses, setSmallWarehouses] = useState("");
  const [smallSKU, setSmallSKU] = useState("");
  const handleWareSmallItem = (available_warehouses, product_sku) => {
    setOpenSmallModal(true);
    setSmallWarehouses(available_warehouses);
    setSmallSKU(product_sku);
  };

  const handleRemoveItem = (itemToRemove) => {
    const updatedProducts = selectedProducts.filter(
      (item) => item !== itemToRemove
    );
    setSelectedProducts(updatedProducts);
  };
  // console.log(selectedProducts);

  return (
    <>
      {submitLoader && <SubmitLoader />}
      <Toaster position="top-right" />

      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <SmallItemTransfer
        openSmallModal={openSmallModal}
        setOpenSmallModal={setOpenSmallModal}
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        smallWarehouses={smallWarehouses}
        smallSKU={smallSKU}
      />

      <AssignWare
        key={refreshKey}
        openModal={openModal}
        setOpenModal={setOpenModal}
        inventory={inventory}
        p_sku={p_sku}
        setInven={setInven}
        unallocated={unallocated}
        setUnallocated={setUnallocated}
      />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/stock_transfer")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography color="inherit">Transfer</Typography>

              <Typography sx={{ color: "text.primary" }}>
                New Transfer
              </Typography>
            </Breadcrumbs>
          </div>

          {isCameraOpen ? (
            <>
              <div className="scannerDiv">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  width={1920}
                  height={220}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode: "environment",

                    border: "none",
                  }}
                  style={{
                    marginBottom: "12px",
                    border: "3px dashed green",
                    padding: "0",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseCamera}
                >
                  Close
                </Button>
              </div>
            </>
          ) : null}

          <div className="card p-3 my-1">
            <div className="row">
              <div className="col-lg-2 my-2">
                <FormControl fullWidth size="small" required>
                  <InputLabel id="vendor_name">Target Warehouse</InputLabel>
                  <Select
                    labelId="vendor_name"
                    label="Target Warehouse"
                    required
                    value={warehouse}
                    onChange={handleWarehouseChange} // Call the function directly
                    // onChange={(e) => seswarehouse(e.target.value)}
                  >
                    <MenuItem value="">--Select--</MenuItem>
                    {warehouses.map((ware) => (
                      <MenuItem
                        key={ware.id}
                        value={ware.id}
                        disabled={ware.id === swarehouse}
                      >
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-2 my-2">
                <TextField
                  size="small"
                  type="date"
                  fullWidth
                  value={transferdate}
                  onChange={(e) => setTransferDate(e.target.value)}
                />
              </div>

              <div className="col-lg-4 my-2">
                <TextField
                  size="small"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  style={{ width: "100%" }}
                  label="Notes"
                ></TextField>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-2 my-2 " style={{ textAlign: "right" }}>
                <Button
                  disabled={
                    !warehouse ||
                    !selectedProducts ||
                    selectedProducts.length === 0
                  }
                  onClick={handleTransfer}
                  variant="contained"
                >
                  Confirm Transfer
                </Button>
              </div>
            </div>
          </div>

          <div className="row my-1">
            <div className="col-lg-3 my-2">
              <div className="card p-3">
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <TextField
                    key={KhaliKaroKey}
                    inputRef={inputRef}
                    fullWidth
                    size="small"
                    disabled={!warehouse}
                    label={
                      inputStatus === 0
                        ? "Search Small Product"
                        : "Scan Product"
                    }
                    variant="outlined"
                    color={inputStatus === 0 ? "primary" : "success"}
                    onInput={
                      inputStatus === 0 ? handleSearchChange : handleScanChange
                    }
                  />

                  {inputStatus === 0 ? (
                    <BsUpcScan
                      onClick={() => {
                        handleOpenCamera();
                        handleScanButtonClick();
                      }}
                      style={{
                        fontSize: "30px",
                        padding: "8px",
                        height: "37px",
                        width: "38px",
                        cursor: "pointer",
                        border: "1px solid #1565c0",
                        borderRadius: "5px",
                        marginLeft: "5px",
                        color: "#1565c0",
                      }}
                    />
                  ) : null}

                  {inputStatus === 1 ? (
                    <IoSearch
                      onClick={() => {
                        handleCloseCamera();
                        handleScanButtonClick();
                      }}
                      style={{
                        fontSize: "30px",
                        padding: "8px",
                        height: "37px",
                        width: "38px",
                        cursor: "pointer",
                        border: "1px solid red",
                        borderRadius: "5px",
                        marginLeft: "5px",
                        color: "red",
                      }}
                    />
                  ) : null}
                </div>

                {displaySearch && displaySearch ? (
                  <>
                    <div id="searchList" ref={searchListRef}>
                      {products.map((product, index) => (
                        <>
                          <div
                            className="card  p-3"
                            style={{ display: "flex" }}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isProductSelected(product)}
                                  onChange={() => handleCheckboxChange(product)}
                                  name="check"
                                />
                              }
                              label={
                                product.product_sku +
                                " : " +
                                product.product_name
                              }
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="col-lg-9 my-2">
              <div className="card p-3">
                {isWideScreen ? (
                  <>
                    <div className="responsive-table1">
                      <table className="ui table celled" id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>#</th>
                            <th>SKU</th>
                            <th align="left">Product</th>
                            <th align="left">Available Qty</th>
                            <th align="left">Quantity</th>
                            <th align="right">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedProducts && selectedProducts.length > 0 ? (
                            <>
                              {selectedProducts.map((product, index) => (
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      product.product_status === 0
                                        ? "#e0f1ff"
                                        : "transparent",
                                  }}
                                >
                                  <td>{index + 1}</td>

                                  <td style={{ width: "6.5vw" }}>
                                    {product.product_sku || ""}
                                  </td>
                                  <td align="left">
                                    {product.product_name || ""}
                                  </td>

                                  <td align="left">{product.in_stock || 0}</td>

                                  <td align="left">
                                    {product.product_status === 0 ? (
                                      <>
                                        {product.transferDetails &&
                                        product.transferDetails.length > 0
                                          ? product.transferDetails.reduce(
                                              (sum, detail) =>
                                                sum + (detail.quantity || 0),
                                              0
                                            )
                                          : 0}
                                      </>
                                    ) : (
                                      <>
                                        {(product.selectedSeries &&
                                          product.selectedSeries.length) ||
                                          0}

                                        <Popup
                                          style={{
                                            backgroundColor: "white",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            color: "black",
                                            padding: "5px",
                                            marginLeft: "20px",
                                            marginTop: "-6px",
                                            cursor: "pointer",
                                            position: "absolute",
                                          }}
                                          on="click"
                                          pinned
                                          content={`${product.selectedSeries}`}
                                          trigger={
                                            <IoAlertCircleOutline
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                fontSize: "18px",
                                                color: "red",
                                              }}
                                            />
                                          }
                                        />
                                      </>
                                    )}
                                  </td>

                                  <td align="center" style={{ width: "2vw" }}>
                                    <>
                                      {product.product_status === 0 ? (
                                        <>
                                          <Button
                                            onClick={() =>
                                              handleWareSmallItem(
                                                product.available_warehouses,
                                                product.product_sku
                                              )
                                            }
                                            variant="outlined"
                                            size="small"
                                          >
                                            Select
                                          </Button>

                                          <Button
                                            style={{ marginLeft: "5px" }}
                                            onClick={() =>
                                              handleRemoveItem(product)
                                            }
                                            variant="outlined"
                                            color="error"
                                            size="small"
                                          >
                                            Remove
                                          </Button>
                                        </>
                                      ) : null}
                                    </>
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td colSpan="6">
                                  <div className="notfound_img_div">
                                    <div className="notfound_img"></div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card p-2 responsive-mobile-table">
                      {selectedProducts.length > 0 ? (
                        <>
                          {selectedProducts &&
                            selectedProducts.map((data) => (
                              <>
                                <div
                                  className="product-card"
                                  style={{
                                    backgroundColor:
                                      data.product_status === 0
                                        ? "#e0f1ff"
                                        : "transparent",
                                  }}
                                  onClick={() =>
                                    data.product_status === 0
                                      ? handleWareSmallItem(
                                          data.available_warehouses,
                                          data.product_sku
                                        )
                                      : null
                                  }
                                >
                                  <div className="py-1 px-2">
                                    <h5 className="product-card-sku">
                                      #{data.product_sku || ""}
                                    </h5>
                                    <h5 className="product-card-name my-1">
                                      {data.product_name || ""}
                                    </h5>

                                    {data.product_status === 0 ? (
                                      <>
                                        <IoCheckmarkDoneSharp
                                          onClick={() =>
                                            handleWareSmallItem(
                                              data.available_warehouses,
                                              data.product_sku
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "25px",
                                            marginTop: "-5px",
                                            position: "absolute",
                                            right: "50px",
                                            border: "1px solid green",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            color: "green",
                                          }}
                                        />

                                        <FaTrashAlt
                                          onClick={() => handleRemoveItem(data)}
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "25px",
                                            marginTop: "-5px",
                                            position: "absolute",
                                            right: "15px",
                                            border: "1px solid red",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            color: "red",
                                          }}
                                        />
                                      </>
                                    ) : null}

                                    <h5 className="product-card-mrp my-1">
                                      In Stock:{" "}
                                      <b style={{ color: "green" }}>
                                        {data.in_stock || ""}
                                      </b>{" "}
                                      | QTY{" "}
                                      {data.product_status === 0 ? (
                                        <>
                                          {data.transferDetails &&
                                          data.transferDetails.length > 0
                                            ? data.transferDetails.reduce(
                                                (sum, detail) =>
                                                  sum + (detail.quantity || 0),
                                                0
                                              )
                                            : 0}
                                        </>
                                      ) : (
                                        <>
                                          {(data.selectedSeries &&
                                            data.selectedSeries.length) ||
                                            0}

                                          <Popup
                                            style={{
                                              backgroundColor: "white",
                                              border: "1px solid grey",
                                              borderRadius: "5px",
                                              color: "black",
                                              padding: "5px",
                                              marginLeft: "20px",
                                              marginTop: "-6px",
                                              cursor: "pointer",
                                              position: "absolute",
                                            }}
                                            on="click"
                                            pinned
                                            content={`${data.selectedSeries}`}
                                            trigger={
                                              <IoAlertCircleOutline
                                                style={{
                                                  cursor: "pointer",
                                                  marginLeft: "5px",
                                                  fontSize: "18px",
                                                  color: "red",
                                                }}
                                              />
                                            }
                                          />
                                        </>
                                      )}
                                    </h5>

                                    {/* <TextField
                                      style={{
                                        fontSize: "11px",
                                        width: "90px",
                                      }}
                                      label="Qty"
                                      variant="outlined"
                                      size="small"
                                      value={quantities[data.product_sku] || ""}
                                      onChange={(e) =>
                                        handleQtyChange(
                                          data.product_sku,
                                          e.target.value
                                        )
                                      }
                                    /> */}
                                  </div>
                                </div>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <div className="notfound_img_div">
                            <div className="notfound_img"></div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* ////////////// */}
        </div>
      </div>

      {/*  //Confirm change warehouse */}
      <Dialog
        open={openConfirmChange}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to change warehouse?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmChange}>Cancel</Button>
          <Button autoFocus onClick={handleConfirmChange}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/*  //Remove Product */}

      <Dialog
        open={openRemoveP}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product Already Scanned!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to remove this product?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveProduct}
            >
              Remove Product
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseRemove}
              color="error"
            >
              Skip
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <audio id="scanSound" src={soundEffect} />
    </>
  );
}

export default AddStockTransfer;

import {
  Breadcrumbs,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import { apiKey, fetch_vendor } from "../../../Api";
import { useNavigate } from "react-router-dom";
import { Pagination, Icon } from "semantic-ui-react";
import toast, { Toaster } from "react-hot-toast";

import CreateVendor from "./CreateVendor";
import DeleteVendor from "../../../Messages/DeleteVendor";
import EditVendor from "./EditVendor";
import { IoMdArrowRoundBack } from "react-icons/io";
import Box from "@mui/material/Box";
import { useScreenWidth } from "../../../useScreenWidth";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhone, FaEnvelope } from "react-icons/fa6";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function AllVendor() {
  const [openvendor, setVendorOpen] = useState(false);
  const handleOpenVendor = () => setVendorOpen(true);
  const handleCloseVendor = () => setVendorOpen(false);

  const [openEditVendor, setEditVendorOpen] = useState(false);
  const handleOpenEditVendor = () => setEditVendorOpen(true);
  const handleCloseEditVendor = () => {
    setEditVendorOpen(false);
    fetchData();
  };

  const [allvendors, setAllVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const [selectedEditVendorDetail, setSelectedEditVendorDetail] = useState();

  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDetailModal = (vendor) => {
    setSelectedVendor(vendor);
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setSelectedVendor(null);
    setOpenDetailModal(false);
  };

  const navigate = useNavigate();

  const handleEdit = (vendor) => {
    // console.log(vendor);
    setSelectedEditVendorDetail(vendor);
    handleOpenEditVendor();
  };

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: searchquery,
    };

    setLoading(true); // Start loading before the request

    axios
      .get(fetch_vendor, {
        params: data,
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.vendors);

        if (response.data && response.data.vendors) {
          //   console.log(response.data);
          setAllVendors(response.data.vendors.reverse());
          setTotalPages(response.data.num_pages);
        } else {
          console.log("Unexpected response structure:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendors:", error.response.data);
        console.log(error);
      })
      .finally(() => {
        setLoading(false); // Stop loading regardless of success or error
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  // To navigate to the Upload Vendor
  const navigateToUploadVendor = () => {
    navigate("/upload-vendors");
  };

  useEffect(() => {
    if (searchquery) {
      if (searchquery.length > 2) {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [currentPage, pageSize, searchquery, filterModel]);

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  // To delete the Vendors
  const [pks, setPks] = useState([]);
  const handleDeleteVendor = () => {
    const selectedPks = selectedRows.map((vendor) => vendor.id);

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      toast.error("No Vendors selected, please choose items to continue!");
      return;
    } else {
      setOpenDelete(true);
    }
  };

  // Selection

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Only include products that are deletable
      const deletableVendors = allvendors.filter(
        (product) => product.deletable !== 0
      );
      setSelectedRows(deletableVendors);
    } else {
      // Clear selectedRows if unchecked
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (vendor) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.find((item) => item.id === vendor.id)) {
        // If already selected, remove it
        return prevSelected.filter((item) => item.id !== vendor.id);
      } else {
        // Otherwise, add the full vendor object
        return [...prevSelected, vendor];
      }
    });
  };

  //   Resposive Design
  const isWideScreen = useScreenWidth();

  return (
    <>
      <Toaster position="top-right" />

      <DeleteVendor
        selectedPks={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <CreateVendor
        openvendor={openvendor}
        handleOpenVendor={handleOpenVendor}
        handleCloseVendor={handleCloseVendor}
        fetchVendors={fetchData}
      />

      <EditVendor
        openvendor={openEditVendor}
        handleCloseVendor={handleCloseEditVendor}
        selectedEditVendorDetail={selectedEditVendorDetail}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Vendors</Typography>
              <Typography sx={{ color: "text.primary" }}>
                All Vendors
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6 mb-md-0 mb-2">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>
              {isWideScreen ? (
                <>
                  <div
                    className="col-lg-6"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleOpenVendor}
                      size="small"
                    >
                      Add Vendor
                    </Button>

                    <Button
                      variant="outlined"
                      size="small"
                      // onClick={uploadVendors}
                      // onClick={handleOpenModal}
                      onClick={navigateToUploadVendor} // Open the modal
                    >
                      Upload Vendors
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleDeleteVendor}
                    >
                      Delete Vendors
                    </Button>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th style={{ width: "2px" }}>
                        <input
                          type="checkbox"
                          checked={
                            selectedRows.length ===
                              allvendors.filter(
                                (vendor) => vendor.deletable !== 0
                              ).length &&
                            allvendors.some((vendor) => vendor.deletable !== 0)
                          }
                          onChange={handleSelectAll}
                          disabled={allvendors.every(
                            (vendor) => vendor.deletable === 0
                          )}
                        />
                      </th>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Address</th>
                      <th>GST Number</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allvendors &&
                      allvendors.map((data) => (
                        <tr key={data.id}>
                          <td style={{ width: "2px" }}>
                            <input
                              type="checkbox"
                              checked={selectedRows.some(
                                (item) => item.id === data.id
                              )}
                              onChange={() => handleRowSelect(data)}
                              disabled={data.deletable === 0}
                            />
                          </td>
                          <td>{data.id}</td>
                          <td>{data.name}</td>
                          <td>{data.email}</td>
                          <td>{data.phone}</td>
                          <td>
                            <span>
                              {data.state}, {data.address}, {data.city}{" "}
                              {data.pincode}
                            </span>
                          </td>
                          <td>{data.gst_no}</td>
                          <td>
                            <Button
                              color="primary"
                              variant="outlined"
                              size="small"
                              onClick={() => handleOpenDetailModal(data)}
                            >
                              View
                            </Button>
                            <Button
                              color="error"
                              variant="outlined"
                              size="small"
                              onClick={() => handleEdit(data)}
                              style={{ marginLeft: "5px" }}
                            >
                              Edit
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {allvendors &&
                  allvendors.map((data) => (
                    <div className="product-card" key={data.id}>
                      <div className="py-1 px-2">
                        <h5 className="product-card-sku">#{data.id}</h5>
                        <h5 className="product-card-name">{data.name}</h5>

                        <div className="product-contact">
                          {data.phone && (
                            <span className="product-phone">
                              <FaPhone className="icon-phone" />
                              {data.phone}
                            </span>
                          )}
                          {data.phone && data.email && (
                            <span className="separator">, </span>
                          )}
                          {data.email && (
                            <span className="product-email">
                              <FaEnvelope className="icon-email" />
                              {data.email}
                            </span>
                          )}
                        </div>

                        {data.pincode && (
                          <div className="product-address">
                            <FaMapMarkerAlt className="icon-location" />
                            {data.state}
                            {data.state && data.address && ", "}
                            {data.address}
                            {data.city && ", "}
                            {data.city}
                            {data.pincode && ", "}
                            {data.pincode}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}

          <div style={{ marginTop: "5px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>

          {/* Detail Modal */}
          <Dialog open={openDetailModal} onClose={handleCloseDetailModal}>
            <Box sx={style}>
              <DialogTitle>Vendor Details</DialogTitle>
              <DialogContent>
                {selectedVendor && (
                  <div>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Name:</strong>
                            </td>
                            <td>{selectedVendor.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email:</strong>
                            </td>
                            <td>{selectedVendor.email || "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Phone:</strong>
                            </td>
                            <td>{selectedVendor.phone || "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Alternative Phone:</strong>
                            </td>
                            <td>{selectedVendor.alternative_phone || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Country Code:</strong>
                            </td>
                            <td>{selectedVendor.country_code || "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Address:</strong>
                            </td>
                            <td>{selectedVendor.address || "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>City:</strong>
                            </td>
                            <td>{selectedVendor.city || "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>State:</strong>
                            </td>
                            <td>{selectedVendor.state || "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Country:</strong>
                            </td>
                            <td>{selectedVendor.country || "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Pincode:</strong>
                            </td>
                            <td>{selectedVendor.pincode || "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Contact Name:</strong>
                            </td>
                            <td>{selectedVendor.contact_name || "-"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>GST Number:</strong>
                            </td>
                            <td>{selectedVendor.gst_no}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDetailModal}
                  variant="outlined"
                  color="error"
                >
                  Close
                </Button>
              </DialogActions>
            </Box>
          </Dialog>

          {/* <Dialog open={openDetailModal} onClose={handleCloseDetailModal}>
            <Box sx={style} className="p-2">
              <div className="row m-0 align-items-center">
                <div className="col-md-6">
                  <DialogTitle className="px-3 py-0">
                    Vendor Details
                  </DialogTitle>
                </div>
                <div className="col-md-6">
                  <DialogActions>
                    <Button
                      onClick={handleCloseDetailModal}
                      variant="outlined"
                      color="error"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </div>
              </div>
              <Divider />

              <DialogContent>
                {selectedVendor && (
                  <>
                    <div className="row vendor-detail">
                      <div className="col-md-4">
                        <h3 className="mb-0">{selectedVendor.name}</h3>
                        <div className="info">
                          <small>{selectedVendor.contact_name}</small>
                          <small>
                            {selectedVendor.phone}{" "}
                            {selectedVendor.alternative_phone &&
                              `, ${selectedVendor.alternative_phone}`}
                          </small>
                          <small>{selectedVendor.email}</small>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-7 text-end">
                        <p className="mb-2">
                          <strong>Address:</strong>
                          {(selectedVendor.address &&
                            ` ${selectedVendor.address}`) ||
                            " N/A"}
                          {selectedVendor.pincode &&
                            ` - ${selectedVendor.pincode}`}
                          {selectedVendor.city && `, ${selectedVendor.city}`}
                          {selectedVendor.state && `, ${selectedVendor.state}`}
                          {selectedVendor.country &&
                            `, ${selectedVendor.country}`}
                          {selectedVendor.country_code &&
                            ` ${selectedVendor.country_code}`}
                        </p>
                        <p>
                          <strong>GST No : </strong>{" "}
                          {selectedVendor.gst_no || " N/A"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </DialogContent>
            </Box>
          </Dialog> */}
        </div>
      </div>
    </>
  );
}

export default AllVendor;

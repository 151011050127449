import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, fetch_purchase_detail, barcode_printing } from "../../Api";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import Error from "../../Messages/Error";
import CustomToolbar from "../../Components/CustomToolbar";
import PrintBarcode from "./PrintBarcode";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function BarcodePurchase() {
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [openBarcode, setOpenBarcode] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [barcodes, setBarcodes] = useState([]);

  const { id } = useParams();

  const handleClickOpenBarcode = () => {
    try {
      axios
        .get(barcode_printing, {
          headers: {
            "API-Key": apiKey,
          },
          params: {
            purchase_pk: parseInt(id),
          },
        })
        .then((response) => {
          setBarcodes(response.data.products);
          console.log(response.data.products);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch {
      setError(error.response.data.message);
      setOpenError(true);
    }
    setOpenBarcode(true);
  };

  const handleCloseBarcode = () => {
    setOpenBarcode(false);
  };

  useEffect(() => {
    fetchPurchases();
  }, []);

  const fetchPurchases = () => {
    axios
      .get(fetch_purchase_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          purchase_pk: parseInt(id),
        },
      })
      .then((response) => {
        setSelectedProducts(response.data.purchase_detail.items_detail);
        console.log(response.data.purchase_detail.items_detail);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      field: "product_sku",
      headerName: "SKU",
      width: 160,
    },

    {
      field: "reference_number",
      headerName: "Ref Number",
      width: 180,
    },

    {
      field: "product_name",
      headerName: "Product",
      width: 620,
    },

    {
      field: "quantity",
      headerName: "Qty",
      width: 100,
    },
  ];

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <PrintBarcode
        openBarcode={openBarcode}
        handleCloseBarcode={handleCloseBarcode}
        barcodes={barcodes}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit" style={{ cursor: "pointer" }}>
              Purchases
            </Typography>
            <Typography sx={{ color: "text.primary" }}>
              Print Barcode
            </Typography>
          </Breadcrumbs>
          <Button
            style={{ float: "right", marginTop: "-25px" }}
            size="small"
            variant="contained"
            onClick={handleClickOpenBarcode}
          >
            Print Barcode
          </Button>

          <div className="row my-2">
            <div className="col-lg-12">
              <div className="card p-4 my-2">
                <DataGrid
                  slots={{ toolbar: CustomToolbar }}
                  // checkboxSelection
                  disableRowSelectionOnClick
                  rows={selectedProducts}
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BarcodePurchase;

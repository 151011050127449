import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { adjustment_History_Detail_ById, apiKey } from "../../../Api";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: {
  //     xs: "90%", // Mobile size
  //     sm: "80%", // Small size (tablet)
  //     md: "750px" // Medium and larger (desktop)
  // },
  width: "850px",
  maxWidth: "950px",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

function StockAdjusmentViewModal({ open, onClose, adjustmentId }) {
  const [loading, setLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [adjustmentDetail, setAdjustmentDetail] = useState();

  useEffect(() => {
    if (open && adjustmentId) {
      fetchAdjustment(adjustmentId);
    }
  }, [open, adjustmentId]);

  const fetchAdjustment = (id) => {
    const bodyData = {
      adjustment_pk: parseInt(id),
    };
    axios
      .post(adjustment_History_Detail_ById, bodyData, {
        headers: {
          "API-Key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        setAdjustmentDetail(response.data.data);
        setSelectedProducts(response.data.data.adjustment_history_detail);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div style={modalStyle} className="bg-white py-3 px-3">
          {loading ? (
            <Typography variant="h6">Loading...</Typography>
          ) : (
            <>
              <Typography variant="h5" component="h2">
                Adjustment Details <small>(#{adjustmentId})</small>
              </Typography>
              <div className="row my-2">
                <div className="col-md-4">
                  <Typography>
                    <strong>Warehouse: </strong>{" "}
                    {adjustmentDetail.warehouse.ware_house}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography>
                    <strong>Date: </strong>
                    {adjustmentDetail.date}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography
                    style={{
                      textTransform: "capitalize",
                      color:
                        adjustmentDetail.action == "stock out"
                          ? "red"
                          : "green",
                    }}
                  >
                    <strong>Action: </strong> {adjustmentDetail.action}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography>
                    <strong>Note: </strong> {adjustmentDetail.note}
                  </Typography>
                </div>
                <div className="col-md-4">
                  {adjustmentDetail.action == "stock out" ? null : (
                    <Typography>
                      <strong>Reason: </strong>{" "}
                      {adjustmentDetail.stock_in_reason}
                    </Typography>
                  )}
                </div>
                <div className="col-md-4">
                  <Typography>
                    <strong>Products: </strong>{" "}
                    {adjustmentDetail.total_products}
                  </Typography>
                </div>
              </div>
              <div
                className="responsive-table1 "
                style={{
                  maxHeight: "300px",
                  height: "max-content",
                  width: "100%",
                }}
              >
                <table className="ui table celled" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>SKU</th>
                      <th>Product</th>
                      <th>Part No</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProducts &&
                      selectedProducts.map((data, index) => (
                        <tr key={index}>
                          <td>{data.product_ref__product_sku}</td>
                          <td>{data.product_ref__product_name}</td>
                          <td>{data.product_ref__part_number}</td>
                          <td>{data.quantity}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                style={{ marginTop: "20px", float: "right" }}
              >
                Close
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default StockAdjusmentViewModal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetch_warehouse, apiKey } from "../Api";
import UserImg from "../Assets/Img/userImg.png";
import Avatar from "@mui/material/Avatar";
import { FaWarehouse } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import ChangeWarehouseModal from "./SwitchWarehouse";
import logo from "./logo.png";

function Navbar({ toggleSidebar }) {
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const navigate = useNavigate();
  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${
        name.split(" ")[1] ? name.split(" ")[1][0] : ""
      }`,
    };
  }

  const [warehouse, setWarehouse] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        let primaryWarehouse = "";

        if (userDetails && userDetails.primary_warehouse_pk !== null) {
          primaryWarehouse = response.data.warehouses.find(
            (warehouse) => warehouse.id == userDetails.primary_warehouse_pk
          );
        }

        if (primaryWarehouse) {
          setWarehouse(primaryWarehouse.ware_house);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClearLocalStorage = () => {
    localStorage.clear();
    navigate("/auth");
    window.location.reload();
  };

  const [openModalWarehouse, setOpenModalWarehouse] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [error, setError] = useState("");
  const handleChangeWarehouse = () => {
    try {
      axios
        .get(fetch_warehouse, {
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setWarehouses(response.data.warehouses);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch {
      console.log("error");
      setOpenModalWarehouse(false);
    }
    setOpenModalWarehouse(true);
  };

  return (
    <>
      <ChangeWarehouseModal
        setOpenModalWarehouse={setOpenModalWarehouse}
        openModalWarehouse={openModalWarehouse}
        warehouses={warehouses}
      />

      <Toaster position="top-right" />
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to={`/`}>
            {/* <img src="../../../assets/images/logo.svg" alt="logo" /> */}
            <b style={{ color: "white" }}>KuberTurboTech</b>
          </Link>
          <Link className="navbar-brand brand-logo-mini" to={`/`}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button
            onClick={toggleSidebar}
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <div className="search-field d-none d-xl-block">
            <form className="d-flex align-items-center h-100" action="#">
              {/* <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input
                  type="text"
                  className="form-control bg-transparent border-0"
                  placeholder="Search products"
                />
              </div> */}
            </form>
          </div>
          <ul className="navbar-nav navbar-nav-right">
            {/* <li className="nav-item  dropdown d-none d-md-block">
              <a
                className="nav-link dropdown-toggle"
                id="reportDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {" "}
                Reports{" "}
              </a>
              <div
                className="dropdown-menu navbar-dropdown"
                aria-labelledby="reportDropdown"
              >
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-file-pdf-box me-2"></i>PDF{" "}
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-file-excel me-2"></i>Excel{" "}
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-file-word me-2"></i>doc{" "}
                </a>
              </div>
            </li> */}
            <li className="nav-item  dropdown d-none d-md-block">
              <a
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => handleChangeWarehouse()}
                // className="nav-link dropdown-toggle"
                // id="projectDropdown"
                // href="#"
                // data-bs-toggle="dropdown"
                // aria-expanded="false"
              >
                <FaWarehouse
                  style={{
                    marginRight: "3px",
                    marginTop: "-2.5px",
                    color: "grey",
                  }}
                />
                <b>{warehouse ? warehouse : "Select Warehouse"}</b>
              </a>
              {/* <div
                className="dropdown-menu navbar-dropdown"
                aria-labelledby="projectDropdown"
              >
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-eye-outline me-2"></i>View Project{" "}
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-pencil-outline me-2"></i>Edit Project{" "}
                </a>
              </div> */}
            </li>

            <li className="nav-item nav-profile dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="profileDropdown"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="nav-profile-img">
                  {userDetails ? (
                    <Avatar
                      style={{
                        height: "30px",
                        width: "30px",
                        fontSize: "12px",
                      }}
                      {...stringAvatar(userDetails.name)}
                    />
                  ) : (
                    <img src={UserImg} alt="image" />
                  )}

                  {/* <img src={UserImg} alt="image" /> */}
                </div>
                <div className="nav-profile-text" style={{ marginLeft: "2px" }}>
                  <p className="mb-1 text-black">
                    {userDetails ? userDetails.name : ""}
                  </p>
                </div>
              </a>
              <div
                className="dropdown-menu navbar-dropdown dropdown-menu-end p-0 border-0 font-size-sm"
                aria-labelledby="profileDropdown"
                data-x-placement="bottom-end"
              >
                <div className="p-3 text-center bg-dark">
                  {userDetails ? (
                    <Avatar
                      style={{
                        height: "50px",
                        width: "50px",
                        padding: "15px 10px",
                      }}
                      {...stringAvatar(userDetails.name)}
                    />
                  ) : (
                    <img
                      className="img-avatar img-avatar48 img-avatar-thumb"
                      src={UserImg}
                      alt=""
                    />
                  )}

                  {/* <img
                    className="img-avatar img-avatar48 img-avatar-thumb"
                    src={UserImg}
                    alt=""
                  /> */}
                </div>
                <div className="p-2">
                  <h5 className="dropdown-header text-uppercase ps-2 text-dark">
                    User Options
                  </h5>
                  <a
                    className="dropdown-item py-1 d-flex align-items-center justify-content-between"
                    href="#"
                  >
                    <span>Profile</span>
                    <span className="p-0">
                      <span className="badge badge-success">1</span>
                      <i className="mdi mdi-account-outline ms-1"></i>
                    </span>
                  </a>
                  <Link
                    className="dropdown-item has-inner-dropdown py-1 d-flex align-items-center justify-content-between"
                    to={`/settings`}
                  >
                    <span>Settings</span>
                    <i className="mdi mdi-tune"></i>

                    <div className="inner-dropdown">
                      <Link
                        className="dropdown-item  py-1 d-flex align-items-center justify-content-between"
                        to={`/settings/discount-adjustment`}
                      >
                        <span>Discount Adjustments</span>
                        <i className="mdi mdi-percent"></i>
                      </Link>
                    </div>
                  </Link>
                  <div role="separator" className="dropdown-divider"></div>
                  <h5 className="dropdown-header text-uppercase  ps-2 text-dark mt-2">
                    Actions
                  </h5>
                  <a
                    className="dropdown-item py-1 d-flex align-items-center justify-content-between"
                    onClick={handleClearLocalStorage}
                  >
                    <span>Log Out</span>
                    <i className="mdi mdi-logout ms-1"></i>
                  </a>
                </div>
              </div>
            </li>

            {/* <li className="nav-item dropdown">
              <a
                className="nav-link count-indicator dropdown-toggle"
                id="notificationDropdown"
                href="#"
                data-bs-toggle="dropdown"
              >
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end navbar-dropdown preview-list"
                aria-labelledby="notificationDropdown"
              >
                <h6 className="p-3 mb-0 bg-dark text-white py-4">
                  Notifications
                </h6>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="mdi mdi-calendar"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      Event today
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      {" "}
                      Just a reminder that you have an event today{" "}
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="mdi mdi-weather-sunny"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      Settings
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      {" "}
                      Update dashboard{" "}
                    </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="mdi mdi-link-variant"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      Launch Admin
                    </h6>
                    <p className="text-gray ellipsis mb-0"> New admin wow! </p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center">See all notifications</h6>
              </div>
            </li> */}
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
            onClick={toggleSidebar}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </>
  );
}

export default Navbar;

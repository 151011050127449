import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack, IoMdCloseCircleOutline } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import {
  apiKey,
  fetchSingleRequirement,
  stopRequirement,
  editQtyforRequirement,
} from "../../Api";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useScreenWidth } from "../../useScreenWidth";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Pagination, Icon, Input, Label } from "semantic-ui-react";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import CustomLoader from "../../Components/Loader";
import { FaImage } from "react-icons/fa";

function RequirementDetails() {
  const { id } = useParams();
  const isWideScreen = useScreenWidth();
  const [singleRequirement, setSingleRequirementItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [editedQty, setEditQty] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [editQtyPanel, setEditQtyPanel] = useState(false);
  const [filterModel, setFilterModel] = useState([]);

  const [details, setDetails] = useState({});

  const [openModal, setOpenModal] = useState(false);

  const [userDetails, setUserDetails] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();

    const user = JSON.parse(localStorage.getItem("ktt_users"));
    setUserDetails(user || "");
  }, [currentPage, pageSize, searchquery, filterModel, id]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      requirement_pk: parseInt(id),
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetchSingleRequirement, data, { headers })
      .then((response) => {
        // console.log(response.data.data);
        setDetails(response.data.data);
        setSingleRequirementItems(response.data.data.items_detail);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/requirements-list");
        toast.error(error.response.data.message);
        console.log(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  // Pagination

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  // To stop the Process of requirement List

  const handleStopClick = () => {
    setOpenModal(true);
  };

  const handleCloseStopModal = () => {
    setOpenModal(false);
  };

  const handleConfirmStop = () => {
    setOpenModal(false);

    const data = {
      user_name: userDetails.name || "",
      requirement_pk: parseInt(id),
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(stopRequirement, data, { headers })
      .then((response) => {
        toast.success("Requirement List Stopped Successfully !!");
        fetchData();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data?.message || "Internal Server Error");
      });
  };

  const [editQtyPanelIndex, setEditQtyPanelIndex] = useState(null);
  const handleConfirmEditQty = async (id) => {
    // Validate the quantity before submitting
    if (!editedQty || isNaN(editedQty) || editedQty <= 0 || editedQty > 10) {
      toast.error("Please enter a valid quantity (1-10).");
      return;
    }

    const data = {
      item_pk: parseInt(id),
      quantity: parseInt(editedQty),
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(editQtyforRequirement, data, { headers })
      .then((response) => {
        toast.success("Quantity Updated Successfully !!");
        fetchData();
        setEditQtyPanel(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response?.data?.message || "Internal Server Error");
      });

    setEditQtyPanelIndex(null);
  };

  const handleOpenEditPanel = (index) => {
    setEditQtyPanelIndex(index);
    setEditQty("");
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/requirements-list")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Requirement List
              </Typography>
              <Typography>#{id}</Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-1">
            <div className="col-md-11">
              <div className="card mt-1 p-3">
                <div className="row mt-1">
                  <div className="col-md-3">
                    Starting Time:{" "}
                    <b style={{ color: "black" }}>
                      {new Date(details.starting_time).toLocaleString()}
                    </b>
                  </div>
                  <div className="col-md-3">
                    Ending Time:{" "}
                    <b style={{ color: "black" }}>
                      {details.ending_time
                        ? new Date(details.ending_time).toLocaleString()
                        : "N/A"}
                    </b>
                  </div>
                  <div className="col-md-2 d-none d-md-block">
                    Stopped Status:{" "}
                    {details.is_stopped === 1 ? (
                      <>
                        <Label color="red">Stopped</Label>
                      </>
                    ) : (
                      <>
                        <Label color="green">OnGoing</Label>
                      </>
                    )}
                  </div>
                  <div className="col-md-3 d-none d-md-block">
                    Stopped By:{" "}
                    <b style={{ color: "black" }}>
                      {details.stopped_by || "N/A"}
                    </b>
                  </div>

                  <div className="d-block d-md-none mt-2">
                    {details.is_stopped === 1 ? (
                      <>
                        <Label color="red">Stopped</Label>
                      </>
                    ) : (
                      <>
                        <Label color="green">OnGoing</Label>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <input
                className="form-control"
                placeholder="Search..."
                style={{
                  backgroundColor: "white",
                  maxWidth: "400px",
                  marginTop: "10px",
                }}
                type="search"
                value={searchquery}
                onChange={handleSearchChange}
              />
            </div>

            <div className="col-md-1 d-none d-md-block">
              <div
                className="card mt-1 p-3"
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                {details.is_stopped === 1 ? null : (
                  <img
                    onClick={() => handleStopClick(id)}
                    src="https://pngimg.com/d/sign_stop_PNG25633.png"
                  />
                )}
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <div className="responsive-table1">
              <table className="ui table celled" id="c_table">
                <thead className="table-head">
                  <tr>
                    <th>ID</th>
                    <th>Product SKU</th>
                    <th>Product Name</th>
                    <th>Part Number</th>
                    <th>OEM Number</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {singleRequirement &&
                    singleRequirement.map((data, index) => (
                      <tr key={data.id}>
                        <td>#{data.id}</td>
                        <td>{data.product_sku}</td>
                        <td>{data.product_name}</td>
                        <td>{data.part_number}</td>
                        <td>{data.oem_number}</td>
                        <td style={{ width: "180px" }}>
                          <div style={{ display: "block" }}>
                            {editQtyPanelIndex === index ? (
                              <>
                                <Input
                                  placeholder="Qty"
                                  autoFocus
                                  style={{
                                    width: "90px",
                                    height: "28px",
                                    marginLeft: "5px",
                                    padding: "0px",
                                  }}
                                  value={editedQty}
                                  onFocus={() => setEditQty("")} // Clear the value when input is focused
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (
                                      value === "" ||
                                      (value <= data.available_stock_in_other &&
                                        value >= 0)
                                    ) {
                                      setEditQty(value);
                                    } else {
                                      setEditQty(data.available_stock_in_other);
                                      toast.error(
                                        `Quantity Can't Exceed ${data.available_stock_in_other}`
                                      );
                                    }
                                  }}
                                  type="number"
                                  min={0}
                                />
                                <IoCheckmarkDoneSharp
                                  onClick={() => handleConfirmEditQty(data.id)}
                                  className="btn-iconn"
                                  style={{
                                    color: "green",
                                    borderColor: "green",
                                  }}
                                />
                                <IoMdCloseCircleOutline
                                  onClick={() => setEditQtyPanelIndex(null)}
                                  className="btn-iconn"
                                  style={{ color: "red", borderColor: "red" }}
                                />
                              </>
                            ) : (
                              <>
                                <Input
                                  readOnly
                                  style={{
                                    width: "90px",
                                    height: "28px",
                                    marginLeft: "5px",
                                    padding: "0px",
                                  }}
                                  onClick={() => handleOpenEditPanel(index)}
                                  value={data.quantity || 0}
                                />
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {singleRequirement.length > 0 ? (
                  <>
                    {singleRequirement &&
                      singleRequirement.map((requirement, index) => (
                        <>
                          <div className="product-card">
                            <div>
                              <FaImage className="product-img" />
                            </div>
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">
                                #{requirement.product_sku}
                              </h5>
                              <h5 className="product-card-name">
                                {requirement.product_name}
                              </h5>

                              <h5
                                className="product-card-mrp mt-2"
                                style={{ color: "grey" }}
                              >
                                <b style={{ color: "black" }}>
                                  {requirement.part_number}
                                </b>
                              </h5>
                              <div
                                style={{
                                  position: "absolute",
                                  right: "12px",
                                  marginTop: "-22px",
                                }}
                              >
                                {editQtyPanelIndex === index ? (
                                  <>
                                    <Input
                                      placeholder="Qty"
                                      autoFocus
                                      style={{
                                        width: "70px",
                                        height: "28px",
                                        marginLeft: "5px",
                                        padding: "0px",
                                      }}
                                      value={editedQty}
                                      onFocus={() => setEditQty("")} // Clear the value when input is focused
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (
                                          value === "" ||
                                          (value <=
                                            requirement.available_stock_in_other &&
                                            value >= 0)
                                        ) {
                                          setEditQty(value);
                                        } else {
                                          setEditQty(
                                            requirement.available_stock_in_other
                                          );
                                          toast.error(
                                            `Quantity Can't Exceed ${requirement.available_stock_in_other}`
                                          );
                                        }
                                      }}
                                      type="number"
                                      min={0}
                                    />
                                    <IoCheckmarkDoneSharp
                                      onClick={() =>
                                        handleConfirmEditQty(requirement.id)
                                      }
                                      className="btn-iconn"
                                      style={{
                                        color: "green",
                                        borderColor: "green",
                                      }}
                                    />
                                    <IoMdCloseCircleOutline
                                      onClick={() => setEditQtyPanelIndex(null)}
                                      className="btn-iconn"
                                      style={{
                                        color: "red",
                                        borderColor: "red",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      readOnly
                                      style={{
                                        width: "70px",
                                        height: "28px",
                                        marginLeft: "5px",
                                        padding: "0px",
                                      }}
                                      onClick={() => handleOpenEditPanel(index)}
                                      value={requirement.quantity || 0}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "5px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Dialog open={openModal} onClose={handleCloseStopModal}>
        <DialogTitle>Confirm Stop</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to stop the process with ID: {id}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseStopModal}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmStop}
            color="primary"
            variant="outlined"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RequirementDetails;

import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { apiKey, requirement_details } from "../../Api";
import CustomLoader from "../../Components/Loader";
import { useScreenWidth } from "../../useScreenWidth";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import { io } from "socket.io-client";

function DispatchRequirementDetails() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const isWideScreen = useScreenWidth();
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const ws = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Establish WebSocket connection with API-Key as a query parameter
    ws.current = new WebSocket(
      `${requirement_details}?requirement_pk=${id}&API-Key=${apiKey}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connection established");
      setLoading(false);
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      console.log(response.items);
      setData(response.items);
      // Assuming response.data.purchase_detail.items_detail is the data you need
      // setData(response.data.purchase_detail.items_detail);
    };

    ws.current.onerror = (error) => {
      console.error(error, "WebSocket error");
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [id, apiKey]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [searchQuery]);

  const fetchData = (query = searchQuery) => {
    const bodyData = {
      requirement_pk: parseInt(id),
      search: query,
    };

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(bodyData));
    }
  };

  const handlePickUp = (pk) => {
    navigate(`/requirement-pick-up/${pk}`);
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Requirement Details
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-1">
            {isWideScreen ? (
              <>
                <div className="responsive-table1">
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Product SKU</th>
                        <th>Product Name</th>
                        <th>Product Type</th>

                        <th>Reqd. Qty</th>
                        <th>Picked Qty</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        <>
                          {data &&
                            data.map((req, index) => (
                              <>
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      req.quantity < req.picked_quantity
                                        ? "rgb(253 199 199)"
                                        : req.quantity == req.picked_quantity
                                        ? "#d1e7dd"
                                        : req.quantity > req.picked_quantity &&
                                          req.picked_quantity != 0
                                        ? "#fff3cd"
                                        : req.picked_quantity == 0
                                        ? "transparent"
                                        : "transparent",
                                  }}
                                >
                                  <td style={{ width: "40px" }}>{index + 1}</td>
                                  <td>{req.product_sku}</td>
                                  <td>{req.product_name}</td>
                                  <td>{req.product_type}</td>
                                  <td>{req.quantity}</td>
                                  <td>{req.picked_quantity}</td>
                                  <td>
                                    <Button
                                      disabled={
                                        req.quantity == req.picked_quantity
                                      }
                                      variant="outlined"
                                      size="small"
                                      onClick={() => handlePickUp(req.id)}
                                    >
                                      Pick
                                    </Button>
                                  </td>
                                </tr>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={10}>
                              {!loading ? (
                                <div className="notfound_img_div">
                                  <div className="notfound_img"></div>
                                </div>
                              ) : (
                                <div className="notfound_img_div">
                                  <div className="loadingfound_img"></div>
                                </div>
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="card p-2 responsive-mobile-table"></div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DispatchRequirementDetails;

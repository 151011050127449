import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { apiKey, TransferHistory } from "../../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useScreenWidth } from "../../../useScreenWidth.js";
import toast, { Toaster } from "react-hot-toast";
import { Icon, Label, Pagination } from "semantic-ui-react";
import CustomLoader from "../../../Components/Loader";
import FilterDateRangeModal from "../../../Components/FilterModal/FilterDateRangeModal.js";
import FilterSelectionModalByWarehouse from "../../../Components/FilterModal/FilterSelectionModalByWarehouse.js";
import { formatDate } from "../../../Components/DateFormat.js";

function StockTransfer() {
  const isWideScreen = useScreenWidth();
  const [transfers, setTransfers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");

  // Warehouse Filter
  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState(null);

  // date Filter

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const navigate = useNavigate();

  const debouncedFetchData = useCallback(
    debounce((page, query) => {
      fetchData(page, query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    debouncedFetchData(1, query);
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    pageSize,
    searchquery,
    startDate,
    endDate,
    selectedWarehouseIds,
  ]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      starting_date: startDate || "",
      ending_date: endDate || "",
      selectedWarehouses: selectedWarehouseIds || null,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    axios
      .post(TransferHistory, data, { headers })
      .then((response) => {
        // console.log(response);
        setTransfers(response.data.data);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleEditClick = (pk) => {
    navigate(`/transfer-history-detail/${pk}`);
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography sx={{ color: "text.primary" }}>Transfer</Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-8 col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-4 col-lg-8"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-stock-transfer")}
                >
                  Add New
                </Button>
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>ID</th>
                      <th>
                        <FilterDateRangeModal
                          title="Transfer Date"
                          startDate={startDate}
                          endDate={endDate}
                          onDateChange={handleDateChange}
                        />
                      </th>
                      <th>
                        <FilterSelectionModalByWarehouse
                          title="Target Warehouse"
                          selectedWarehouseId={selectedWarehouseIds}
                          onSelect={setSelectedWarehouseIds}
                        />
                      </th>
                      <th>Products</th>
                      <th>Notes</th>
                      <th>Created By</th>
                      <th style={{ textAlign: "center" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transfers.length > 0 ? (
                      <>
                        {transfers &&
                          transfers.map((data) => (
                            <>
                              <tr>
                                <td style={{ width: "60px" }}>#{data.id}</td>
                                <td style={{ width: "180px" }}>
                                  {formatDate(data.date) || "-"}
                                </td>

                                <td>
                                  {data.target_warehouse
                                    ? data.target_warehouse.ware_house
                                    : "-"}
                                </td>
                                <td>
                                  {data.total_products
                                    ? data.total_products
                                    : "-"}{" "}
                                  ({data.total_quantity})
                                </td>
                                <td>{data.note || "N/A"}</td>
                                <td>{data.user_name || "N/A"}</td>
                                <td
                                  style={{
                                    width: "100px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    size="small"
                                    onClick={() => handleEditClick(data.id)}
                                  >
                                    View
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan="7">
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {transfers.length > 0 ? (
                  <>
                    {transfers &&
                      transfers.map((data) => (
                        <>
                          <div
                            className="product-card"
                            id="product_card"
                            onClick={() => handleEditClick(data.id)}
                          >
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5 className="product-card-name">
                                {data.target_warehouse
                                  ? data.target_warehouse.ware_house
                                  : "-"}
                              </h5>
                              <h5 className="warehouse-card-address">
                                Created By: {data.user_name || "N/A"}
                              </h5>
                            </div>
                            <div>
                              <h5 className="my-1 warehouse-card-label">
                                <Label color="green">
                                  {data.total_products} | {data.total_quantity}{" "}
                                  QTY
                                </Label>
                              </h5>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "2px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default StockTransfer;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { transfer_child_product_immediately, apiKey } from "../../../Api";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function SmallItemTransfer({
  openSmallModal,
  setOpenSmallModal,
  selectedProducts,
  setSelectedProducts,
  smallWarehouses,
  smallSKU,
}) {
  const [rows, setRows] = useState([
    { selectedWarehouse: "", quantity: 0, targetQuantity: 0 },
  ]);

  useEffect(() => {
    if (smallWarehouses && smallWarehouses.length === 1) {
      setRows([
        {
          selectedWarehouse: smallWarehouses[0].warehouse_id,
          quantity: smallWarehouses[0].quantity,
          targetQuantity: 0,
        },
      ]);
    }
  }, [smallWarehouses]);

  const handleClose = () => {
    setOpenSmallModal(false);
  };

  const handleWarehouseChange = (index, event) => {
    const warehouseId = event.target.value;
    const newRows = [...rows];
    newRows[index].selectedWarehouse = warehouseId;

    const selectedWarehouseData = smallWarehouses.find(
      (item) => item.warehouse_id === warehouseId
    );

    if (selectedWarehouseData) {
      newRows[index].quantity = selectedWarehouseData.quantity;
    }
    setRows(newRows);
  };

  const handleTargetQuantityChange = (index, event) => {
    const value = event.target.value;
    const maxQuantity = rows[index].quantity; // Assuming rows is your array of data

    if (value > maxQuantity) {
      // Optionally, you can show an error message or set the value to the max quantity
      setRows((prevRows) =>
        prevRows.map((row, i) =>
          i === index ? { ...row, targetQuantity: maxQuantity } : row
        )
      );
    } else {
      setRows((prevRows) =>
        prevRows.map((row, i) =>
          i === index ? { ...row, targetQuantity: value } : row
        )
      );
    }
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      { selectedWarehouse: "", quantity: 0, targetQuantity: 0 },
    ]);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const selectedWarehouses = rows.map((row) => row.selectedWarehouse);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const handleTransferProduct = () => {
    const updatedProducts = selectedProducts.map((product) => {
      if (product.product_sku === smallSKU) {
        return {
          ...product,
          transferDetails: rows.map((row) => ({
            warehouse_pk: parseInt(row.selectedWarehouse),
            quantity: parseInt(row.targetQuantity),
          })),
        };
      }
      return product;
    });

    setSelectedProducts(updatedProducts);
    setOpenSmallModal(false);
  };

  return (
    <>
      <Toaster position="top-right" />
      <Dialog
        open={openSmallModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
          >
            Choose Source Warehouse
          </DialogTitle>
          <form>
            <DialogContent>
              {rows &&
                rows.map((row, index) => (
                  <div className="row my-2" key={index}>
                    <div className="col-md-5">
                      <FormControl fullWidth size="small">
                        <InputLabel>Source Warehouse</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          value={row.selectedWarehouse}
                          label="Source Warehouse"
                          onChange={(e) => handleWarehouseChange(index, e)}
                        >
                          <MenuItem value="">--Select--</MenuItem>
                          {smallWarehouses &&
                            smallWarehouses.map((data) => (
                              <MenuItem
                                disabled={selectedWarehouses.includes(
                                  data.warehouse_id
                                )}
                                key={data.warehouse_id}
                                value={data.warehouse_id}
                              >
                                {data.ware_house_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-3">
                      <TextField
                        label="Avail Qty"
                        size="small"
                        variant="outlined"
                        value={row.quantity}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        label="Quantity"
                        size="small"
                        variant="outlined"
                        value={row.targetQuantity}
                        inputProps={{
                          max: row.quantity,
                        }}
                        onChange={(e) => handleTargetQuantityChange(index, e)}
                      />
                    </div>
                    <div className="col-md-1">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => handleDeleteRow(index)}
                      >
                        <DeleteIcon fontSize="small" style={{ color: "red" }} />
                      </IconButton>
                    </div>
                  </div>
                ))}
              {smallWarehouses &&
                smallWarehouses.some(
                  (data) => !selectedWarehouses.includes(data.warehouse_id)
                ) && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleAddRow}
                  >
                    Add Warehouse
                  </Button>
                )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleTransferProduct}
              >
                Proceed
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </>
  );
}

export default SmallItemTransfer;

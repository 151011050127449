import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, edit_row } from "../../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import toast, { Toaster } from "react-hot-toast";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import { Breadcrumbs } from "@mui/material";
import { IoClose } from "react-icons/io5";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "600px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

function EditRowModal({
  setOpenEditRowModal,
  openEditRowModal,
  row_name,
  row_id,
  fetchData,
}) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [newrow, setRowName] = useState(row_name);

  useEffect(() => {
    if (row_name) {
      setRowName(row_name);
    }
  }, [row_name]);

  const handleClose = () => {
    setOpenEditRowModal(false);
    setError("");
  };

  const handleEditRow = (e) => {
    e.preventDefault();
    if (!newrow) {
      toast.error("Row cannot be empty!");
      return;
    }

    const formattedData = {
      row_pk: parseInt(row_id),
      row_name: newrow,
    };

    axios
      .post(edit_row, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          // setOpenSuccess(true);
          toast.success(`Row has been updated successfully!`);
          setRowName("");
          fetchData();
          setOpenEditRowModal(false);
        } else {
          toast.error(response.data.message);
          // setOpenError(true);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        // setOpenError(true);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openEditRowModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Warehouse</Typography>
            <Typography color="inherit">Rows</Typography>
            <Typography sx={{ color: "text.primary" }}>{row_name}</Typography>
          </Breadcrumbs>

          <IoClose className="closeModalBtn" onClick={handleClose} />

          <form onSubmit={handleEditRow}>
            <div
              style={{
                maxHeight: "30vh",
                marginTop: "8px",
              }}
            >
              <TextField
                id="outlined-row-name"
                label="Row Name"
                value={newrow}
                size="small"
                required
                onChange={(e) => setRowName(e.target.value)}
                fullWidth
                autoFocus
              />
            </div>

            <div className="d-flex justify-content-end mt-1">
              <Button
                style={{ marginTop: "8px", marginLeft: "2px" }}
                variant="outlined"
                color="primary"
                disabled={newrow == row_name}
                type="submit"
              >
                Update Row
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default EditRowModal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_product_detail,
  getTypeBrand,
  update_product,
} from "../../Api";
import toast from "react-hot-toast";
import SubmitLoader from "../../Components/SubmitLoader";
import { IoMdArrowRoundBack } from "react-icons/io";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useParams, useNavigate } from "react-router-dom";
import { FaImage } from "react-icons/fa6";

function ProductDetails() {
  const [submitLoader, setSubmitLoader] = useState(false);
  const navigate = useNavigate();
  const { sku } = useParams();

  const [product_sku, setSKU] = useState("");
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);
  const [product_name, setProduct_name] = useState("");
  const [oem_number, setOEM] = useState("");
  const [part_number, setPartNo] = useState("");
  const [product_brand, setProductBrand] = useState("");
  const [product_type, setProductType] = useState("");
  const [product_desc, setDesc] = useState("");
  const [mrp, setMrp] = useState("");
  const [in_stock, setInstock] = useState("");
  const [stock_alert, setStockAlert] = useState(1);
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [hsn_code, setHSN] = useState("");
  const [gst_percent, setGST] = useState("");

  useEffect(() => {
    fetchProduct();
    fetchBrandType();
  }, [sku]);

  const fetchProduct = () => {
    axios
      .get(fetch_product_detail, {
        params: {
          product_sku: sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setSKU(response.data.product[0].product_sku);
        setProduct_name(response.data.product[0].product_name);
        setOEM(response.data.product[0].oem_number);
        setPartNo(response.data.product[0].part_number);
        setProductBrand(response.data.product[0].brand__product_brand);
        setProductType(response.data.product[0].product_type__product_type);
        setDesc(response.data.product[0].description);
        setMrp(response.data.product[0].mrp);
        setInstock(response.data.product[0].in_stock);
        setStockAlert(response.data.product[0].stock_alert);
        setLength(response.data.product[0].length);
        setBreadth(response.data.product[0].breadth);
        setHeight(response.data.product[0].height);
        setWeight(response.data.product[0].weight);
        setHSN(response.data.product[0].hsn_code);
        setGST(response.data.product[0].gst_percent);
      })
      .catch((error) => {
        toast.error("Error fetching product details.");
      });
  };

  const fetchBrandType = () => {
    axios
      .get(getTypeBrand, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setTypes(response.data.product_types);
        setBrands(response.data.product_brands);
      })
      .catch((error) => {
        toast.error(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
      });
  };
  return (
    <>
      {submitLoader && <SubmitLoader />}
      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_products")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="inherit"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                Products
              </Typography>
              <Typography sx={{ color: "text.primary" }}>
                {product_name.length > 23
                  ? product_name.substring(0, 23) + "..."
                  : product_name}
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div className="product-card my-1">
                <div>
                  <FaImage className="product-img" />
                </div>
                <div className="py-2 px-2">
                  <h5 className="product-card-sku">#{product_sku}</h5>
                  <h5 className="product-card-name">{product_name}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <div className="product-card my-1">
                <div className="py-1 px-2">
                  <h5 className="product-card-label">Part Number</h5>
                  <h5 className="product-card-type my-1">{part_number}</h5>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="py-1 px-2">
                <h5 className="product-card-label">Product Brand</h5>
                <h5 className="product-card-type">{product_brand}</h5>
              </div>
            </div>
            <div className="col-6 col-lg-2">
              <div className="py-1 px-2">
                <h5 className="product-card-label">Product Type</h5>
                <h5 className="product-card-type">{product_type}</h5>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="py-1 px-2">
                <h5 className="product-card-label">OEM Number</h5>
                <h5 className="product-card-type">{oem_number}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetails;

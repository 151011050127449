import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, fetch_sale_detail } from "../../Api";
import Typography from "@mui/material/Typography";
import { TbExternalLink } from "react-icons/tb";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import toast, { Toaster } from "react-hot-toast";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: {
  //     xs: "90%", // Mobile size
  //     sm: "80%", // Small size (tablet)
  //     md: "750px" // Medium and larger (desktop)
  // },
  width: "850px",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

function ViewSaleDetailModal({ open, onClose, saleId }) {
  const [loading, setLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customer, setCustomer] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  const [sale_date, setSaleDate] = useState("");
  const [discountedprice, setDiscountedPrice] = useState(0);
  const [totalamount, setTotalAmount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [othercharge, setOtherCharge] = useState(0);

  useEffect(() => {
    fetchSales();
  }, [saleId]);

  const fetchSales = () => {
    if (!saleId) {
      // console.log("Sale Id nahi hai ")
      return;
    }
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(saleId),
        },
      })
      .then((response) => {
        const data = response.data.data;

        // Set customer and other details from the API response
        setCustomer(data.billing_customer.customer_name);
        setNote(data.note);
        setSaleDate(data.sale_date);
        setDiscountedPrice(data.discount_amount);
        setTotalAmount(data.total_amount);
        setOtherCharge(data.other_charge);
        setShipping(data.courier_charge);
        setAddress(
          data.billing_customer.primary_shipping?.address ||
            "No Address Provided"
        );

        // Set selected products based on the API response
        const items = data.items.map((item) => ({
          product_sku: item.product_sku,
          product_name: item.product_name,
          mrp: item.mrp,
          price: item.price,
          quantity: item.quantity,
          discount_amount: item.discount_amount,
        }));

        setSelectedProducts(items);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // setOpenError(true);
        toast.error("Failed to fetch sale details.");
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal open={open} onClose={onClose}>
        <div style={modalStyle} className="bg-white py-3 px-3">
          {loading ? (
            <Typography variant="h6">Loading...</Typography>
          ) : (
            <>
              <Typography variant="h5" component="h2">
                Sale Details <small>(#{saleId})</small>
                <a
                  href={`/#/view-sale/${saleId}`}
                  target="_blank"
                  className="navigate-other"
                >
                  <TbExternalLink />
                </a>
              </Typography>
              <div className="row my-2">
                <div className="col-md-4">
                  <Typography>
                    <strong>Customer: </strong> {customer}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography>
                    <strong>Bill to : </strong> {address}
                  </Typography>
                </div>
                <div className="col-md-4">
                  <Typography>
                    <strong>Note: </strong> {note || "N/A"}
                  </Typography>
                </div>
              </div>
              <div className="row">
                <div
                  className="responsive-table1 "
                  style={{
                    maxHeight: "400px",
                    height: "max-content",
                    width: "100%",
                  }}
                >
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>SKU</th>
                        <th>Product</th>
                        <th>MRP</th>
                        <th>Discount</th>
                        <th>Discount Amt</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProducts &&
                        selectedProducts.map((data, index) => (
                          <tr key={index}>
                            <td>{data.product_sku}</td>
                            <td>{data.product_name}</td>
                            <td>{data.mrp}.00</td>
                            <td>
                              {(
                                (data.discount_amount / data.mrp) *
                                100
                              ).toFixed(2)}
                              %
                            </td>
                            <td>{data.discount_amount}.00</td>
                            <td>{data.quantity}</td>
                            <td>{data.quantity * data.price}.00</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <Button
                onClick={onClose}
                variant="outlined"
                color="primary"
                style={{ marginTop: "20px", float: "right" }}
              >
                Close
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default ViewSaleDetailModal;

import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import {
  apiKey,
  fetch_warehouse,
  fetch_all_product,
  fetchProductInven,
  bulk_Stock_In,
  bilk_Stock_Out,
  fetch_all_product_transfer,
  stock_in_reasons,
} from "../../../Api";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import AssignRackModal from "./AssignStockModal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import Webcam from "react-webcam";
import Quagga from "quagga";
import { IoSearch } from "react-icons/io5";
import { FaTrashAlt } from "react-icons/fa";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import SeriesModal from "./SeriesModal";
import RemoveSkipProduct from "../../../Messages/StockAdjustment/RemoveSkipProduct";
import soundEffect from "../../../Assets/sounds/beep.mp3";
import { BsUpcScan } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import CustomLoader from "../../../Components/Loader";
import SubmitLoader from "../../../Components/SubmitLoader";
import { useScreenWidth } from "../../../useScreenWidth";
import { Label } from "semantic-ui-react";

function AddStockAdjustment() {
  const webcamRef = useRef(null);
  const isWideScreen = useScreenWidth();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [displaySearch, setDisplay] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProducts2, setSelectedProducts2] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState("");
  const [type, setType] = useState();
  const searchListRef = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [productInventories, setProductInventories] = useState({});
  const [unallocated, setUnallocated] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);
  const [inven, setInven] = useState([]);
  const [warehouse, setWarehouse] = useState(
    userDetails.primary_warehouse_pk || ""
  );
  const [warehouses, setWarehouses] = useState([]);
  const [stockInReasons, setStockInReasons] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchWarehouse();
    fetchInven();
  }, []);

  const fetchInven = (sku) => {
    axios
      .get(fetchProductInven, {
        params: {
          warehouse_id: warehouse,
          sku: sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const { row_inventory, obj } = response.data;

        setProductInventories((prevInventories) => ({
          ...prevInventories,
          [sku]: row_inventory,
        }));

        // Update unallocated quantities for the specific SKU
        setUnallocated((prev) => ({
          ...prev,
          [sku]: obj.unallocated,
        }));
      })
      .catch((error) => {
        setError(error);
      });
  };

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allWarehouses = response.data.warehouses;
        setWarehouses(allWarehouses); // Set all warehouses
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchProducts = useCallback(() => {
    if (type === 1) {
      try {
        axios
          .post(
            fetch_all_product,
            { search: searchQuery || "" },
            {
              headers: {
                "Content-Type": "application/json",
                "API-Key": apiKey,
              },
            }
          )
          .then((response) => {
            const fetchedProducts = response.data.data.products;

            setProducts(fetchedProducts);
            setDisplay(true);
          });
        // console.log(response.data.data.products);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    } else if (type === 2) {
      if (!apiKey) {
        toast.error("API Key is not available");
      }

      axios
        .post(
          fetch_all_product_transfer,

          {
            search: searchQuery || "",
            warehouse_pk: parseInt(warehouse),
          },
          {
            headers: {
              "API-Key": apiKey,
            },
          }
        )
        .then((response) => {
          const fetchedProducts = response.data.data;
          setProducts(fetchedProducts);
          setDisplay(true);
        })
        .catch((error) => {
          toast.error(error.response?.data?.message);
        });
    } else {
      console.log("sabse alg else ");
    }
  }, [searchQuery, warehouse, type]);

  const [debouncedQuery, setDebouncedQuery] = useState("");

  // Debounce the setting of debouncedQuery
  const debouncedSetQuery = useCallback(
    debounce((query) => {
      setDebouncedQuery(query);
    }, 600),
    []
  );

  const handleSearchChange = useCallback(
    (query) => {
      const formattedQuery = query.split("_")[0];
      setSearchQuery(formattedQuery);
      // Automatically add the product if it contains '_'
      if (query.includes("_")) {
        const productToAdd = products.find(
          (product) => product.product_sku === `KTT-${formattedQuery}`
        );

        if (
          productToAdd &&
          !selectedProducts2.some(
            (p) => p.product_sku === productToAdd.product_sku
          )
        ) {
          setSelectedProducts2((prev) => [...prev, productToAdd]);
          setSearchQuery("");
        }
      }
      // Update the immediate search query for user feedback
      debouncedSetQuery(formattedQuery); // Update the debounced query
    },
    [products, selectedProducts2, debouncedSetQuery]
  );

  const [istyping, setIstyping] = useState(true);

  const handleInputChange = (e) => {
    const query = e.target.value;

    // If the input contains '_', do not allow further input
    if (query.includes("_")) {
      const sanitizedQuery = query.split("_")[0]; // Get the part before the underscore
      setSearchQuery(sanitizedQuery); // Set the visible input value
      handleSearchChange(query); // Call to handleSearchChange with the full query
      setIstyping(false);
      setTimeout(() => {
        setIstyping(true);
      }, 1000);
    } else {
      setSearchQuery(query); // Set the visible input value
      handleSearchChange(query); // Call to handleSearchChange with the current query
    }
  };

  useEffect(() => {
    if (debouncedQuery) {
      fetchProducts(debouncedQuery);
    }
  }, [debouncedQuery, fetchProducts]);

  const handleCheckboxChange = (product) => {
    const newEntry = {
      ...product,
      inventoryDetail: "",
      quantity: 0,
    };

    if (type == 2) {
      if (product.product_status == 1) {
        addScannedProduct(product.product_sku, product);
        return;
      }

      setScannedProducts((prevSelected) => {
        if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
          return prevSelected.filter(
            (p) => p.product_sku !== product.product_sku
          );
        } else {
          fetchInven(product.product_sku);
          return [...prevSelected, newEntry];
        }
      });
    } else if (type == 1) {
      setSelectedProducts2((prevSelected) => {
        if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
          return prevSelected.filter(
            (p) => p.product_sku !== product.product_sku
          );
        } else {
          fetchInven(product.product_sku);
          return [...prevSelected, newEntry];
        }
      });
    }
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleQuantityChange = (productId, newValue) => {
    const numericValue = Number(newValue);
    const selectedProduct = selectedProducts.find((p) => p.id === productId);

    if (selectedProduct) {
      const selectedInventory = selectedProduct.inventoryDetail;
      const selectedInven =
        productInventories[selectedProduct.product_sku] || [];
      const inventory = selectedInven.find(
        (inv) => inv.id === selectedInventory
      );

      const availableQuantity = inventory?.quantity || 0;

      // Check if the idType is 2 and quantity exceeds available amount
      if (selectedProduct.idType == 2 && numericValue > availableQuantity) {
        alert("Quantity cannot exceed the available amount.");
        return; // Prevent updating state if validation fails
      }

      // Update the selected product or reset quantities if idType changes
      setSelectedProducts((prevSelected) =>
        prevSelected.map((product) =>
          product.id === productId
            ? {
                ...product,
                quantity:
                  selectedProduct.idType !== product.idType ? 0 : numericValue,
                idType: product.idType,
              }
            : product
        )
      );
    }
  };

  const [openTypeModalChange, setOpenTypeModalChange] = useState(false);
  const [changedType, setChangedType] = useState("");

  const handleTypeChange = (event) => {
    const newType = event.target.value;

    if (scannedProducts && scannedProducts.length > 0) {
      setChangedType(parseInt(newType));
      setOpenTypeModalChange(true);
    } else if (selectedProducts2 && selectedProducts2.length > 0) {
      setChangedType(parseInt(newType));
      setOpenTypeModalChange(true);
    } else {
      setType(parseInt(newType));
    }
  };

  const handleConfirmTypeChange = () => {
    setType(changedType);

    setOpenTypeModalChange(false);
    setScannedProducts([]);
    setSelectedProducts([]);
    setSelectedProducts2([]);
  };

  const handleCancelTypeChange = () => {
    setOpenTypeModalChange(false);
    setChangedType("");
  };

  // =================================== Stock IN ===============================

  const [quantities, setQuantities] = useState({});

  const handleQtyChange = (sku, value) => {
    const numericValue = parseInt(value, 10);

    setQuantities((prevQuantities) => {
      const newQuantities = {
        ...prevQuantities,
        [sku]: isNaN(numericValue) ? "" : numericValue,
      };

      setSelectedProducts2((prevSelected) => {
        const existingProduct = prevSelected.find(
          (product) => product.product_sku === sku
        );

        if (existingProduct) {
          return prevSelected.map((product) =>
            product.product_sku === sku
              ? { ...product, product_quantity: newQuantities[sku] }
              : product
          );
        } else {
          return [
            ...prevSelected,
            { product_sku: sku, product_quantity: newQuantities[sku] },
          ];
        }
      });

      return newQuantities;
    });
  };

  const isQuantityValid = (productSku) => {
    return quantities[productSku] && quantities[productSku] > 0;
  };

  const stockin = [
    {
      field: "product_sku",
      headerName: "SKU",
      width: 150,
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 400,
    },
    {
      field: "quantiy",
      headerName: "Quantity",
      width: 200,
      renderCell: (params) => (
        <>
          <TextField
            label="Quantity"
            variant="outlined"
            size="small"
            value={quantities[params.row.product_sku] || ""}
            onChange={(e) =>
              handleQtyChange(params.row.product_sku, e.target.value)
            }
            style={{ marginTop: "5px" }}
          />
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleDeleteStockin(params.row.product_sku)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleDeleteStockin = (productSku) => {
    setSelectedProducts2((prevProducts) =>
      prevProducts.filter((product) => product.product_sku !== productSku)
    );
  };

  // Debugging quantities state
  useEffect(() => {
    console.log("Quantities updated:", quantities);
  }, [quantities]);

  const [stockInModal, setStockInModal] = useState(false);
  const [p_sku, setP_SKU] = useState("");

  const [refreshKey, setRefreshKey] = useState(0);

  const [qty, setQty] = useState("");
  const handleEditClick = (sku) => {
    setP_SKU(sku);
    setStockInModal(true);
    const quantity = quantities[sku] || 0;
    setQty(quantity);
  };

  const fetchStockInReasons = () => {
    axios
      .get(stock_in_reasons, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data && response.data.data) {
          setStockInReasons(response.data.data);
        } else {
          console.error("Unexpected response structure", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching stock in reasons:", error);
      });
  };

  useEffect(() => {
    fetchStockInReasons();
  }, [type]);

  const [selectedReason, setSelectedReason] = useState("");

  const handleStockInReasonChange = (event) => {
    // Get the value of the selected reason from the event
    const selectedValue = event.target.value;

    setSelectedReason(selectedValue);
  };

  // ============= Submit Data ===========

  const handleSubmitData = () => {
    const productCounts = selectedProducts.reduce((acc, product) => {
      acc[product.product_sku] = (acc[product.product_sku] || 0) + 1;
      return acc;
    }, {});

    const formattedInventory = selectedProducts.reduce((acc, product) => {
      const existingEntry = acc.find(
        (entry) => entry.product_sku === product.product_sku
      );

      if (existingEntry) {
        const existingInventoryDetail = existingEntry.inventoryDetails.find(
          (detail) => detail.inventory_pk == product.inventoryDetail
        );

        if (existingInventoryDetail) {
          existingInventoryDetail.quantity += product.quantity;
        } else {
          existingEntry.inventoryDetails.push({
            inventory_pk: parseInt(product.inventoryDetail),
            quantity: product.quantity,
          });
        }
      } else {
        acc.push({
          product_sku: product.product_sku,
          inventoryDetails: [
            {
              inventory_pk: parseInt(product.inventoryDetail),
              quantity: product.quantity,
            },
          ],
        });
      }

      return acc;
    }, []);

    setSubmitLoader(true);

    const stockInformattedData = {
      user_name: userDetails.name,
      remark: notes,
      products: selectedProducts2.map((product) => ({
        sku: product.product_sku,
        quantity: parseInt(product.product_quantity),
      })),
      reason_pk: parseInt(selectedReason),
      warehouse_id: warehouse,
    };

    if (type == 1) {
      if (!selectedProducts2 || selectedProducts2.length === 0) {
        toast.error("Please select products");
        setSubmitLoader(false);
        // setOpenError(true);
        return;
      }

      const hasEmptyQuantityinProduct = selectedProducts2.some(
        (product) =>
          product.product_quantity === 0 ||
          product.product_quantity === null ||
          product.product_quantity === undefined
      );

      if (hasEmptyQuantityinProduct) {
        toast.error("Quantity cannot be empty for any product.");
        setSubmitLoader(false);
        // setOpenError(true);
        return;
      }

      if (!selectedReason || selectedReason === "") {
        toast.error("Please select reason");
        setSubmitLoader(false);
        return;
      }
      axios
        .post(bulk_Stock_In, stockInformattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            // setOpenSuccess(true);
            setSubmitLoader(false);
            navigate("/stock_adjustments");
            toast.success(`Stock In Successful!`);
            setSelectedProducts([]);
            setNotes("");
            setType("");
            setInven([]);
          } else {
            setSubmitLoader(false);
            toast.error(response.data.message);

            // setOpenError(true);
          }
        })
        .catch((error) => {
          setSubmitLoader(false);
          toast.error(error.response.data.message);
          // setOpenError(true);
        });
    } else if (type == 2) {
      if (!scannedProducts || scannedProducts.length === 0) {
        setSubmitLoader(false);
        toast.error("Please Select a Product.");
        // setOpenError(true);
        return;
      }

      // Function to format the inventory data
      const formattedProducts = scannedProducts.map((product) => ({
        product_sku: product.product_sku,
        qty:
          product.type === "big"
            ? product.selectedSeries.length || 0
            : parseInt(product.qty) || 0,
        selected_series: product.selectedSeries || [],
      }));

      const formattedOutData = {
        user_name: userDetails.name,
        remark: notes,
        warehouse_pk: warehouse,
        products: formattedProducts,
      };

      axios
        .post(bilk_Stock_Out, formattedOutData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            setSubmitLoader(false);
            navigate("/stock_adjustments");
            setSelectedProducts([]);
            setNotes("");
            setInven([]);
            setScannedProducts([]);
            setType("");
            toast.success(`Stock Out Successfully`);
          } else {
            setSubmitLoader(false);
            toast.error(response.data.message);
            // setOpenError(true);
          }
        })
        .catch((error) => {
          setSubmitLoader(false);
          toast.error(error.response.data.message);
          console.log(error);
        });
    }
  };

  // ========== New Stock Out =========

  const inputRef = useRef(null); // Create a ref for the input field
  const [scanData, setScanData] = useState("");
  const [ScanFocus, setScanFocus] = useState(0);
  const [itemDisplay, setItemDisplay] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [customer, setCustomer] = useState("");
  const [scannedProducts, setScannedProducts] = useState([]);
  const [openRemoveP, setOpenRemoveP] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);

  const handleClickSmallItems = () => {
    setItemDisplay((prevValue) => (prevValue === 1 ? 0 : 1));
    // setScanData("");
    setSearchInput("");
  };

  const handleScanFocus = () => {
    setScanFocus((prevValue) => (prevValue === 1 ? 0 : 1));
  };

  // Focus the input when itemDisplay is 0
  useEffect(() => {
    if (ScanFocus === 0 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [ScanFocus]);

  // === fetch_all_product_transfer ===

  const debounceFetchProducts = useCallback(
    debounce(async (value) => {
      try {
        const body = {
          search: value,
          warehouse_pk: parseInt(warehouse),
        };

        const response = await axios.post(
          fetch_all_product_transfer,
          // { search: value, warehouse_pk: parseInt(warehouse) },
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          }
        );

        const fetchedProducts = response.data.data || [];

        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    }, 500), // 500ms delay
    [customer, warehouse] // Dependency array
  );

  const handleInputChangeSmall = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchInput(value);

      if (value.trim().length <= 1) {
        setDisplay(false);
      } else if (value.trim().length >= 2) {
        debounceFetchProducts(value);
      } else {
        setProducts([]);
        setDisplay(false);
      }
    },
    [debounceFetchProducts]
  );

  const isProductSelected = (product) => {
    return scannedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleDeleteProduct = (product_sku) => {
    setScannedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
  };

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      const body = {
        search: sku,
        warehouse_pk: parseInt(warehouse),
      };
      axios
        .post(
          fetch_all_product_transfer,
          // { search: sku, warehouse_pk: parseInt(warehouse) },
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          }
        )
        .then((response) => {
          if (response.data.status === 1) {
            if (response.data.data.length > 0) {
              const productData = response.data.data[0];
              addScannedProduct(sku, productData);
            } else {
              toast.error("Product Not Found");
              // setOpenError(true);
            }
          } else if (response.data.status === 0) {
            toast.error(response.data.message);
            // setOpenError(true);
          }
          setScanData("");
        })
        .catch((error) => {
          // setOpenError(true);

          toast.error(error.response.data.message);
          setScanData("");
        });
    }, 400),
    [customer, warehouse]
  );

  useEffect(() => {
    handleInputChangeScan(scanData);
  }, [scanData]);

  const handleInputChangeScan = (scanData) => {
    const newValue = scanData;

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    if (newValue.length > 5) {
      if (isValidProductSku(newValue)) {
        let seriesExists = false;

        for (const product of scannedProducts) {
          if (
            product.sku === newValue.split("_")[0] &&
            product.additional_info?.includes(
              parseInt(newValue.split("_")[1], 10)
            )
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(newValue.split("_")[0]);
          setAdditionalInfoToRemove(parseInt(newValue.split("_")[1], 10));
          setOpenRemoveP(true);

          return;
        } else {
          fetchProductData(newValue);
          playSound();
        }
      } else {
        playSound();
      }
    }
    setScanData("");
  };

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      // const [baseSku, additionalInfoString] = sku.split("_");
      // const additionalInfo = parseInt(additionalInfoString, 10);
      let baseSku;
      let additionalInfoString;
      let additionalInfo;

      if (sku.includes("_")) {
        [baseSku, additionalInfoString] = sku.split("_");
        additionalInfo = parseInt(additionalInfoString, 10);
      } else if (sku.includes("-")) {
        baseSku = sku.split("-")[1];

        additionalInfoString = undefined;
        additionalInfo = undefined; // No additional info
      } else {
        baseSku = sku;
        additionalInfoString = undefined;
        additionalInfo = undefined; // Set to undefined or any default value as needed
      }
      const seriesAvailable = productData.serieses.map(
        (series) => series.series == additionalInfo
      );

      if (additionalInfoString) {
        if (seriesAvailable.some((isAvailable) => isAvailable)) {
          setScannedProducts((prev) => {
            const existingProduct = prev.find(
              (item) =>
                item.sku.slice("-")[1] === baseSku ||
                item.product_sku.slice("-")[1] === baseSku ||
                item.sku === baseSku
            );

            if (existingProduct) {
              // Check if the cur_series is already in the selectedSeries array
              if (
                existingProduct.selectedSeries.includes(
                  productData.cur_series || parseInt(additionalInfoString)
                )
              ) {
                setOpenRemoveSkipProductModal(true);
                setSelectedRemoveSkipProductDetail({
                  existingProduct,
                  cur_series:
                    productData.cur_series || parseInt(additionalInfoString),
                });
                return prev; // Do not add the series if it is already selected
              }

              return prev.map((item) => {
                if (item.sku === baseSku) {
                  const newSeries = {
                    series:
                      productData.cur_series || parseInt(additionalInfoString),
                    ware_house__ware_house: productData.serieses.find(
                      (series) => series.series === productData.cur_series
                    )?.ware_house__ware_house,
                    rack__rack_name: productData.serieses.find(
                      (series) => series.series === productData.cur_series
                    )?.rack__rack_name,
                    rack__row__row_name: productData.serieses.find(
                      (series) => series.series === productData.cur_series
                    )?.rack__row__row_name,
                  };

                  return {
                    ...item,
                    selectedSeries: [
                      ...item.selectedSeries,
                      ...(productData.cur_series
                        ? [productData.cur_series]
                        : []),
                      ...(additionalInfoString
                        ? [parseInt(additionalInfoString)]
                        : []),
                    ],
                  };
                }
                toast.success("Product Added !");
                return item;
              });
            }

            const newProduct = {
              sku: baseSku,
              product_name: productData.product_name,
              product_sku: productData.product_sku,
              type: "big",
              cur_series: productData.cur_series,
              all_series: productData.serieses.map((productSeries) => ({
                series: productSeries.series,
                ware_house__ware_house: productSeries.ware_house__ware_house,
                rack__rack_name: productSeries.rack__rack_name,
                rack__row__row_name: productSeries.rack__row__row_name,
              })),
              selectedSeries: productData.cur_series
                ? [productData.cur_series]
                : additionalInfo
                ? [additionalInfo]
                : [],
            };

            toast.success("Product Added!");

            return [...prev, newProduct];
          });
        } else {
          const warehouseName = warehouses
            .filter((ware) => ware.id == warehouse)
            .map((ware) => ware.ware_house);
          toast.error(`Product is Not available in ${warehouseName} `);
        }
      } else {
        setScannedProducts((prev) => {
          const existingProduct = prev.find((item) => {
            const sku = item.sku || ""; // Default to empty string if sku is undefined
            const productSku = item.product_sku || ""; // Default to empty string if product_sku is undefined

            return (
              sku.slice("-")[1] === baseSku ||
              productSku.slice("-")[1] === baseSku ||
              sku === baseSku
            );
          });

          if (existingProduct) {
            return prev.filter(
              (item) => item.product_sku !== existingProduct.product_sku
            );
          }

          const newProduct = {
            sku: baseSku,
            product_name: productData.product_name,
            product_sku: productData.product_sku,
            type: "big",
            cur_series: productData.cur_series,
            all_series: productData.serieses.map((productSeries) => ({
              series: productSeries.series,
              ware_house__ware_house: productSeries.ware_house__ware_house,
              rack__rack_name: productSeries.rack__rack_name,
              rack__row__row_name: productSeries.rack__row__row_name,
            })),
            selectedSeries: productData.cur_series
              ? [productData.cur_series]
              : additionalInfo
              ? [additionalInfo]
              : [],
          };
          toast.success("Product Added !");
          return [...prev, newProduct];
        });
      }
    }, 300),
    [warehouse] // dependencies
  );

  // Series Modal Open
  const [openSeriesModal, setOpenSeriesModal] = useState(false);
  const [selectedSeriesProduct, setSelectedSeriesProduct] = useState(null);

  const handleOpenSeriesModal = (product) => {
    setSelectedSeriesProduct(product); // Set the selected product to pass to the modal
    setOpenSeriesModal(true); // Open the modal
  };

  const handleCloseSeriesModal = () => {
    setOpenSeriesModal(false); // Close the modal
    setSelectedSeriesProduct(null); // Clear the selected product
  };

  // For Big product update Series.
  const [openRemoveSkipProductModal, setOpenRemoveSkipProductModal] =
    useState(false);
  const [selectedRemoveSkipProductDetail, setSelectedRemoveSkipProductDetail] =
    useState([]);

  const handleCloseOpenRemoveSkipProductModal = () => {
    setOpenRemoveSkipProductModal(false);
  };

  const updateSelectedSeries = (sku, selectedSeries, cur_series) => {
    setScannedProducts((prev) => {
      return prev.map((product) => {
        if (product.sku === sku) {
          // Check if the cur_series is already in the selectedSeries array
          if (selectedSeries.includes(cur_series)) {
            setOpenRemoveSkipProductModal(true);
            setSelectedRemoveSkipProductDetail({
              product,
              cur_series: cur_series,
            });
            return product; // Do not update the product if the series is already selected
          }
          return { ...product, selectedSeries };
        }
        return product;
      });
    });
  };

  const handleSamllProductQuantityChange = (e, product, index) => {
    const value = Math.max(
      0,
      Math.min(Number(e.target.value), product.in_stock)
    );
    const updatedProducts = [...scannedProducts];
    updatedProducts[index].qty = value;
    setScannedProducts(updatedProducts);
  };

  // Warehouse check

  const [openWarehouseModalChange, setOpenWarehouseModalChange] =
    useState(false);
  const [pendingWarehouse, setPendingWarehouse] = useState("");

  const handleWarehouseChange = (e) => {
    const selectedWarehouseId = e.target.value;

    // Check if selectedWarehouseId is valid
    if (selectedWarehouseId) {
      const selectedWarehouse = warehouses.find(
        (wh) => wh.id === selectedWarehouseId
      );

      if (scannedProducts && scannedProducts.length > 0) {
        setPendingWarehouse(selectedWarehouse.id);
        setOpenWarehouseModalChange(true);
      } else if (selectedProducts2 && selectedProducts2.length > 0) {
        setPendingWarehouse(selectedWarehouse.id);
        setOpenWarehouseModalChange(true);
      } else {
        setPendingWarehouse(selectedWarehouse.id);
        setWarehouse(selectedWarehouse.id);
      }
    }
  };

  const handleConfirmWarehouseChange = () => {
    setWarehouse(pendingWarehouse);
    setOpenWarehouseModalChange(false);
    setScannedProducts([]);
    setSelectedProducts([]);
    setSelectedProducts2([]);
  };

  const handleCancelWarehouseChange = () => {
    setOpenWarehouseModalChange(false);
    setPendingWarehouse("");
  };

  // Web Cam
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const [isScanned, setIsScanned] = useState(false); // State to track if a barcode has been scanned

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
    setIsScanned(false); // Reset scanning state when opening the camera
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const handleDetected = useCallback(
    (result) => {
      const scannedCode = result.codeResult.code;

      // Only set scan data and play sound if a barcode has not already been scanned
      if (!isScanned) {
        if (type == 2) {
          setScanData(scannedCode);
        }
        playSound();
        setIsScanned(true); // Mark that a barcode has been scanned
        setIsCameraOpen(false);
      }
    },
    [isScanned]
  );

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc && !isScanned) {
          // Only scan if not already scanned
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0, // Needs to be 0 when used with `decodeSingle`
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"], // Adjust according to your needs
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 700);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected, isScanned]);

  // Add these two functions above your component return statement
  const handleFocus = () => {
    fetchProducts();
    setDisplay(true);
  };

  const handleBlur = (e) => {};

  return (
    <>
      {submitLoader && <SubmitLoader />}
      <Toaster position="top-right" />

      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      <RemoveSkipProduct
        selectedRemoveSkipProductDetail={selectedRemoveSkipProductDetail}
        handleCloseOpenRemoveSkipProductModal={
          handleCloseOpenRemoveSkipProductModal
        }
        scannedProducts={scannedProducts}
        setScannedProducts={setScannedProducts}
        openRemoveSkipProductModal={openRemoveSkipProductModal}
      />

      {/* // Out Stock Big Type Size Product Series Selection Modal  */}
      {openSeriesModal && (
        <SeriesModal
          open={openSeriesModal}
          handleClose={handleCloseSeriesModal}
          product={selectedSeriesProduct}
          updateSelectedSeries={updateSelectedSeries}
        />
      )}

      <AssignRackModal
        openModal={stockInModal}
        setOpenModal={setStockInModal}
        warehouseId={warehouse}
        p_sku={p_sku}
        setInven={setInven}
        inven={inven}
        qty={qty}
      />

      {/* Confirmation change type Modal */}
      <Dialog open={openTypeModalChange} onClose={handleCancelTypeChange}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <p>Changing the type will remove all data. Do you want to proceed?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelTypeChange}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmTypeChange}
            color="primary"
            variant="outlined"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Modal */}
      <Dialog
        open={openWarehouseModalChange}
        onClose={handleCancelWarehouseChange}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <p>
            Changing the warehouse will remove all data. Do you want to proceed?
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelWarehouseChange}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmWarehouseChange}
            color="primary"
            variant="outlined"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/stock_adjustments")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography color="inherit">Adjustments</Typography>
              <Typography sx={{ color: "text.primary" }}>
                New Adjustment
              </Typography>
            </Breadcrumbs>
          </div>
          {isCameraOpen ? (
            <>
              <div className="scannerDiv">
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  width={1920}
                  height={220}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    facingMode: "environment",

                    border: "none",
                  }}
                  style={{
                    marginBottom: "12px",
                    border: "3px dashed green",
                    padding: "0",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseCamera}
                >
                  Close
                </Button>
              </div>
            </>
          ) : null}
          <div className="card p-3 my-1">
            <div className="row">
              <div className="col-lg-2 my-2">
                <FormControl fullWidth size="small">
                  <InputLabel id="warehouse-select-label">
                    Stock Type
                  </InputLabel>
                  <Select
                    labelId="stock-select-label"
                    value={type}
                    onChange={handleTypeChange} // Use the handler here
                    label="Stock Type"
                  >
                    <MenuItem value="">--Select--</MenuItem>
                    <MenuItem value="1">Stock In</MenuItem>
                    <MenuItem value="2">Stock Out</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-2 my-2">
                <FormControl fullWidth size="small">
                  <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
                  <Select
                    labelId="warehouse-select-label"
                    value={warehouse} // Ensure this is controlled by state
                    onChange={handleWarehouseChange}
                    label="Warehouse"
                  >
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {type && type == 1 ? (
                <>
                  <div className="col-lg-2 my-2">
                    <FormControl fullWidth size="small">
                      <InputLabel id="warehouse-select-label">
                        Reason
                      </InputLabel>
                      <Select
                        labelId="stock-select-label"
                        value={selectedReason} // Update this to bind to selectedReason
                        onChange={handleStockInReasonChange}
                        label="Reason"
                      >
                        {stockInReasons &&
                          stockInReasons.map((reason) => (
                            <MenuItem key={reason.id} value={reason.id}>
                              {reason.reason}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </>
              ) : (
                <div className="col-lg-2"></div>
              )}

              <div className="col-lg-4 my-2">
                <TextField
                  size="small"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  style={{ width: "100%" }}
                  label="Notes"
                ></TextField>
              </div>

              <div className="col-lg-2 my-2">
                <Button
                  disabled={!warehouse || !type}
                  onClick={handleSubmitData}
                  variant="contained"
                >
                  Confirm Adjustment
                </Button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 my-2">
              {type ? (
                <>
                  <div className="card p-3">
                    {type && type == 1 ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <TextField
                            size="small"
                            key={refreshKey}
                            fullWidth
                            type="search"
                            disabled={!type || !istyping}
                            label="Search Products..."
                            value={searchQuery} // Control the value of the input
                            onChange={handleInputChange} // Update onChange
                            onFocus={handleFocus} // Add this line
                            onBlur={handleBlur} // Add this line
                            autoComplete="off"
                          />
                        </div>

                        {displaySearch && displaySearch ? (
                          <>
                            <div id="searchList" ref={searchListRef}>
                              {products.map((product, index) => (
                                <>
                                  <div
                                    className="card  p-3"
                                    style={{ display: "flex" }}
                                    key={index}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={selectedProducts2.some(
                                            (prev) =>
                                              prev.product_sku ===
                                              product.product_sku
                                          )}
                                          onClick={() =>
                                            handleCheckboxChange(product)
                                          }
                                          name="check"
                                        />
                                      }
                                      label={
                                        product.product_sku +
                                        " : " +
                                        product.product_name
                                      }
                                    />
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : type == 2 ? (
                      <>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <TextField
                            inputRef={inputRef}
                            onFocus={
                              itemDisplay && itemDisplay === 1
                                ? handleFocus
                                : ""
                            }
                            onChange={
                              itemDisplay && itemDisplay === 1
                                ? handleInputChangeSmall
                                : (e) => setScanData(e.target.value)
                            }
                            value={
                              itemDisplay && itemDisplay === 1
                                ? searchInput
                                : scanData
                            }
                            // value={searchInput}
                            fullWidth
                            size="small"
                            label={
                              itemDisplay && itemDisplay === 1
                                ? "Search Products"
                                : "Scan Product"
                            }
                            variant="outlined"
                          />

                          {itemDisplay === 1 ? null : (
                            <>
                              <BsUpcScan
                                onClick={handleOpenCamera}
                                style={{
                                  fontSize: "30px",
                                  padding: "8px",
                                  height: "37px",
                                  width: "38px",
                                  cursor: "pointer",
                                  border: "1px solid #1565c0",
                                  borderRadius: "5px",
                                  marginLeft: "5px",
                                  color: "#1565c0",
                                }}

                                // onClick={handleOpenCamera}
                              />
                            </>
                          )}

                          {itemDisplay && itemDisplay === 1 ? (
                            <>
                              <BsUpcScan
                                // onClick={handleClickSmallItems }
                                onClick={() => {
                                  handleOpenCamera();
                                  handleClickSmallItems();
                                }}
                                style={{
                                  fontSize: "30px",
                                  padding: "8px",
                                  height: "37px",
                                  width: "38px",
                                  cursor: "pointer",
                                  border: "1px solid #1565c0",
                                  borderRadius: "5px",
                                  marginLeft: "5px",
                                  color: "#1565c0",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <IoSearch
                                onClick={handleClickSmallItems}
                                style={{
                                  fontSize: "30px",
                                  padding: "8px",
                                  height: "37px",
                                  width: "38px",
                                  cursor: "pointer",
                                  border: "1px solid red",
                                  borderRadius: "5px",
                                  marginLeft: "5px",
                                  color: "red",
                                }}
                              />
                            </>
                          )}
                        </div>

                        {displaySearch && displaySearch ? (
                          <>
                            <div id="searchList" ref={searchListRef}>
                              {products.map((product, index) => (
                                <>
                                  <div
                                    className="card  p-3"
                                    style={{ display: "flex" }}
                                    key={index}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={isProductSelected(product)}
                                          onChange={() =>
                                            handleCheckboxChange(product)
                                          }
                                          name="check"
                                        />
                                      }
                                      label={
                                        product.product_sku +
                                        " : " +
                                        product.product_name
                                      }
                                    />
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>

            <div className="col-lg-9 my-2">
              {type ? (
                <>
                  <div className="card p-3">
                    {type && type == 1 ? (
                      <>
                        {/* // Stock In  Table Data  */}
                        {isWideScreen ? (
                          <>
                            <div
                              className="responsive-table1"
                              style={{ maxHeight: "71vh" }}
                            >
                              <table className="ui table celled " id="c_table">
                                <thead className="table-head">
                                  <tr>
                                    <th>#</th>
                                    <th>SKU</th>
                                    <th>Product</th>
                                    <th>Quantity</th>

                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedProducts2 &&
                                  selectedProducts2.length > 0 ? (
                                    <>
                                      {selectedProducts2.map(
                                        (product, index) => (
                                          <tr key={index}>
                                            <td component="th" scope="row">
                                              {index + 1}
                                            </td>
                                            <td>{product.product_sku || ""}</td>
                                            <td>
                                              {product.product_name || ""}
                                            </td>
                                            <td style={{ maxWidth: "90px" }}>
                                              <TextField
                                                label="Quantity"
                                                variant="outlined"
                                                size="small"
                                                value={
                                                  quantities[
                                                    product.product_sku
                                                  ] || ""
                                                }
                                                onChange={(e) =>
                                                  handleQtyChange(
                                                    product.product_sku,
                                                    e.target.value
                                                  )
                                                }
                                                style={{ marginTop: "5px" }}
                                              />
                                            </td>
                                            <td>
                                              <Button
                                                size="small"
                                                variant="outlined"
                                                color="error"
                                                onClick={() =>
                                                  handleDeleteStockin(
                                                    product.product_sku
                                                  )
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <tr>
                                        <td colSpan="6">
                                          <div className="notfound_img_div">
                                            <div className="notfound_img"></div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="card p-2 responsive-mobile-table">
                              {selectedProducts2.length > 0 ? (
                                <>
                                  {selectedProducts2 &&
                                    selectedProducts2.map((data) => (
                                      <>
                                        <div className="product-card">
                                          <div className="py-1 px-2">
                                            <h5 className="product-card-sku">
                                              #{data.product_sku || ""}
                                            </h5>
                                            <h5 className="product-card-name my-1">
                                              {data.product_name || ""}
                                            </h5>

                                            <FaTrashAlt
                                              onClick={() =>
                                                handleDeleteStockin(
                                                  data.product_sku
                                                )
                                              }
                                              style={{
                                                cursor: "pointer",
                                                fontSize: "25px",
                                                marginTop: "10px",
                                                position: "absolute",
                                                right: "15px",
                                                border: "1px solid red",
                                                borderRadius: "5px",
                                                padding: "5px",
                                                color: "red",
                                              }}
                                            />

                                            <TextField
                                              style={{
                                                fontSize: "11px",
                                                width: "90px",
                                              }}
                                              label="Qty"
                                              variant="outlined"
                                              size="small"
                                              value={
                                                quantities[data.product_sku] ||
                                                ""
                                              }
                                              onChange={(e) =>
                                                handleQtyChange(
                                                  data.product_sku,
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                </>
                              ) : (
                                <>
                                  <div className="notfound_img_div">
                                    <div className="notfound_img"></div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : type == 2 ? (
                      <>
                        {/* // Stock Out  Table Data  */}
                        {isWideScreen ? (
                          <>
                            <div
                              className="responsive-table1"
                              style={{ maxHeight: "71vh" }}
                            >
                              <table className="ui table celled " id="c_table">
                                <thead className="table-head">
                                  <tr>
                                    <th>#</th>
                                    <th>SKU</th>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                    <th style={{ textAlign: "center" }}>
                                      In Stock
                                    </th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {scannedProducts &&
                                  scannedProducts.length > 0 ? (
                                    <>
                                      {scannedProducts.map((product, index) => (
                                        <tr
                                          key={index}
                                          style={{
                                            backgroundColor:
                                              product.type !== "big"
                                                ? "#e6f1f7"
                                                : "transparent",
                                          }}
                                        >
                                          <td component="th" scope="row">
                                            {index + 1}
                                          </td>
                                          <td>{product.product_sku || ""}</td>
                                          <td>{product.product_name || ""}</td>

                                          {product.type == "big" ? (
                                            <>
                                              <td style={{ maxWidth: "80px" }}>
                                                <TextField
                                                  className="p-0 m-0"
                                                  size="small"
                                                  type="number"
                                                  value={
                                                    product.selectedSeries
                                                      ? product.selectedSeries
                                                          .length
                                                      : 0
                                                  }
                                                  disabled={true}
                                                />
                                              </td>
                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {product.all_series
                                                  ? product.all_series.length
                                                  : 0}
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              <td style={{ maxWidth: "80px" }}>
                                                <TextField
                                                  className="p-0 m-0"
                                                  size="small"
                                                  type="number"
                                                  value={product.qty || ""}
                                                  onChange={(e) =>
                                                    handleSamllProductQuantityChange(
                                                      e,
                                                      product,
                                                      index
                                                    )
                                                  }
                                                  InputProps={{
                                                    inputProps: {
                                                      min: 0,
                                                    },
                                                  }}
                                                  placeholder="Quantity"
                                                  autoComplete="off"
                                                />
                                              </td>

                                              <td
                                                style={{ textAlign: "center" }}
                                              >
                                                {product.in_stock
                                                  ? product.in_stock
                                                  : 0}
                                              </td>
                                            </>
                                          )}

                                          <td style={{ width: "160px" }}>
                                            <>
                                              {product.type == "big" ? (
                                                <Button
                                                  onClick={() =>
                                                    handleOpenSeriesModal(
                                                      product
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  variant="outlined"
                                                  color="primary"
                                                  size="small"
                                                >
                                                  Select Series
                                                </Button>
                                              ) : (
                                                <button
                                                  style={{
                                                    minWidth: "115px",
                                                    border: "none",
                                                    background: "none",
                                                  }}
                                                ></button>
                                              )}

                                              <Button
                                                onClick={() =>
                                                  handleDeleteProduct(
                                                    product.product_sku
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  marginLeft: "5px",
                                                }}
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                              >
                                                Delete
                                              </Button>
                                            </>
                                          </td>
                                        </tr>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      <tr>
                                        <td colSpan="6">
                                          <div className="notfound_img_div">
                                            <div className="notfound_img"></div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="card p-2 responsive-mobile-table">
                              {scannedProducts.length > 0 ? (
                                <>
                                  {scannedProducts &&
                                    scannedProducts.map((data, index) => (
                                      <>
                                        <div className="product-card">
                                          <div className="py-1 px-2">
                                            <h5 className="product-card-sku">
                                              #{data.product_sku || ""}
                                            </h5>
                                            <h5 className="product-card-name my-1">
                                              {data.product_name || ""}
                                            </h5>

                                            {data.type == "big" ? (
                                              <>
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <TextField
                                                    style={{ maxWidth: "80px" }}
                                                    size="small"
                                                    type="number"
                                                    value={
                                                      data.selectedSeries
                                                        ? data.selectedSeries
                                                            .length
                                                        : 0
                                                    }
                                                    disabled={true}
                                                  />

                                                  <Label
                                                    color="red"
                                                    style={{
                                                      padding: "12.5px 9px",
                                                    }}
                                                  >
                                                    {data.all_series
                                                      ? data.all_series.length
                                                      : 0}{" "}
                                                    QTY
                                                  </Label>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <TextField
                                                    style={{ maxWidth: "80px" }}
                                                    size="small"
                                                    type="number"
                                                    value={data.qty || ""}
                                                    onChange={(e) =>
                                                      handleSamllProductQuantityChange(
                                                        e,
                                                        data,
                                                        index
                                                      )
                                                    }
                                                    InputProps={{
                                                      inputProps: {
                                                        min: 0,
                                                      },
                                                    }}
                                                    placeholder="Qty"
                                                    autoComplete="off"
                                                  />

                                                  <Label
                                                    color="red"
                                                    style={{
                                                      padding: "12.5px 9px",
                                                    }}
                                                  >
                                                    {data.in_stock
                                                      ? data.in_stock
                                                      : 0}{" "}
                                                    QTY
                                                  </Label>
                                                </div>
                                              </>
                                            )}

                                            <div
                                              style={{
                                                position: "absolute",
                                                right: "13px",
                                                marginTop: "-26px",
                                              }}
                                            >
                                              {data.type == "big" ? (
                                                <IoCheckmarkDoneSharp
                                                  style={{
                                                    cursor: "pointer",
                                                    fontSize: "25px",

                                                    border: "1px solid green",
                                                    borderRadius: "5px",
                                                    padding: "5px",
                                                    color: "green",
                                                  }}
                                                  onClick={() =>
                                                    handleOpenSeriesModal(data)
                                                  }
                                                />
                                              ) : (
                                                <button
                                                  style={{
                                                    minWidth: "115px",
                                                    border: "none",
                                                    background: "none",
                                                  }}
                                                ></button>
                                              )}
                                              <FaTrashAlt
                                                onClick={() =>
                                                  handleDeleteProduct(
                                                    data.product_sku
                                                  )
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "25px",
                                                  marginLeft: "10px",
                                                  border: "1px solid red",
                                                  borderRadius: "5px",
                                                  padding: "5px",
                                                  color: "red",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                </>
                              ) : (
                                <>
                                  <div className="notfound_img_div">
                                    <div className="notfound_img"></div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <audio id="scanSound" src={soundEffect} />
    </>
  );
}

export default AddStockAdjustment;

import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_customer,
  fetch_sale_detail,
  validateProductForDispatch,
  DispatchSale,
  checkAvailableStockQty,
  fetch_warehouse,
} from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Webcam from "react-webcam";
import Quagga from "quagga";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Switch from "@mui/material/Switch";
import { debounce } from "lodash";
import soundEffect from "../../Assets/sounds/beep.mp3";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IoMdArrowRoundBack } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function DispatchDetails() {
  const webcamRef = useRef(null);
  const inputRef = useRef(null); // Create a ref for the input field
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [errormsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState("");
  const { id } = useParams();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const [customer, setCustomer] = useState("");
  const [sale_date, setS_date] = useState("");

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState("");

  useEffect(() => {
    fetchCustomers();
    fetchSales();
    fetchDataWarehouse();
  }, [searchquery, warehouse]);

  const fetchDataWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const fetchCustomers = (query = searchquery) => {
    axios
      .get(fetch_customer, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          search: query,
        },
      })
      .then((response) => {
        setCustomers(response.data.customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [smallQty, setSmallQty] = useState({});
  const fetchSales = () => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(id),
        },
      })
      .then((response) => {
        setCustomer(response.data.data.billing_customer.id);
        setS_date(response.data.data.sale_date);
        const sortedProducts = response.data.data.items.filter(
          (product) => product.dispatched_quantity !== product.quantity
        );
        setSelectedProducts(sortedProducts);
        setSmallQty(
          sortedProducts.reduce((acc, product) => {
            acc[product.product_sku] = "";
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getCustomerDetails = (customerId) => {
      const foundCustomer = customers.find((cust) => cust.id === customerId);
      if (foundCustomer) {
        return {
          name: foundCustomer.customer_name,
          primaryShipping: foundCustomer.primary_shipping || {},
        };
      }
      return {
        name: "",
        primaryShipping: {},
      };
    };

    const customerDetails = getCustomerDetails(customer);
  }, [customer, customers]);

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [scanData, setScanData] = useState("");
  const [openRemoveP, setOpenRemoveP] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
  };

  const handleDetected = useCallback((result) => {
    const scannedCode = result.codeResult.code;
    setScanData(scannedCode);
  }, []);

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const handleInputChange = (scanData) => {
    const newValue = scanData;

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    const isValidProductSku = (value) => {
      const regex = /^\d{6}_\d+$/;
      return regex.test(value);
    };

    if (newValue.length > 5) {
      if (isValidProductSku(newValue)) {
        let seriesExists = false;

        for (const product of selectedProducts) {
          if (
            product.sku === newValue.split("_")[0] &&
            product.additional_info?.includes(
              parseInt(newValue.split("_")[1], 10)
            )
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(newValue.split("_")[0]);
          setAdditionalInfoToRemove(parseInt(newValue.split("_")[1], 10));
          setOpenRemoveP(true);

          return;
        } else {
          fetchProductData(newValue);
          setIsCameraOpen(false);
          playSound();
        }
      } else {
        playSound();
      }
    }
    setScanData("");
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      if (!warehouse) {
        toast.error("Please Select Warehouse");
        return;
      }

      axios
        .get(validateProductForDispatch, {
          params: {
            product_value: sku,
            sale_pk: parseInt(id),
            warehouse_pk: parseInt(warehouse),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          const productData = response.data.product;

          addScannedProduct(sku, productData);
          toast.success("Product added!");

          setScanData("");
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setScanData("");
        });
    }, 600),
    [warehouse]
  );

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      const [baseSku, additionalInfoString] = sku.split("_");
      const additionalInfo = parseInt(additionalInfoString, 10);

      if (!productData) {
        toast.error("Product not found for the scanned SKU.");
        return;
      }

      setSelectedProducts((prev) => {
        const existingProduct = prev.find(
          (item) => item.product_sku === productData.product_sku
        );

        if (existingProduct) {
          const totalScanned = existingProduct.additional_info?.length || 0;
          const availableQuantity =
            existingProduct.quantity - existingProduct.dispatched_quantity;

          if (existingProduct.additional_info?.includes(additionalInfo)) {
            setSkuToRemove(baseSku);
            setAdditionalInfoToRemove(additionalInfo);
            setOpenRemoveP(true);
            return prev;
          }

          if (totalScanned >= availableQuantity) {
            toast.error(`Products Already Fulfiled for SKU: ${baseSku}`);
            return prev;
          }

          return prev.map((item) =>
            item.product_sku === productData.product_sku
              ? {
                  ...item,
                  additional_info: [
                    ...(item.additional_info || []),
                    additionalInfo,
                  ],
                }
              : item
          );
        } else {
          return [
            ...prev,
            {
              sku: baseSku,
              product_name: productData.product_name,
              product_sku: productData.product_sku,
              additional_info: [additionalInfo],
            },
          ];
        }
      });
    }, 300),
    []
  );

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0,
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"],
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 700);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected]);

  const handleCloseRemove = () => {
    setOpenRemoveP(false);
    setScanData("");
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const handleRemoveProduct = () => {
    setSelectedProducts((prev) => {
      return prev.map((item) => {
        if (item.product_sku === `KTT-${skuToRemove}`) {
          const updatedAdditionalInfo = item.additional_info.filter(
            (info) => info !== additionalInfoToRemove
          );

          return {
            ...item,
            additional_info: updatedAdditionalInfo,
          };
        }
        return item;
      });
    });

    setOpenRemoveP(false);
    setSkuToRemove("");
    setAdditionalInfoToRemove(null);
  };

  const [checkedStates, setCheckedStates] = useState({});

  const [outsideQty, setOutsideQty] = useState({});
  const handleChange = (productSku) => (event) => {
    const newCheckedStates = {
      ...checkedStates,
      [productSku]: event.target.checked,
    };
    setCheckedStates(newCheckedStates);

    if (!event.target.checked) {
      const newoutsideQty = { ...outsideQty, [productSku]: "" };
      setOutsideQty(newoutsideQty);
    }
  };
  const handleOutSideQtyChange = (productSku) => (event) => {
    const value = Number(event.target.value);

    const maxAllowedValue =
      selectedProducts.find((product) => product.product_sku === productSku)
        .quantity -
      selectedProducts.find((product) => product.product_sku === productSku)
        .dispatched_quantity -
      (selectedProducts.find((product) => product.product_sku === productSku)
        .additional_info
        ? selectedProducts.find((product) => product.product_sku === productSku)
            .additional_info.length
        : smallQty[productSku] || 0);

    if (value > maxAllowedValue) {
      toast.error(`Value cannot exceed ${maxAllowedValue}.`);
    }

    const newValue = Math.min(value, maxAllowedValue);

    const newoutsideQty = { ...outsideQty, [productSku]: newValue };
    setOutsideQty(newoutsideQty);
  };

  const handleSmallQtyChange = (productSku) => (event) => {
    const value = Number(event.target.value);

    const product = selectedProducts.find(
      (prod) => prod.product_sku === productSku
    );
    const maxAllowedValue = product.quantity;

    const alertStock =
      product.quantity - outsideQty[productSku] - product.dispatched_quantity;

    const maxAvailableQty = availableQuantities.find(
      (prod) => prod.product_sku === productSku
    ).available_stock;

    if (value > maxAvailableQty) {
      toast.error(
        `Available Stock is ${maxAvailableQty}. Adjust Stock or Dispatch from Outside!`
      );
    } else if (value > maxAllowedValue) {
      toast.error(`Quantity cannot exceed ${maxAllowedValue}.`);
    } else if (value > alertStock) {
      toast.error(`Quantity can't exceed ${alertStock}.`);
    } else {
      const newSmallQty = { ...smallQty, [productSku]: value };
      setSmallQty(newSmallQty);
    }
  };

  const [availableQuantities, setAvailableQuantities] = useState([]);
  const [openConfirmWare, setConfirmWareChange] = useState(false);
  const [warehouseKey, setWareHouseKey] = useState("");

  const [initialSetup, setInitialSetup] = useState(true);

  const handleWarehouseChange = (e) => {
    const ware = e.target.value;
    setWareHouseKey(ware);

    setConfirmWareChange(true);
  };

  useEffect(() => {
    if (initialSetup && warehouses.length > 0) {
      const gateWarehouse = warehouses.find((ware) =>
        ware.ware_house.toLowerCase().includes("gate")
      );

      if (gateWarehouse) {
        setWarehouse(gateWarehouse.id);
        fetchQuantities(gateWarehouse.id);
      }
      setInitialSetup(false);
    }
  }, [warehouses, initialSetup]);

  const fetchQuantities = (warehouseId) => {
    axios
      .get(checkAvailableStockQty, {
        params: {
          sale_pk: id,
          warehouse_pk: parseInt(warehouseId),
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const quantities = response.data.data;
        setAvailableQuantities(quantities);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleConfirmWareHouseChange = () => {
    fetchQuantities(warehouseKey);

    setWarehouse(warehouseKey);
    setSmallQty("");
    setOutsideQty("");
    setConfirmWareChange(false);
  };

  const handleCloseWare = () => {
    setConfirmWareChange(false);
  };

  const handleSubmit = () => {
    if (!warehouse) {
      toast.error("Please Select Warehouse");
      return;
    }

    const filteredProducts = selectedProducts.filter((product) => {
      const outsideQuantity = outsideQty[product.product_sku] || 0;
      const quantity =
        product.product_status === 0
          ? parseInt(smallQty[product.product_sku]) || 0
          : product.additional_info
          ? product.additional_info.length
          : 0;

      return outsideQuantity !== 0 || quantity !== 0;
    });

    const products = filteredProducts.map((product) => ({
      product_sku: product.product_sku,
      outside_quantity: outsideQty[product.product_sku] || 0,
      serieses: product.additional_info || [],
      quantity:
        product.product_status === 0
          ? parseInt(smallQty[product.product_sku]) || 0
          : product.additional_info
          ? product.additional_info.length
          : 0,
    }));

    const finalData = {
      user_name: userDetails.name,
      sale_pk: parseInt(id),
      warehouse_pk: parseInt(warehouse),
      products,
    };

    if (!id) {
      toast.error("Sale Invalid!");
      return;
    }

    try {
      axios
        .post(DispatchSale, finalData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            toast.success("Sale Dispatched Successfully!");
            fetchSales();
            navigate("/dispatch-history");
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } catch (error) {
      console.log("error While Creating Draft :", error);
    }
  };

  return (
    <>
      <Toaster position="top-right" />
      <audio id="scanSound" src={soundEffect} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error
        openError={openError}
        setOpenError={setOpenError}
        error={error}
        errormsg={errormsg}
      />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Dispatch</Typography>
              <Typography color="inherit">Dispatch Details</Typography>
              <Typography sx={{ color: "text.primary" }}>#{id}</Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-2">
            <div className="card p-4">
              <div className="row">
                <div className="col-lg-3 my-1">
                  Customer:{" "}
                  {customers.find((cust) => cust.id === customer) ? (
                    <span>
                      {
                        customers.find((cust) => cust.id === customer)
                          .customer_name
                      }
                    </span>
                  ) : (
                    <span>No matching customer found</span>
                  )}
                </div>
                <div className="col-lg-5 my-1">Date: {sale_date}</div>
                <div className="col-lg-2 my-1">
                  <FormControl size="small" fullWidth>
                    <InputLabel>Select Warehouse</InputLabel>
                    <Select
                      value={warehouse}
                      label="Select Warehouse"
                      onChange={handleWarehouseChange}
                    >
                      <MenuItem value="">--Select Warehouse--</MenuItem>
                      {warehouses.map((ware) => (
                        <MenuItem key={ware.id} value={ware.id}>
                          {ware.ware_house}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-2 text-end">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Confirm Dispatch
                  </Button>
                </div>
              </div>
            </div>

            {isCameraOpen ? (
              <Webcam
                audio={false}
                ref={webcamRef}
                width={1920}
                height={220}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  facingMode: "environment",
                  border: "none",
                }}
                style={{
                  marginBottom: "12px",
                  border: "3px dashed green",
                  padding: "0",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : null}

            {isCameraOpen ? (
              <>
                <Button
                  size="small"
                  id="mobile_scan"
                  onClick={handleCloseCamera}
                  color="error"
                  variant="contained"
                  style={{
                    marginTop: "5px",
                    maxWidth: "150px",
                    position: "fixed",
                    zIndex: "5",
                    bottom: "5px",
                    right: "5px",
                  }}
                >
                  Close
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="small"
                  id="mobile_scan"
                  onClick={handleOpenCamera}
                  variant="contained"
                  style={{
                    marginTop: "5px",
                    maxWidth: "150px",
                    position: "absolute",
                    bottom: "5px",
                    right: "5px",
                  }}
                >
                  Scan Products
                </Button>
              </>
            )}

            <div className="card my-2 ">
              <input
                autoComplete="off"
                autoFocus
                value={scanData}
                ref={inputRef}
                style={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "4px",
                  maxWidth: "150px",
                  fontSize: "15px",
                  color: "grey",
                  marginTop: "5px",
                  padding: "5px",
                }}
                onChange={(e) => setScanData(e.target.value)}
              />
              <div className="row">
                <div className="card p-2 my-2">
                  <div className="products_table_class">
                    <div className="responsive-table1">
                      <table className="ui table celled" id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>SKU</th>
                            <th align="left">Product</th>
                            <th align="left">Avail Qty</th>
                            <th align="left">Sale Qty</th>
                            <th align="left">Quantity</th>
                            <th align="left">Left Qty</th>
                            <th align="left"> Dispatch From Outside </th>
                            <th align="right">Dispatched</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedProducts && selectedProducts.length > 0 ? (
                            <>
                              {selectedProducts.map((product) => (
                                <tr
                                  key={product.product_sku}
                                  style={{
                                    backgroundColor:
                                      product.product_status === 0
                                        ? "#e0f1ff"
                                        : "transparent",
                                  }}
                                >
                                  <td style={{ width: "6.5vw" }}>
                                    {product.product_sku}
                                  </td>
                                  <td align="left">{product.product_name}</td>

                                  <td align="left">
                                    {availableQuantities.find(
                                      (qty) =>
                                        qty.product_sku === product.product_sku
                                    )?.available_stock || 0}{" "}
                                  </td>

                                  <td align="left">{product.quantity}</td>

                                  <td align="left">
                                    {product.product_status === 0 ? (
                                      <TextField
                                        inputProps={{
                                          min: 0,
                                        }}
                                        fullWidth
                                        disabled={
                                          product.quantity ===
                                          product.dispatched_quantity
                                        }
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        style={{ width: "100px" }}
                                        value={smallQty[product.product_sku]}
                                        onChange={handleSmallQtyChange(
                                          product.product_sku
                                        )}
                                      />
                                    ) : (
                                      (product.additional_info &&
                                        product.additional_info.length) ||
                                      0
                                    )}
                                  </td>

                                  <td align="left" style={{ color: "red" }}>
                                    {product.product_status === 0
                                      ? product.quantity -
                                        product.dispatched_quantity -
                                        (smallQty[product.product_sku] || 0) -
                                        (outsideQty[product.product_sku] || 0)
                                      : product.quantity -
                                        product.dispatched_quantity -
                                        (smallQty[product.product_sku] || 0) -
                                        (outsideQty[product.product_sku] || 0) -
                                        (product.additional_info
                                          ? product.additional_info.length
                                          : 0)}
                                  </td>

                                  <td
                                    align="left"
                                    style={{
                                      // width: "180px",
                                      fontWeight: "bold",
                                      display: "flex",
                                    }}
                                  >
                                    <Switch
                                      disabled={
                                        product.quantity -
                                          product.dispatched_quantity -
                                          (product.additional_info
                                            ? product.additional_info.length
                                            : 0) ===
                                        0
                                      }
                                      checked={
                                        checkedStates[product.product_sku]
                                      }
                                      onChange={handleChange(
                                        product.product_sku
                                      )}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                    <TextField
                                      autoComplete="off"
                                      inputProps={{ min: 0 }}
                                      fullWidth
                                      type="number"
                                      size="small"
                                      label="Quantity"
                                      variant="outlined"
                                      style={{ width: "100px" }}
                                      disabled={
                                        !checkedStates[product.product_sku]
                                      }
                                      value={outsideQty[product.product_sku]}
                                      onChange={handleOutSideQtyChange(
                                        product.product_sku
                                      )}
                                    />
                                  </td>

                                  <td align="right">
                                    {product.dispatched_quantity}
                                  </td>
                                </tr>
                              ))}
                            </>
                          ) : (
                            <>
                              <tr>
                                <td
                                  colSpan="9"
                                  style={{
                                    textAlign: "center",
                                    color: "red",
                                  }}
                                >
                                  No Products Selected
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openRemoveP}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product Already Scanned!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to remove this product?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveProduct}
            >
              Remove Product
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseRemove}
              color="error"
            >
              Skip
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        open={openConfirmWare}
        onClose={handleCloseWare}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to change warehouse?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All your data will be reset. You won't be able to revert it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWare}>Cancel</Button>
          <Button onClick={handleConfirmWareHouseChange} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DispatchDetails;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import AddRackModal from "./AddNewRack";
import DeleteRackModal from "../../../Messages/DeleteWarehouse/DeleteRack";
import EditRackModal from "./EditRackModal";
import PrintBarcode from "../PrintRackBarcode";
import { Breadcrumbs } from "@mui/material";
import { apiKey, delete_rack, fetch_racks } from "../../../Api";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useScreenWidth } from "../../../useScreenWidth";
import { MdEditSquare } from "react-icons/md";
import { IoClose } from "react-icons/io5";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "600px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

function RacksUpdateModal({
  setOpenModal2,
  openModal2,
  racks,
  row_name,
  row_id,
  setRacks,
  ware_id,
  warehouse,
  fetchData,
}) {
  const isWideScreen = useScreenWidth();
  const handleClose = () => {
    setOpenModal2(false);
  };

  const [openModalBarcode, setOpenModalBarcode] = useState(false);

  const [openAddRackModal, setOpenAddRackModal] = useState(false);

  const handleAddRack = () => {
    setOpenAddRackModal(true);
  };

  const [rack_id, setRackId] = useState("");
  const [rack_name, setRackname] = useState("");

  const [openDeleteRack, setOpenDeleteRack] = useState(false);
  const [racksbar, setRacksData] = useState([]);

  const [allRackData, setAllRackData] = useState([]);

  const handlePrintRack = (ware_id, row, rack_id, rackname) => {
    // Ensure racksbar is declared properly
    const racksbarData = [
      {
        barcode_value: `W${ware_id}-R${row}-R${rack_id}`,
        row_name: row_name,
        warehouse_name: warehouse,
        rack_name: rackname,
      },
    ];

    setOpenModalBarcode(true);
    setRacksData(racksbarData);
  };

  const [openEditRack, setOpenEditRack] = useState(false);

  const handleEditRack = (rackid, rackname) => {
    setRackId(rackid);
    setRackname(rackname);
    setOpenEditRack(true);
  };

  // ================= Row Selection Checkboxes

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectRow = (row) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.some((selectedRow) => selectedRow.id === row.id)) {
        return prevSelected.filter((selectedRow) => selectedRow.id !== row.id);
      } else {
        return [...prevSelected, row];
      }
    });
  };

  const handleSelectAllRows = () => {
    setSelectedRows(selectedRows.length === racks.length ? [] : racks);
  };

  const handleDeleteAll = () => {
    const selectedData = selectedRows.map((racks) => racks.id);
    axios
      .delete(delete_rack, {
        headers: {
          "API-Key": apiKey,
        },
        data: {
          rack_pks: selectedData,
        },
      })
      .then(() => {
        toast.success(`Selected Racks Deleted Successfully!`);
        setSelectedRows([]);
        fetchData();
        fetchRows();
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error?.response?.data?.message);
      });
  };

  const handlePrintAll = () => {
    const racksbarData = selectedRows.map((seletedRow) => ({
      barcode_value: `W${ware_id}-R${row_id}-R${seletedRow.id}`,
      row_name: row_name,
      warehouse_name: warehouse,
      rack_name: seletedRow.rack_name,
    }));

    setOpenModalBarcode(true);
    setRacksData(racksbarData);
  };

  // fetch Racks

  const fetchRows = () => {
    axios
      .get(
        fetch_racks,

        {
          params: {
            row_pk: parseInt(row_id),
          },
          headers: {
            "API-Key": apiKey,
          },
        }
      )
      .then((response) => {
        // console.log(response.data.racks);
        setAllRackData(response.data.racks);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Failed to fetch racks.");
      });
  };

  useEffect(() => {
    if (openModal2) {
      fetchRows();
    }
  }, [openModal2, row_id]);

  return (
    <>
      <Toaster position="top-right" />
      <PrintBarcode
        openModalBarcode={openModalBarcode}
        setOpenModalBarcode={setOpenModalBarcode}
        racksbar={racksbar}
      />

      <AddRackModal
        openAddRackModal={openAddRackModal}
        setOpenAddRackModal={setOpenAddRackModal}
        row_id={row_id}
        setRacks={setRacks}
        fetchData={fetchData}
        fetchRows={fetchRows}
      />

      <EditRackModal
        openEditRack={openEditRack}
        setOpenEditRack={setOpenEditRack}
        rack_name={rack_name}
        rack_id={rack_id}
        row_id={row_id}
        setRacks={setRacks}
        fetchData={fetchData}
        fetchRows={fetchRows}
      />

      <DeleteRackModal
        openDeleteRack={openDeleteRack}
        setOpenDeleteRack={setOpenDeleteRack}
        rack_id={rack_id}
        row_id={row_id}
        setRacks={setRacks}
      />

      <Modal
        open={openModal2}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Warehouse</Typography>
            <Typography color="inherit">Racks</Typography>
            <Typography sx={{ color: "text.primary" }}>{row_name}</Typography>
          </Breadcrumbs>

          <IoClose className="closeModalBtn" onClick={handleClose} />

          <div
            style={{
              overflowY: "scroll",
              maxHeight: "30vh",
              overflowX: "hidden",
              marginTop: "5px",
            }}
          >
            {isWideScreen ? (
              <>
                <div className="responsive-table1">
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th style={{ width: "2px" }}>
                          <input
                            type="checkbox"
                            checked={
                              selectedRows.length > 0
                                ? selectedRows.length === racks.length
                                : false
                            }
                            onChange={handleSelectAllRows}
                          />
                        </th>
                        <th>#</th>
                        <th>Rack Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allRackData &&
                        allRackData.map((data) => (
                          <tr key={data.id}>
                            <td style={{ width: "2px" }}>
                              <input
                                type="checkbox"
                                checked={selectedRows.some(
                                  (selectedRow) => selectedRow.id === data.id
                                )}
                                onChange={() => handleSelectRow(data)}
                              />
                            </td>
                            <td style={{ width: "70px" }}>{data.id}</td>
                            <td>{data.rack_name}</td>
                            <td style={{ width: "150px" }}>
                              <Button
                                onClick={() =>
                                  handleEditRack(data.id, data.rack_name)
                                }
                                variant="outlined"
                                style={{ marginLeft: "5px" }}
                                color="info"
                                size="small"
                              >
                                Edit
                              </Button>

                              <Button
                                onClick={() =>
                                  handlePrintRack(
                                    ware_id,
                                    row_id,
                                    data.id,
                                    data.rack_name
                                  )
                                }
                                variant="outlined"
                                style={{ marginLeft: "5px" }}
                                color="error"
                                size="small"
                              >
                                Print
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div
                  className="card responsive-mobile-table"
                  style={{ maxHeight: "250px" }}
                >
                  {allRackData.length > 0 ? (
                    <>
                      {allRackData &&
                        allRackData.map((data) => (
                          <>
                            <div className="product-card">
                              <div className="py-1 px-2">
                                <h5 className="product-card-sku">#{data.id}</h5>
                                <h5 className="product-card-name">
                                  {" "}
                                  Rack Name :{" "}
                                  {data.rack_name ? data.rack_name : "N/A"}
                                </h5>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "13px",
                                    marginTop: "-26px",
                                  }}
                                >
                                  <MdEditSquare
                                    className="iicon iicon-edit"
                                    onClick={() =>
                                      handleEditRack(data.id, data.rack_name)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="d-flex justify-content-end mt-2">
            <Button
              variant="outlined"
              color="primary"
              style={{ marginRight: "5px", marginTop: "8px" }}
              size="small"
              onClick={() => handleAddRack()}
            >
              Add New Rack
            </Button>
            {/* Conditionally render Print All and Delete All buttons */}
            {selectedRows.length > 0 && (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handlePrintAll}
                  style={{ marginRight: "5px", marginTop: "8px" }}
                >
                  Print All
                </Button>

                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={handleDeleteAll}
                  style={{ marginTop: "8px" }}
                >
                  Delete All
                </Button>
              </>
            )}

            {/* <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button> */}
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default RacksUpdateModal;

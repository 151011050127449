import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { FormControlLabel, Button } from "@mui/material";

import toast, { Toaster } from "react-hot-toast";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import { BiLinkExternal } from "react-icons/bi";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import {
  DateRange,
  DateRangePicker,
  defaultStaticRanges,
  createStaticRanges,
} from "react-date-range";
import { addDays, subDays, startOfWeek, endOfWeek, format } from "date-fns";
import {
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
  startOfQuarter,
  endOfQuarter,
} from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { apiKey, stock_ledger, export_ledger } from "../../../Api";
import axios from "axios";
import { Pagination, Icon, Label, Checkbox } from "semantic-ui-react";

import SubmitLoader from "../../../Components/SubmitLoader";
import { formatDate } from "../../../Components/DateFormat.js";
import ViewPurchaseModal from "../../Purchase/ViewPurchaseModal";
import ViewSaleDetailModal from "../../Sales/ViewSaleDetailModal";
import StockAdjusmentViewModal from "../StockAdjustment/StockAdjusmentViewModal";
import StockTransferModal from "../StockTransfer/StockTransferModal";
import { FaCalendarAlt, FaSearch } from "react-icons/fa";
import ConversionDetailModal from "../../Conversion/ConversionDetailModal";

function StockLedger() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const currentDate = new Date();
    setEndDate(currentDate);

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setStartDate(oneMonthAgo);
  }, []);

  // Define custom ranges
  const customStaticRanges = createStaticRanges([
    ...defaultStaticRanges,
    {
      label: "6 Months",
      range: () => ({
        startDate: subMonths(new Date(), 6),
        endDate: new Date(),
      }),
    },
    // {
    //   label: 'Previous 6 Months',
    //   range: () => ({
    //     startDate: subMonths(subMonths(new Date(), 6), 6),
    //     endDate: subMonths(new Date(), 6),
    //   }),
    // },
    {
      label: "This Year",
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
      }),
    },
    {
      label: "Previous Year",
      range: () => ({
        startDate: startOfYear(subYears(new Date(), 1)),
        endDate: endOfYear(subYears(new Date(), 1)),
      }),
    },
  ]);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [predefinedRange, setPredefinedRange] = useState("");

  const [searchquery, setSearchQuery] = useState("");
  const [dummySearch, setDummySearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);

  const [products, setProducts] = useState([]);
  const [productNull, setProductNull] = useState([]);
  const [showProduct, setshowProduct] = useState(false);

  // Modals
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);

  const [saleModalOpen, setSaleModalOpen] = useState(false);
  const [selectedSaleId, setSelectedSaleId] = useState(null);

  const [adjustmentModalOpen, setAdjustmentModalOpen] = useState(false);
  const [selectedAdjustmentId, setSelectedAdjustmentId] = useState(null);

  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [selectedTransferId, setSelectedTransferId] = useState(null);

  const [conversionModalOpen, setConversionModalOpen] = useState(false);
  const [selectedConversionId, setSelectedConversionId] = useState(null);

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const exportReport = () => {
    setExportLoading(true);
    const adjustedStartDate = subDays(startDate, 1);
    const adjustedEndDate = addDays(endDate, 1);

    const formattedData = {
      starting_date: format(adjustedStartDate, "yyyy-MM-dd"),
      ending_date: format(adjustedEndDate, "yyyy-MM-dd"),
      search: searchquery || "",
    };

    axios
      .post(export_ledger, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
        responseType: "blob", // Important to handle binary data
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `report_${formattedData.starting_date}_to_${formattedData.ending_date}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        setExportLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setExportLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const fetchReport = () => {
    if (!startDate && !endDate) {
      toast.error("Please select a time period.");
      return;
    }
    setShowDatePicker(false);
    setLoading(true);
    setshowProduct(true);

    const adjustedStartDate = subDays(startDate, 1);
    const adjustedEndDate = addDays(endDate, 1);

    // Format the dates to "YYYY-MM-DD"
    const formattedData = {
      starting_date: format(adjustedStartDate, "yyyy-MM-dd"),
      ending_date: format(adjustedEndDate, "yyyy-MM-dd"),
      search: searchquery || "",
      page: currentPage || "",
      per_page: pageSize,
    };

    // console.log(formattedData);

    axios
      .post(stock_ledger, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setProducts(response.data.products);
        setTotalPages(response.data.num_pages);
        setLoading(false);

        // if (response.data.products.length === 0) {
        //   setProductNull(true);
        // } else {
        //   setProducts(response.data.products);
        //   setTotalPages(response.data.num_pages);
        //   setProductNull(null);
        // }
        // setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = () => {
    const query = dummySearch;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
  };

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      fetchReport();
    }
  }, [currentPage, pageSize, searchquery]);

  // Expand table
  const [expandedRows, setExpandedRows] = useState({});

  const handleExpandToggle = (sku) => {
    setExpandedRows((prev) => {
      const isExpanded = prev[sku]?.expanded;

      if (isExpanded) {
        return {
          ...prev,
          [sku]: {
            expanded: false,
            combinedTransactions: [],
            activeTransaction: null,
          },
        };
      }

      const combinedData = combineTransactions(sku, checkboxState[sku]);
      return {
        ...prev,
        [sku]: {
          expanded: true,
          combinedTransactions: combinedData,
          activeTransaction: null,
        },
      };
    });
  };

  const [checkboxState, setCheckboxState] = useState({});

  useEffect(() => {
    const initialCheckboxState = {};
    products.forEach((product) => {
      initialCheckboxState[product.product_sku] = {
        purchase: true,
        sale: true,
        stockAdj: true,
        transfer: true,
        conversion: true,
      };
    });
    setCheckboxState(initialCheckboxState);
  }, [products]);

  const handleCheckboxChange = (sku, transactionType) => {
    setCheckboxState((prevState) => {
      const productCheckboxState = prevState[sku] || {
        purchase: true,
        sale: true,
        stockAdj: true,
        transfer: true,
        conversion: true,
      };

      const newState = {
        ...prevState,
        [sku]: {
          ...productCheckboxState,
          [transactionType]: !productCheckboxState[transactionType],
        },
      };

      // Update combined transactions for the specific SKU
      updateCombinedTransactions(sku, newState[sku]);
      return newState;
    });
  };

  const updateCombinedTransactions = (sku, checkboxState) => {
    setExpandedRows((prevRows) => {
      const newExpandedRows = { ...prevRows };

      if (newExpandedRows[sku]?.expanded) {
        const combinedData = combineTransactions(sku, checkboxState);
        newExpandedRows[sku] = {
          ...newExpandedRows[sku],
          combinedTransactions: combinedData,
        };
      }

      return newExpandedRows;
    });
  };

  const combineTransactions = (sku, checkboxState) => {
    const productData = products.find((product) => product.product_sku === sku);
    const combinedData = [];
    const transactionIds = new Set();

    if (productData) {
      const addTransactions = (
        transactions,
        type,
        stockInKey,
        stockOutKey,
        adjustmentType
      ) => {
        transactions?.forEach((transaction) => {
          // Filter adjustments by type if adjustmentType is provided
          if (
            type === "adjustment" &&
            transaction.adjustment_type !== adjustmentType
          ) {
            return;
          }

          const transactionId = `${type}_${transaction[`${type}_pk`]}`;
          if (!transactionIds.has(transactionId)) {
            transactionIds.add(transactionId);

            // Create link based on transaction type
            const link =
              type === "purchase"
                ? `/#/view-purchase/${transaction.purchase_pk}`
                : type === "sale"
                ? `/#/view-sale/${transaction.sale_pk}`
                : type === "transfer"
                ? `/transfer-history-detail/${transaction.transfer_pk}`
                : type === "adjustment"
                ? `/#/stock-adjustment-detail/${transaction.adjustment_pk}`
                : type === "conversion"
                ? `/#/conversion-detail/${transaction.conversion_pk}`
                : null;

            combinedData.push({
              link,
              id: transaction[`${type}_pk`],
              date: transaction.date,
              warehouse:
                transaction.warehouse?.warehouse_name ||
                transaction.recieved_warehouse?.warehouse_name ||
                "-",
              transactionType: type.charAt(0).toUpperCase() + type.slice(1),
              stockIn: transaction[stockInKey] || 0,
              stockOut: transaction[stockOutKey] || 0,
            });
          }
        });
      };

      // Log current checkbox state for debugging
      // console.log('Checkbox State:', checkboxState);

      // Combine transactions based on current checkbox state
      if (checkboxState.purchase) {
        addTransactions(
          productData.purchase_transactions,
          "purchase",
          "quantity",
          "stock_out"
        );
      }
      if (checkboxState.sale) {
        addTransactions(
          productData.sale_transactions,
          "sale",
          "stock_in",
          "quantity"
        );
      }
      if (checkboxState.stockAdj) {
        // Handle stock adjustments separately for stock in and stock out
        addTransactions(
          productData.adjustment_transactions,
          "adjustment",
          "quantity",
          "0",
          "stock in"
        );
        addTransactions(
          productData.adjustment_transactions,
          "adjustment",
          "0",
          "quantity",
          "stock out"
        );
      }
      if (checkboxState.transfer) {
        productData.transfer_transactions?.forEach((transaction) => {
          const transactionId = `transfer_${transaction.transfer_pk}_${transaction.warehouse.id}`;
          if (!transactionIds.has(transactionId)) {
            transactionIds.add(transactionId);

            const link = `/transfer-history-detail/${transaction.transfer_pk}`;
            combinedData.push({
              link,
              id: transaction.transfer_pk,
              date: transaction.date,
              warehouse: transaction.warehouse?.warehouse_name || "-",
              transactionType: "Transfer",
              stockIn: transaction.stock_in || 0,
              stockOut: transaction.stock_out || 0,
            });
          }
        });
      }
      if (checkboxState.conversion) {
        addTransactions(
          productData.conversion_transactions,
          "conversion",
          "stock_in",
          "stock_out"
        );
      }
    }

    // Log combined data for debugging
    // console.log('Combined Data:', combinedData);

    return combinedData;
  };

  return (
    <>
      {loading && <SubmitLoader />}
      {exportLoading && <SubmitLoader />}

      <Toaster position="top-right" />

      <ViewPurchaseModal
        open={purchaseModalOpen}
        onClose={() => setPurchaseModalOpen(false)}
        purchaseId={selectedPurchaseId}
      />
      <ViewSaleDetailModal
        open={saleModalOpen}
        onClose={() => setSaleModalOpen(false)}
        saleId={selectedSaleId}
      />
      <StockAdjusmentViewModal
        open={adjustmentModalOpen}
        onClose={() => setAdjustmentModalOpen(false)}
        adjustmentId={selectedAdjustmentId}
      />
      <StockTransferModal
        open={transferModalOpen}
        onClose={() => setTransferModalOpen(false)}
        transferId={selectedTransferId}
      />
      <ConversionDetailModal
        open={conversionModalOpen}
        onClose={() => setConversionModalOpen(false)}
        conversionId={selectedConversionId}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Stock Ledger
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-4 col-md-6 d-flex align-items-center gap-2 ">
                <Button
                  variant="outlined"
                  // size="small"
                  onClick={toggleDatePicker}
                >
                  <div>
                    <FaCalendarAlt
                      style={{ marginTop: "-0.2rem" }}
                      className="me-1"
                    />{" "}
                    {`${format(startDate, "yyyy-MM-dd")} to ${format(
                      endDate,
                      "yyyy-MM-dd"
                    )} `}
                  </div>
                </Button>
              </div>
              {showDatePicker && (
                <div
                  className="row position-absolute "
                  style={{
                    zIndex: 10,
                    marginTop: "3.5rem",
                    width: "max-content",
                  }}
                >
                  <div
                    className="bg-white"
                    style={{
                      zIndex: 10,
                      width: "max-content",
                    }}
                  >
                    <DateRangePicker
                      onChange={(item) => {
                        setStartDate(item.selection.startDate);
                        setEndDate(item.selection.endDate);
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={[{ startDate, endDate, key: "selection" }]}
                      direction="horizontal"
                      preventSnapRefocus={true}
                      staticRanges={customStaticRanges}
                    />
                    <div className="d-flex bg-white justify-content-end align-items-center mb-2">
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={toggleDatePicker}
                        className="ms-2"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={fetchReport}
                        className="ms-2"
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-6 d-flex justify-content-end align-items-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={fetchReport}
                  className="ms-2"
                >
                  Run Report
                </Button>
                {products && products.length > 0 ? (
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={exportReport}
                    className="ms-2"
                  >
                    Export
                  </Button>
                ) : null}
              </div>
            </div>
          </div>

          {/* {products && products.length > 0 && ( */}
          {showProduct && (
            <>
              <div className="card my-2 p-2">
                <div className="row">
                  <div className="col-lg-4 d-flex ">
                    <div class="ui action input">
                      <input
                        value={dummySearch}
                        onChange={(e) => {
                          setDummySearch(e.target.value);
                        }}
                        type="text"
                        placeholder="Search..."
                      />
                      <button onClick={handleSearchChange} class="ui button">
                        <FiSearch style={{ marginRight: "5px" }} /> Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="responsive-table1" style={{ maxHeight: "68vh" }}>
                <table className="ui table celled" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>SKU</th>
                      <th>Product Name</th>
                      <th>Product Type</th>
                      <th>Product Brand</th>
                      <th>Part Number</th>

                      <th>Stock</th>
                      <th>Stock In</th>
                      <th>Stock Out</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products && products.length > 0 ? (
                      products.map((data) => (
                        <React.Fragment key={data.product_sku}>
                          <tr>
                            <td>
                              <span
                                style={{
                                  margin: "5px",
                                  fontSize: "15px",
                                  marginTop: "-5px",
                                  cursor: "pointer",
                                }}
                              >
                                {expandedRows[data.product_sku]?.expanded ? (
                                  <IoMdArrowDropup
                                    onClick={() =>
                                      handleExpandToggle(data.product_sku)
                                    }
                                  />
                                ) : (
                                  <IoMdArrowDropdown
                                    onClick={() =>
                                      handleExpandToggle(data.product_sku)
                                    }
                                  />
                                )}
                              </span>
                              {data.product_sku}
                            </td>
                            <td>{data.product_name}</td>
                            <td>{data.product_type}</td>
                            <td>{data.product_brand}</td>
                            <td>{data.part_number}</td>

                            <td>
                              <b>{data.in_stock} QTY</b>
                            </td>
                            <td
                              style={{
                                color: data.in_stock > 0 ? "green" : "grey",
                              }}
                            >
                              <b>
                                {data.in_quantity < 1 ? (
                                  <FaLongArrowAltUp
                                    style={{
                                      color: "transparent",
                                    }}
                                  />
                                ) : (
                                  <FaLongArrowAltUp />
                                )}
                                {data.in_quantity || "0"}
                              </b>
                            </td>
                            <td
                              style={{
                                color: data.out_quantity > 0 ? "red" : "grey",
                              }}
                            >
                              <b>
                                {data.out_quantity < 1 ? (
                                  <FaLongArrowAltUp
                                    style={{
                                      color: "transparent",
                                    }}
                                  />
                                ) : (
                                  <FaLongArrowAltUp />
                                )}
                                {data.out_quantity || "0"}
                              </b>
                            </td>
                          </tr>

                          {expandedRows[data.product_sku]?.expanded && (
                            <tr>
                              <td colSpan={10}>
                                <div className="row bg-grey">
                                  <div className="col-md-12 col-12">
                                    <div className="row">
                                      <div
                                        className="col-md-12 mb-2 d-flex"
                                        style={{
                                          padding: "6px",
                                          margin: "5px",
                                          gap: "50px",
                                        }}
                                      >
                                        <div>
                                          <Checkbox
                                            checked={
                                              checkboxState[data.product_sku]
                                                ?.purchase || false
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(
                                                data.product_sku,
                                                "purchase"
                                              )
                                            }
                                            label="Purchase"
                                          />
                                        </div>
                                        <div>
                                          <Checkbox
                                            label="Sale"
                                            checked={
                                              checkboxState[data.product_sku]
                                                ?.sale || false
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(
                                                data.product_sku,
                                                "sale"
                                              )
                                            }
                                          />
                                        </div>
                                        <div>
                                          <Checkbox
                                            checked={
                                              checkboxState[data.product_sku]
                                                ?.stockAdj || false
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(
                                                data.product_sku,
                                                "stockAdj"
                                              )
                                            }
                                            label="Adjustment"
                                          />
                                        </div>
                                        <div>
                                          <Checkbox
                                            checked={
                                              checkboxState[data.product_sku]
                                                ?.transfer || false
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(
                                                data.product_sku,
                                                "transfer"
                                              )
                                            }
                                            label="Transfer"
                                          />
                                        </div>
                                        <div>
                                          <Checkbox
                                            checked={
                                              checkboxState[data.product_sku]
                                                ?.conversion || false
                                            }
                                            onChange={() =>
                                              handleCheckboxChange(
                                                data.product_sku,
                                                "conversion"
                                              )
                                            }
                                            label="Conversion"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="simple-table">
                                      <div>
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>ID</th>
                                              <th>Date</th>
                                              <th>Warehouse</th>
                                              <th>Transaction Type</th>
                                              <th>Stock In</th>
                                              <th>Stock Out</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {expandedRows[data.product_sku]
                                              ?.combinedTransactions.length >
                                            0 ? (
                                              expandedRows[
                                                data.product_sku
                                              ].combinedTransactions.map(
                                                (transaction, index) => (
                                                  <tr
                                                    key={index}
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      if (
                                                        transaction.transactionType ===
                                                        "Purchase"
                                                      ) {
                                                        setSelectedPurchaseId(
                                                          transaction.id
                                                        );
                                                        setPurchaseModalOpen(
                                                          true
                                                        );
                                                      } else if (
                                                        transaction.transactionType ===
                                                        "Sale"
                                                      ) {
                                                        setSelectedSaleId(
                                                          transaction.id
                                                        );
                                                        setSaleModalOpen(true);
                                                      } else if (
                                                        transaction.transactionType ===
                                                        "Adjustment"
                                                      ) {
                                                        setSelectedAdjustmentId(
                                                          transaction.id
                                                        );
                                                        setAdjustmentModalOpen(
                                                          true
                                                        );
                                                      } else if (
                                                        transaction.transactionType ===
                                                        "Transfer"
                                                      ) {
                                                        setSelectedTransferId(
                                                          transaction.id
                                                        );
                                                        setTransferModalOpen(
                                                          true
                                                        );
                                                      } else if (
                                                        transaction.transactionType ===
                                                        "Conversion"
                                                      ) {
                                                        setSelectedConversionId(
                                                          transaction.id
                                                        );
                                                        setConversionModalOpen(
                                                          true
                                                        );
                                                      } else {
                                                        navigate(
                                                          transaction.link
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    {/* {console.log("expandedRows[data.product_sku]?.combinedTransactions : ", expandedRows[data.product_sku]?.combinedTransactions)} */}
                                                    {/* {console.log("transaction.transactionType : ",transaction.transactionType)} */}
                                                    <td
                                                      style={{
                                                        cursor: "pointer",
                                                        width: "30px",
                                                      }}
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        if (
                                                          transaction.transactionType ===
                                                          "Purchase"
                                                        ) {
                                                          setSelectedPurchaseId(
                                                            transaction.id
                                                          );
                                                          setPurchaseModalOpen(
                                                            true
                                                          );
                                                        } else if (
                                                          transaction.transactionType ===
                                                          "Sale"
                                                        ) {
                                                          setSelectedSaleId(
                                                            transaction.id
                                                          );
                                                          setSaleModalOpen(
                                                            true
                                                          );
                                                        } else if (
                                                          transaction.transactionType ===
                                                          "Adjustment"
                                                        ) {
                                                          setSelectedAdjustmentId(
                                                            transaction.id
                                                          );
                                                          setAdjustmentModalOpen(
                                                            true
                                                          );
                                                        } else if (
                                                          transaction.transactionType ===
                                                          "Transfer"
                                                        ) {
                                                          setSelectedTransferId(
                                                            transaction.id
                                                          );
                                                          setTransferModalOpen(
                                                            true
                                                          );
                                                        } else if (
                                                          transaction.transactionType ===
                                                          "Conversion"
                                                        ) {
                                                          setSelectedConversionId(
                                                            transaction.id
                                                          );
                                                          setConversionModalOpen(
                                                            true
                                                          );
                                                        } else {
                                                          navigate(
                                                            transaction.link
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <a
                                                        href={
                                                          transaction.transactionType ===
                                                          "Purchase"
                                                            ? "#"
                                                            : transaction.link
                                                        }
                                                      >
                                                        <BiLinkExternal />{" "}
                                                        {transaction.id}
                                                      </a>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {formatDate(
                                                        transaction.date
                                                      ) || "-"}
                                                    </td>
                                                    <td>
                                                      {transaction.warehouse}
                                                    </td>
                                                    <td>
                                                      <Label
                                                        size="tiny"
                                                        style={{
                                                          borderRadius: "6px",
                                                          minWidth: "70px",
                                                        }}
                                                        color={
                                                          transaction.transactionType ==
                                                          "Purchase"
                                                            ? "red"
                                                            : transaction.transactionType ==
                                                              "Sale"
                                                            ? "green"
                                                            : transaction.transactionType ==
                                                              "Adjustment"
                                                            ? "blue"
                                                            : transaction.transactionType ==
                                                              "Transfer"
                                                            ? "orange"
                                                            : "yellow"
                                                        }
                                                      >
                                                        {
                                                          transaction.transactionType
                                                        }
                                                      </Label>
                                                    </td>
                                                    <td
                                                      style={{ width: "120px" }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            transaction.stockIn <
                                                            1
                                                              ? "grey"
                                                              : "green",
                                                        }}
                                                      >
                                                        {transaction.stockIn <
                                                        1 ? (
                                                          <FaLongArrowAltUp
                                                            style={{
                                                              color:
                                                                "transparent",
                                                            }}
                                                          />
                                                        ) : (
                                                          <FaLongArrowAltUp />
                                                        )}{" "}
                                                        {transaction.stockIn}
                                                      </span>{" "}
                                                    </td>
                                                    <td
                                                      style={{ width: "120px" }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            transaction.stockOut <
                                                            1
                                                              ? "grey"
                                                              : "red",
                                                        }}
                                                      >
                                                        {transaction.stockOut <
                                                        1 ? (
                                                          <FaLongArrowAltDown
                                                            style={{
                                                              color:
                                                                "transparent",
                                                            }}
                                                          />
                                                        ) : (
                                                          <FaLongArrowAltDown />
                                                        )}{" "}
                                                        {transaction.stockOut}
                                                      </span>{" "}
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={6}>
                                                  No transactions available for
                                                  the selected filters.
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10}>No products found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div style={{ marginTop: "5px", display: "flex" }}>
                <select
                  className="form-control mb_btn_hide"
                  style={{
                    width: "50px",
                    marginRight: "5px",
                    backgroundColor: "white",
                  }}
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <Pagination
                  size="tiny"
                  defaultActivePage={1}
                  onPageChange={handlePageChange}
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: true,
                  }}
                  firstItem={{
                    content: <Icon name="angle double left" />,
                    icon: true,
                  }}
                  lastItem={{
                    content: <Icon name="angle double right" />,
                    icon: true,
                  }}
                  prevItem={{ content: <Icon name="angle left" />, icon: true }}
                  nextItem={{
                    content: <Icon name="angle right" />,
                    icon: true,
                  }}
                  totalPages={totalPages}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StockLedger;

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import { apiKey, DispatchHistoryItems } from "../../Api";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CustomToolbar from "../../Components/CustomToolbar";
import { DataGrid } from "@mui/x-data-grid";

import { debounce } from "lodash";
import { Pagination, Icon } from "semantic-ui-react";

function SingleDispatchDetail() {
  const { id } = useParams();

  const [singleDisptachDetial, setSingleDisptachDetial] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);

  // Other Main Information
  const [dispatcher, setDispatcher] = useState("");
  const [dispatchedDate, setDispatchedDate] = useState("");
  const [fetchedWarehouse, setFetchedWarehouse] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, filterModel, id]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      history_pk: parseInt(id),
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(DispatchHistoryItems, data, { headers })
      .then((response) => {
        console.log(response.data.data);
        setDispatcher(response.data.data.dispatcher_name);
        setDispatchedDate(response.data.data.dispatched_date);
        setFetchedWarehouse(response.data.data.warehouse);

        setSingleDisptachDetial(response.data.data.items);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data?.message);
        console.log(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };
  // const debouncedHandleSearchChange = useCallback(debounce(handleSearchChange, 1100), []);

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  return (
    <>
      <Toaster position="top-right" />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate(-1)} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Dispatch</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Dispatch History
              </Typography>
              <Typography>#{id}</Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              ></div>
            </div>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-md-3">
                <p className="mb-0">
                  <strong>Date :</strong>{" "}
                  {dispatchedDate.toLocaleString() || "N/A"}
                </p>
              </div>
              <div className="col-md-3">
                <p className="mb-0">
                  <strong>Dispatched By :</strong> {dispatcher || "N/A"}
                </p>
              </div>
              <div className="col-md-3">
                <p className="mb-0">
                  <strong>Warehouse :</strong> {fetchedWarehouse || "N/A"}
                </p>
              </div>
            </div>
          </div>

          <div className="details-section"></div>

          <div>
            <div className="responsive-table1" style={{ height: "70vh" }}>
              <table className="ui table celled" id="c_table">
                <thead className="table-head">
                  <tr>
                    <th>ID</th>
                    <th>Product SKU</th>
                    <th>Product Name</th>
                    <th>Product Type</th>
                    <th>Product Brand</th>
                    <th>Part Number</th>
                    <th>OEM Number</th>
                    <th>Quantity</th>
                    <th>Outside Dispatched QTY</th>
                  </tr>
                </thead>
                <tbody>
                  {singleDisptachDetial &&
                    singleDisptachDetial.map((dispatch, index) => (
                      <tr key={dispatch.id || index}>
                        <td>{dispatch.id}</td>
                        <td>{dispatch.product_sku}</td>
                        <td>{dispatch.product_name}</td>
                        <td>{dispatch.product_type}</td>
                        <td>{dispatch.product_brand}</td>
                        <td>{dispatch.part_number}</td>
                        <td>{dispatch.oem_number}</td>
                        <td>{dispatch.dispatched_quantity}</td>
                        <td>{dispatch.dispatched_from_outside}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div style={{ marginTop: "2px", display: "flex" }}>
              <select
                className="form-control"
                style={{
                  width: "50px",
                  marginRight: "5px",
                  backgroundColor: "white",
                }}
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <Pagination
                defaultActivePage={1}
                onPageChange={handlePageChange}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SingleDispatchDetail;

import React, { useState } from 'react';
import { Typography, Button, Modal } from "@mui/material";
import { Label } from 'semantic-ui-react';
import ViewCustomer from '../../OurClients/Clients/ViewCustomer';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "850px",
    maxWidth: "950px",
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

function CustomersProductDashboard({ data, onClose }) {

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [openDetailModal, setOpenDetailModal] = useState(false);

    const handleOpenDetailModal = (customer) => {
        console.log(customer);
        setSelectedCustomer(customer);
        setOpenDetailModal(true);
    };

    const handleCloseDetailModal = () => {
        setSelectedCustomer(null);
        setOpenDetailModal(false);
    };


    return (
        <>

            <ViewCustomer
                openViewCustomer={openDetailModal}
                handleCloseCustomer={handleCloseDetailModal}
                selectedCustomer={selectedCustomer}
                fetchCustomers={``}
            />
            <Modal open onClose={onClose}>
                <div style={modalStyle} className="bg-white py-3 px-3">
                    <div className="d-flex justify-content-between align-items-center mb-2" >
                        <Typography variant="h5" component="h2">
                            Customers Details
                        </Typography>
                        <div className="mb_btn_hide">
                            Total Customers : <Label>{data.length}</Label>
                        </div>

                    </div>
                    <div
                        className="responsive-table1"
                        style={{
                            maxHeight: "300px",
                            height: "max-content",
                            width: "100%",
                        }}
                    >
                        <table className="ui table celled" id="c_table">
                            <thead className="table-head">
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    data.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{cursor:"pointer"}} onClick={() => handleOpenDetailModal(item)} >{item.id}</td>
                                            <td>{item.customer_name}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.total_purchased_quantity}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: "20px", float: "right" }}
                    >
                        Close
                    </Button>
                </div>
            </Modal>

        </>
    );
}

export default CustomersProductDashboard;

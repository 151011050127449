import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import jsBarcode from "jsbarcode";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 410,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function PrintBarcode({ handleCloseBarcode, openBarcode, barcodes }) {
  const generateBarcodeImage = (sku) => {
    const canvas = document.createElement("canvas");

    jsBarcode(canvas, sku, {
      format: "CODE128", // CODE128 is used for GS1-128 encoding
      width: 3,
      height: 50,
      fontSize: 0,
      margin: 1,
      text: "", // Set text to empty to hide the human-readable text
    });

    return canvas.toDataURL();
  };

  const handlePrint = () => {
    const printWindow = window.open("", "", "height=600,width=1200");

    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <style>
            @media print {
              .print-barcode {
                margin: 15px 10px;
                page-break-inside: avoid;
                page-break-before: always;
              }
              .barcodeName {
                margin: 5px 0;
              }
              @page {
                size: auto;
                margin: 20mm;
              }
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${barcodes
            .map(
              (data) =>
                `
                <div class="print-barcode" style="font-family:sans-serif">

                  <h5 class="barcodeName">PART NO.: <b>${
                    data.part_number.includes(",")
                      ? data.part_number.split(",")[0]
                      : data.part_number.length > 40
                      ? data.part_number.substring(0, 20)
                      : data.part_number
                  }</b></h5>
                      <h5 class="barcodeName">PRODUCT NAME: <b>${
                        data.name.length > 45
                          ? data.name.substring(0, 45) + "..."
                          : data.name
                      }</b></h5>
                  <h5 style="margin-top:12px" class="barcodeName my-3">BRAND: <b>${
                    data.brand
                  }</b></h5>
                   <h5  class="barcodeName my-3">QTY: <b>1 PC
                  </b></h5>
                 
                  <div style="position: absolute; right: 12px;  bottom: "25px;">
                    <img style="margin-top:-24px;width:190px" src="${generateBarcodeImage(
                      data.sku.split("-")[1] + "_" + data.series
                    )}" />
                     <p style="font-size:10px;margin-top:-1px;text-align:center">${
                       data.sku + "_" + data.series
                     }</p>
                  </div>
                </div>
              `
            )
            .join("")}
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const handleClose = () => {
    handleCloseBarcode(false);
  };

  return (
    <div>
      <Modal
        open={openBarcode}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseBarcode();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Print Barcode</Typography>
            <Typography sx={{ color: "text.primary" }}>
              Printing Preview
            </Typography>
          </Breadcrumbs>
          <TableContainer
            component={Paper}
            style={{
              maxHeight: "50vh",
              overflowY: "scroll",
              marginTop: "2px",
              padding: "20px",
            }}
          >
            {barcodes.map((data, index) => (
              <div key={`${index}`} className="mainBarcode print-barcode">
                <h5 className="barcodeName">
                  PART NO.:{" "}
                  <b>
                    {data.part_number.includes(",")
                      ? data.part_number.split(",")[0]
                      : data.part_number.length > 40
                      ? data.part_number.substring(0, 30)
                      : data.part_number}
                  </b>
                </h5>
                <h5 className="barcodeName" style={{ marginTop: "-10px" }}>
                  PRODUCT NAME:{" "}
                  <b>
                    {data.name.length > 45
                      ? data.name.substring(0, 45) + "..."
                      : data.name}
                  </b>
                </h5>
                <h5 className="barcodeName" style={{ marginTop: "0px" }}>
                  BRAND: <b>{data.brand}</b>
                </h5>
                <h5 className="barcodeName" style={{ marginTop: "-5px" }}>
                  QTY: <b>1PC</b>
                </h5>
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "2px",
                  }}
                >
                  <img
                    style={{ width: "180px", height: "auto" }}
                    src={generateBarcodeImage(
                      data.sku.split("-")[1] + "_" + data.series
                    )}
                    alt="Barcode"
                  />
                  <p style={{ fontSize: "10px", textAlign: "center" }}>
                    {data.sku + "_" + data.series}
                  </p>
                </div>
              </div>
            ))}
          </TableContainer>
          <div style={{ float: "right" }}>
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "5px" }}
              onClick={handlePrint}
              variant="outlined"
            >
              Confirm Print
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default PrintBarcode;

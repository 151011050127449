import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { apiKey, conversion_history, conversion_barcodes } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Pagination, Icon } from "semantic-ui-react";
import { useScreenWidth } from "../../useScreenWidth";
import PrintBarcode from "../Purchase/PrintBarcode";
import CustomLoader from "../../Components/Loader";
import FilterDateRangeModal from "../../Components/FilterModal/FilterDateRangeModal";
import FilterSelectionModalByWarehouse from "../../Components/FilterModal/FilterSelectionModalByWarehouse";
import FilterTableColumns from "../../Components/FilterModal/FilterTableColumns";

function Convert() {
  const isWideScreen = useScreenWidth();
  const [converionHistory, setConverionHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");

  // date Filter
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Warehouse Filter
  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState(null);

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const navigate = useNavigate();

  const debouncedFetchData = useCallback(
    debounce((page, query) => {
      fetchData(page, query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    debouncedFetchData(1, query);
  };
  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    pageSize,
    searchquery,
    startDate,
    endDate,
    selectedWarehouseIds,
  ]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      starting_date: startDate || "",
      ending_date: endDate || "",
      selectedWarehouses: selectedWarehouseIds || null,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    axios
      .post(conversion_history, data, { headers })
      .then((response) => {
        setConverionHistory(response.data.convert_history);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleViewDetails = (id) => {
    navigate(`/conversion-detail/${id}`);
  };

  // ======= handle Print Barcode Of Adjustment

  const [openModalBarcode, setOpenModalBarcode] = useState(false);
  const [barcodes, setBarcodes] = useState([]);

  const handlePrintBarcode = (conversionId) => {
    try {
      axios
        .get(conversion_barcodes, {
          params: {
            convert_history_pk: conversionId,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            setBarcodes(response.data.products);

            const barcodeProducts = response.data.products;
            // Now send And Prepare the data for printing

            const barcodeData = barcodeProducts.map((product) => ({
              sku: product.sku,
              series: product.series,
              part_number: product.part_number,
              name: product.name,
              brand: product.brand,
            }));

            // Wrap in an array to maintain consistency
            setBarcodes(barcodeData);
            setOpenModalBarcode(true);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          // setError(error);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <PrintBarcode
        handleCloseBarcode={setOpenModalBarcode}
        openBarcode={openModalBarcode}
        barcodes={barcodes}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit" style={{ cursor: "pointer" }}>
                Inventory
              </Typography>
              <Typography sx={{ color: "text.primary" }}>
                Conversions
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-8 col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-4 col-lg-8"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                {isWideScreen ? <FilterTableColumns tableId="Convert" /> : null}
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-conversions")}
                >
                  Add New
                </Button>
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1 ">
                <table className="ui table celled Convert" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>
                        <FilterDateRangeModal
                          title="Converted Date"
                          startDate={startDate}
                          endDate={endDate}
                          onDateChange={handleDateChange}
                        />
                      </th>
                      <th>
                        <FilterSelectionModalByWarehouse
                          title="Warehouse"
                          selectedWarehouseId={selectedWarehouseIds}
                          onSelect={setSelectedWarehouseIds}
                        />
                      </th>
                      <th>Conversion By</th>
                      <th>Items</th>
                      <th>Total Products</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {converionHistory.length > 0 ? (
                      <>
                        {converionHistory &&
                          converionHistory.map((data, index) => (
                            <tr key={index}>
                              <td style={{ width: "3vw" }}>{index + 1}</td>
                              <td>
                                {new Date(
                                  data.convert_date
                                ).toLocaleDateString()}
                              </td>
                              <td>{data.warehouse}</td>
                              <td>{data.user_name}</td>
                              <td>{data.item_count}</td>
                              <td>{data.total_products}</td>
                              <td style={{ width: "200px" }}>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  onClick={() => handleViewDetails(data.id)}
                                >
                                  View Details
                                </Button>

                                <Button
                                  style={{ marginLeft: "5px" }}
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  onClick={() => handlePrintBarcode(data.id)}
                                >
                                  Print
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan="6">
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {converionHistory.length > 0 ? (
                  <>
                    {converionHistory &&
                      converionHistory.map((data) => (
                        <>
                          <div
                            className="product-card"
                            onClick={() => handleViewDetails(data.id)}
                          >
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5 className="product-card-name">
                                {data.warehouse}
                              </h5>
                              <h5 className="warehouse-card-address">
                                {data.convert_date}
                              </h5>

                              <h5
                                style={{
                                  position: "absolute",
                                  marginTop: "-15px",
                                  right: "15px",
                                  fontSize: "11px",

                                  color:
                                    data.in_stock <= 2
                                      ? "red"
                                      : "rgb(36, 36, 36)",
                                  cursor:
                                    data.product_type__product_type ==
                                    "Child Parts"
                                      ? "default"
                                      : "pointer",
                                }}
                                className="product-card-mrp"
                              >
                                {data.item_count} Products |{" "}
                                {data.total_products} QTY
                              </h5>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "5px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Convert;

import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { transfer_child_product_immediately, apiKey } from "../../../Api";
import toast, { Toaster } from "react-hot-toast";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function SmallItemTransfer({
  smallItemOpen,
  setSmallItemOpen,
  warehouse,
  setsku,
  // addScannedProduct,
  handleCheckboxChange,
  productDatatoModal,
  smallItemArray,
}) {
  const [rows, setRows] = useState([
    { selectedWarehouse: "", quantity: 0, targetQuantity: 0 },
  ]);

  useEffect(() => {
    if (smallItemArray.length === 1) {
      setRows([
        {
          selectedWarehouse: smallItemArray[0].ware_house_id,
          quantity: smallItemArray[0].quantity,
          targetQuantity: 0,
        },
      ]);
    }
  }, [smallItemArray]);

  const handleClose = () => {
    setSmallItemOpen(false);
  };

  const handleWarehouseChange = (index, event) => {
    const warehouseId = event.target.value;
    const newRows = [...rows];
    newRows[index].selectedWarehouse = warehouseId;

    const selectedWarehouseData = smallItemArray.find(
      (item) => item.ware_house_id === warehouseId
    );

    if (selectedWarehouseData) {
      newRows[index].quantity = selectedWarehouseData.quantity;
    }
    setRows(newRows);
  };

  const handleTargetQuantityChange = (index, event) => {
    const newRows = [...rows];
    newRows[index].targetQuantity = event.target.value;
    setRows(newRows);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      { selectedWarehouse: "", quantity: 0, targetQuantity: 0 },
    ]);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const selectedWarehouses = rows.map((row) => row.selectedWarehouse);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const handleTransferProduct = () => {
    const details = rows.map((row) => ({
      warehouse_pk: parseInt(row.selectedWarehouse),
      target_quantity: parseInt(row.targetQuantity),
    }));

    const totalTargetQuantity = details.reduce(
      (sum, detail) => sum + detail.target_quantity,
      0
    );

    const data = {
      warehouse_pk: parseInt(warehouse),
      product_sku: setsku,
      user_name: userDetails.name,
      total_quantity: totalTargetQuantity,
      details: details,
    };

    axios
      .post(transfer_child_product_immediately, data, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success("Product Transferred Successfully");
          console.log(" Previous productDatatoModal : ", productDatatoModal);

          const updatedProductDatatoModal = {
            ...productDatatoModal,
            in_stock: totalTargetQuantity,
          };

          console.log(
            "updatedProductDatatoModal : ",
            updatedProductDatatoModal
          );

          handleCheckboxChange(updatedProductDatatoModal);
          // addScannedProduct(setsku, productDatatoModal);
          setSmallItemOpen(false);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Dialog
        open={smallItemOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "#ed4928", fontSize: "1.5rem", fontWeight: "bold" }}
          >
            Transfer Small Product?
          </DialogTitle>
          <DialogContent>
            {rows.map((row, index) => (
              <div className="row my-2" key={index}>
                <div className="col-md-5">
                  <FormControl fullWidth size="small">
                    <InputLabel>Source Warehouse</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      value={row.selectedWarehouse}
                      label="Source Warehouse"
                      onChange={(e) => handleWarehouseChange(index, e)}
                      disabled={smallItemArray.length === 1}
                    >
                      <MenuItem value="">--Select--</MenuItem>
                      {smallItemArray.map((data) => (
                        <MenuItem
                          key={data.ware_house_id}
                          value={data.ware_house_id}
                          disabled={selectedWarehouses.includes(
                            data.ware_house_id
                          )}
                        >
                          {data.ware_house_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-3">
                  <TextField
                    label="Avail Qty"
                    size="small"
                    variant="outlined"
                    value={row.quantity}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <TextField
                    label="Quantity"
                    size="small"
                    variant="outlined"
                    value={row.targetQuantity}
                    onChange={(e) => handleTargetQuantityChange(index, e)}
                  />
                </div>
                <div className="col-md-1">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDeleteRow(index)}
                  >
                    <DeleteIcon fontSize="small" style={{ color: "red" }} />
                  </IconButton>
                </div>
              </div>
            ))}
            <Button
              variant="outlined"
              size="small"
              onClick={handleAddRow}
              hidden={rows.length >= smallItemArray.length}
            >
              Add Row
            </Button>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleClose}
            >
              Skip
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleTransferProduct}
            >
              Proceed
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default SmallItemTransfer;

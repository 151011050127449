import { Breadcrumbs, Typography } from '@mui/material'
import React from 'react'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom';

function Settings() {
    const navigate = useNavigate();
    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div style={{ display: "flex" }}>
                        <IoMdArrowRoundBack
                            id="backbtn"
                            onClick={() => navigate("/")}
                        />
                        <Breadcrumbs aria-label="breadcrumb">
                            <Typography color="inherit">Dashboard</Typography>
                            <Typography sx={{ color: "text.primary" }}>
                                Settings
                            </Typography>
                        </Breadcrumbs>
                    </div>

                    <div className="row my-4">
                        <div className="col-md-3 mb-2">
                            <Link to={`/settings/discount-adjustment`} className="card rounded text-center">
                                <div className="card-body ">
                                    <h5 className="card-title">Discount Adjustments</h5>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Settings;

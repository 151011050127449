import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1000px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ViewAllocationHistoryModal({
  allocateDetails,
  opendetail,
  setOpenDetail,
}) {
  // Destructure the necessary details from allocateDetails
  const { date, user_name, ware_house__ware_house, rack, row, history_detail } =
    allocateDetails || {};

  const handleClose = () => {
    setOpenDetail(false);
  };

  return (
    <Modal
      open={opendetail}
      onClose={(reason) => {
        if (reason !== "backdropClick") {
          setOpenDetail();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Inventory</Typography>
          <Typography color="inherit">Allocation History</Typography>
          <Typography sx={{ color: "text.primary" }}>Details</Typography>
        </Breadcrumbs>

        <div
          className="row my-2"
          style={{ backgroundColor: "#e8f5ff", padding: "5px" }}
        >
          <div className="col-lg-4 my-1">
            <Typography>
              Date:{" "}
              <b style={{ color: "#3b3b3b" }}>
                {date ? new Date(date).toLocaleString() : "-"}
              </b>
            </Typography>
          </div>
          <div className="col-lg-4 my-1">
            <Typography>
              User: <b style={{ color: "#3b3b3b" }}>{user_name || "-"}</b>
            </Typography>
          </div>
          <div className="col-lg-4 my-1"></div>
          <div className="col-lg-4 my-1">
            <Typography>
              Warehouse:{" "}
              <b style={{ color: "#3b3b3b" }}>
                {ware_house__ware_house || "-"}
              </b>
            </Typography>
          </div>

          <div className="col-lg-4 my-1">
            <Typography>
              Row:{" "}
              <b style={{ color: "#3b3b3b" }}>
                {row?.rack_number__row__row_name || "-"}
              </b>
            </Typography>
          </div>
          <div className="col-lg-4 my-1">
            <Typography>
              Rack:{" "}
              <b style={{ color: "#3b3b3b" }}>
                {rack?.rack_number__rack_name || "-"}
              </b>
            </Typography>
          </div>
        </div>
        <TableContainer
          component={Paper}
          style={{
            maxHeight: "50vh",
            overflowY: "scroll",
            marginTop: "2px",
          }}
        >
          <Table size="small" aria-label="allocation history table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history_detail && history_detail.length > 0 ? (
                history_detail.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.product_ref__product_sku}</TableCell>
                    <TableCell>{item.product_ref__product_name}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} style={{ textAlign: "center" }}>
                    No history details available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ float: "right" }}>
          <Button
            style={{ marginTop: "8px" }}
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default ViewAllocationHistoryModal;

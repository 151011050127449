import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiKey,
  fetch_sale_detail,
  fetch_sale_product,
  fetch_customer,
  create_sale,
  getLastPrice,
  fetch_customer_address,
  update_sale,
} from "../../Api";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import AddProductModal from "../Products/AddProductModal";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import CreateCustomer from "../OurClients/Clients/CreateCustomer";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";

import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaTrashAlt } from "react-icons/fa";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { debounce } from "lodash";
import { Popup } from "semantic-ui-react";
import { IoAlertCircle } from "react-icons/io5";
import { Autocomplete } from "@mui/material";

function EditSaleDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchListRef = useRef(null);
  const [quantities, setQuantities] = useState({});
  const [mrps, setMrps] = useState({});
  const [last_price, setLast_price] = useState({});
  const [customers, setCustomers] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const [customer, setCustomer] = useState("");
  const [customeraddress, setCustomeraddress] = useState([]);
  const [address, setAddress] = useState("");
  const [discount_amount, setDiscount] = useState("");
  const [sale_date, setS_date] = useState();
  const [other_charge, setOtherCharge] = useState();
  const [shipping_charge, setShippingCharge] = useState("");
  const [notes, setNotes] = useState("");

  const [percentage, setPercentage] = useState();

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [discounts, setDiscounts] = useState({});
  const [numericDiscounts, setNumericDiscounts] = useState({});

  useEffect(() => {
    fetchSales();
  }, [id]);

  const fetchSales = () => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(id),
        },
      })
      .then((response) => {
        const data = response.data.data;
        // console.log("response.data.data :", data);
        setNotes(data.note);
        setCustomer(data.billing_customer.id);
        setS_date(data.sale_date);
        setDiscount(data.discount_amount);
        setShippingCharge(data.courier_charge);

        const items = data.items.map((item) => {
          const discountPercentage = ((item.mrp - item.price) / item.mrp) * 100;
          const discountValue = item.mrp - item.price;
          const total = item.price * item.quantity;

          return {
            product_sku: item.product_sku,
            product_name: item.product_name,
            mrp: item.mrp,
            price: item.price,
            quantity: item.quantity,
            discount_amount: discountValue,
            available_stock: item.available_stock,
            last_price: item.last_price || 0,
            discount_percentage: discountPercentage.toFixed(0),
            total: total,
          };
        });

        // Set the selected products and initialize discounts, prices, and quantities
        setSelectedProducts(items);

        // Initialize discounts, prices, and quantities
        const initialDiscounts = {};
        const initialNumericDiscounts = {};
        const initialMrps = {};
        const initialQuantities = {};

        items.forEach((item) => {
          initialDiscounts[item.product_sku] = item.discount_percentage;
          initialNumericDiscounts[item.product_sku] =
            item.discount_amount.toFixed(0);
          initialMrps[item.product_sku] = item.price;
          initialQuantities[item.product_sku] = item.quantity;
        });

        setDiscounts(initialDiscounts);
        setNumericDiscounts(initialNumericDiscounts);
        setMrps(initialMrps);
        setQuantities(initialQuantities);

        // Calculate total MRP based on the current state of items
        // setDiscount(data.discount_amount);
        // setShippingCharge(data.courier_charge);
        // setOtherCharge(data.other_charge);
        // console.log(other_charge,'is other befor fucntion',data.other_charge);
        // setDiscount
        const shipping = shipping_charge
          ? shipping_charge
          : data.courier_charge;

        const discount = discount_amount
          ? discount_amount
          : data.discount_amount;

        const totalMRP = calculateTotalChangeMRP(items) + shipping - discount;
        // console.log(other_charge,'is other after fucntion');

        // Calculate and set the percentage based on the fetched other charge and total MRP
        // const totalMRP = calculateTotalChangeMRP(items);

        const percentageValue = (data.other_charge / totalMRP) * 100;

        setPercentage(parseFloat(percentageValue.toFixed(0)));
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to fetch sale details.");
      });
  };

  const fetchCustomers = (query = searchquery) => {
    axios
      .get(fetch_customer, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          search: query,
        },
      })
      .then((response) => {
        // console.log(response);
        setCustomers(response.data.customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCustomers();
  }, [searchquery]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const debounceFetchProducts = useCallback(
    debounce(async (value) => {
      try {
        const response = await axios.post(
          fetch_sale_product,
          { search: value, customer_pk: parseInt(customer) || 0 },
          { headers: { "Content-Type": "application/json", "API-Key": apiKey } }
        );
        console.log(response.data.data);
        const fetchedProducts = response.data.data || [];
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    }, 500), // 500ms delay
    [customer] // Dependency array
  );

  // Handle input change, using debounceFetchProducts
  const handleInputChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchInput(value);

      if (value.trim().length >= 2) {
        debounceFetchProducts(value);
      } else {
        setProducts([]);
        setDisplay(false);
      }
    },
    [debounceFetchProducts]
  );

  const handleFocus = () => {
    debounceFetchProducts();
    setDisplay(true);
  };

  const handleCustomerChange = (e, newValue) => {
    const customer_pk = newValue ? newValue.id : "";
    setCustomer(customer_pk);

    // Create an array of SKUs from selected products
    const skus = selectedProducts.map((product) => product.product_sku);

    try {
      axios
        .get(fetch_customer_address, {
          headers: {
            "API-Key": apiKey,
          },
          params: {
            customer_pk: customer_pk,
          },
        })
        .then((response) => {
          // console.log(response.data.address);
          setCustomeraddress(response.data.address);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}

    // Fetch updated prices for the new customer
    try {
      axios
        .post(
          getLastPrice,
          { customer_pk, skus },
          {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          }
        )
        .then((response) => {
          // Convert the array to an object for easy lookup
          const lastMrpsData = response.data.prices;
          const lastMrpsDataObject = lastMrpsData.reduce((acc, item) => {
            acc[item.sku] = {
              last_price: item.last_price,
              last_discountPercentage: item.recomended_discount, // Add recommended discount here
            };
            return acc;
          }, {});

          // Update selectedProducts with the new last_price and last_discountPercentage values
          const updatedProducts = selectedProducts.map((product) => ({
            ...product,
            last_price:
              lastMrpsDataObject[product.product_sku]?.last_price ||
              product.last_price,
            last_discountPercentage:
              lastMrpsDataObject[product.product_sku]
                ?.last_discountPercentage || product.last_discountPercentage,
          }));

          // Update the selectedProducts state with new prices
          setSelectedProducts(updatedProducts);
          // console.log(updatedProducts);
        })
        .catch((error) => {
          console.error("Error fetching updated prices:", error);
        });
    } catch (error) {
      console.error("Error fetching updated prices:", error);
    }
  };

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      const isSelected = prevSelected.some(
        (p) => p.product_sku === product.product_sku
      );

      // Define the desired product structure
      const formattedProduct = {
        product_sku: product.product_sku,
        product_name: product.product_name,
        mrp: product.mrp,
        price: product.price,
        quantity: product.quantity || 1,
        discount_amount: product.discount_amount || 0,
        available_stock: product.available_stock || 0,
        last_price: product.last_price || 0,
        discount_percentage: product.discount_percentage || "0",
        total: product.price * (product.quantity || 1),
      };

      if (isSelected) {
        // Remove product from selectedProducts
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        // Add product to selectedProducts
        setLast_price((prevLastMrps) => ({
          ...prevLastMrps,
          [product.product_sku]: product.last_price,
        }));

        // Automatically set discount if available and original MRP is greater than 0
        const originalMRP = product.mrp;

        if (product.recomended_discount && originalMRP > 0) {
          const percentageValue = product.recomended_discount; // Get the recommended discount
          const discountValue = (originalMRP * percentageValue) / 100; // Calculate discount value
          const newMRP = Math.max(originalMRP - discountValue, 0); // Calculate new MRP after discount

          // Update state with the calculated values
          setDiscounts((prevDiscounts) => ({
            ...prevDiscounts,
            [product.product_sku]: `${percentageValue}`, // Set the percentage as a string
          }));

          setNumericDiscounts((prevDiscounts) => ({
            ...prevDiscounts,
            [product.product_sku]: discountValue.toFixed(2), // Set the discount value
          }));

          setMrps((prevMrps) => ({
            ...prevMrps,
            [product.product_sku]: newMRP, // Set the new MRP
          }));
        }

        return [...prevSelected, formattedProduct]; // Add the formatted product
      }
    });
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    const quantity = parseInt(value, 10);

    if (quantity > 0 || value === "") {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [product.product_sku]: quantity || 1,
      }));
    }
  };

  const [opencustomer, setCustomerOpen] = useState(false);
  const handleOpencustomer = () => setCustomerOpen(true);
  const handleClosecustomer = () => setCustomerOpen(false);

  const handleDeleteProduct = (product_sku) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
  };

  // Add Product
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);

  // Function to open the modal
  const handleAddProductModal = () => {
    setAddProductModalOpen(true);
  };

  // Function to close the modal
  const handleCloseProductAddModal = () => {
    setAddProductModalOpen(false);
  };

  useEffect(() => {
    const getCustomerDetails = (customerId) => {
      const foundCustomer = customers.find((cust) => cust.id === customerId);
      if (foundCustomer) {
        return {
          name: foundCustomer.customer_name,
          primaryShipping: foundCustomer.primary_shipping || {}, // Default to an empty object if not available
        };
      }
      return {
        name: "",
        primaryShipping: {},
      };
    };

    const customerDetails = getCustomerDetails(customer);
    const location = customerDetails.primaryShipping?.id || "";

    setAddress(location); // Update address when customerDetails.primaryShipping.id changes
  }, [customer, customers, setAddress]);

  const handleDiscountChange = (e, product, type) => {
    const { value } = e.target;
    const sku = product.product_sku;
    const originalMRP = product.mrp;
    let newMRP;

    const parseValue = (val) => parseFloat(val) || 0;

    if (type === "percentage") {
      const percentageValue = parseValue(value);
      if (percentageValue < 0 || percentageValue > 100) {
        return;
      }
      const discountValue = (originalMRP * percentageValue) / 100;
      newMRP = originalMRP - discountValue;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: `${percentageValue}`,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: discountValue.toFixed(0),
      }));
    } else if (type === "numeric") {
      const numericValue = parseValue(value);
      if (numericValue < 0) {
        return;
      }
      newMRP = originalMRP - numericValue;

      const percentageValue = (numericValue / originalMRP) * 100;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: `${Math.ceil(percentageValue)}`,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: numericValue.toFixed(0),
      }));
    }

    newMRP = Math.max(newMRP, 0);

    setMrps((prevMrps) => ({
      ...prevMrps,
      [sku]: newMRP,
    }));
  };

  const handleMRPChange = (event, product) => {
    const { value } = event.target;
    const updatedMRP = parseFloat(value) || 0;

    // Retrieve the original MRP and current discount for the product
    const originalMRP = product.mrp;
    const discount = discounts[product.product_sku] || 0;

    // If updated MRP is negative, set it to 0
    const newMRP = Math.max(updatedMRP, 0);

    // Update the MRP in state
    setMrps((prevMrps) => ({
      ...prevMrps,
      [product.product_sku]: newMRP,
    }));

    // Handle flat discount
    const flatDiscount = parseFloat(discount) || 0;
    const newNumericDiscount = flatDiscount;
    const discountedPrice = Math.max(newMRP - newNumericDiscount, 0);

    // Calculate the discount based on the original MRP
    const calculatedPercentageDiscount =
      originalMRP > 0 ? ((originalMRP - newMRP) / originalMRP) * 100 : 0;
    const calculatedNumericDiscount = originalMRP - newMRP;

    let discountratenumeric = Math.max(calculatedNumericDiscount, 0);
    let discountrate = Math.max(calculatedPercentageDiscount, 0).toFixed(2);

    discountrate = isNaN(discountrate) ? "0" : discountrate;
    discountratenumeric = isNaN(discountratenumeric) ? 0 : discountratenumeric;

    // Update the state with the recalculated discounts
    setNumericDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: discountratenumeric,
    }));

    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: discountrate,
    }));

    return discountedPrice;
  };

  const calculateProductTotal = (product) => {
    const quantity = quantities[product.product_sku] || 1;
    const mrp = mrps[product.product_sku] || product.mrp;

    if (mrp <= 0) {
      return 0;
    }

    // Retrieve the discount value and check if it contains '%'

    return Math.ceil(mrp * quantity);
  };

  const calculateTotalMRP = () => {
    const totalProductMRP = selectedProducts.reduce((total, product) => {
      const productMRP = mrps[product.product_sku] || product.mrp;
      const quantity =
        product.additional_info && product.additional_info.length
          ? product.additional_info.length
          : quantities[product.product_sku] || 1;

      return total + productMRP * quantity;
    }, 0);

    const otherCharge = parseFloat(other_charge) || 0;
    const shippingCharge = parseFloat(shipping_charge) || 0;
    const discountAmount = parseFloat(discount_amount) || 0;

    const totalCharges = otherCharge + shippingCharge;
    const totalMRP = totalProductMRP + totalCharges - discountAmount;

    return totalMRP;
  };

  useEffect(() => {
    const totalMRP = calculateTotalChangeMRP();
    const newOtherCharge = (percentage / 100) * totalMRP;
    setOtherCharge(newOtherCharge.toFixed(0));
  }, [
    shipping_charge,
    discount_amount,
    percentage,
    selectedProducts,
    mrps,
    quantities,
  ]);

  const calculateTotalChangeMRP = (items = selectedProducts) => {
    const totalProductMRP = items.reduce((total, product) => {
      const productMRP =
        mrps[product.product_sku] || product.price
          ? product.price || 0
          : product.mrp || 0;
      return (
        total +
        productMRP * (quantities[product.product_sku] || product.quantity)
      );
    }, 0);

    const shippingCharge = parseFloat(shipping_charge) || 0;
    const discountAmount = parseFloat(discount_amount) || 0;

    const totalMRP = totalProductMRP + shippingCharge - discountAmount;

    // return totalMRP;
    return Math.max(totalMRP, 0);
  };

  const handleOtherChargeChange = (e) => {
    const newOtherCharge = parseFloat(e.target.value) || 0;
    setOtherCharge(newOtherCharge);
    // setOtherCharge(parseFloat(newOtherCharge.toFixed(0)));
    const totalMRP = calculateTotalChangeMRP();
    // setPercentage(parseFloat((newOtherCharge / totalMRP) * 100));
    setPercentage((newOtherCharge / totalMRP) * 100);
  };

  const handlePercentageChange = (e) => {
    let newPercentage = parseFloat(e?.target?.value) || 0;
    if (newPercentage > 99) {
      newPercentage = 99;
    }

    setPercentage(parseFloat(newPercentage));
    const totalMRP = calculateTotalChangeMRP();
    const totalCal = (newPercentage / 100) * totalMRP;
    // setOtherCharge(totalCal.toFixed(0));
    setOtherCharge(totalCal);
  };

  const handleSubmit = async () => {
    if (!sale_date || !customer) {
      toast.error("Customer & Sale Date cannot be empty!");
      return;
    }
    const saleData = {
      customer_pk: parseInt(customer) || 0,
      sale_date: sale_date,
      sale_pk: parseInt(id),
      other_charge: parseInt(other_charge) || 0,
      courier_charge: parseInt(shipping_charge) || 0,
      // shipping_pk: parseInt(address),
      note: notes || "",
      sale_type: "party",
      discount_amount: discount_amount || 0,
      user_name: userDetails.name || "",
      products: selectedProducts.map((product) => ({
        product_sku: product.product_sku,
        quantity: parseInt(quantities[product.product_sku]) || 1,
        mrp: mrps[product.product_sku] || product.mrp,
        item_discount: parseFloat(numericDiscounts[product.product_sku]) || 0.0,
      })),
    };

    axios
      .post(update_sale, saleData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setSelectedProducts([]);
          setProducts([]);
          setDiscount("0.00");
          setSearchInput("");
          setS_date("");
          setCustomer("");
          navigate("/all_sales");
          toast.success("Party Sale Updated successfully!");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };

  return (
    <>
      {/* {console.log("selectedProducts : ", selectedProducts)} */}
      <Toaster position="top-right" />

      <AddProductModal
        open={addProductModalOpen}
        handleClose={handleCloseProductAddModal}
      />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <CreateCustomer
        opencustomer={opencustomer}
        handleOpencustomer={handleOpencustomer}
        handleClosecustomer={handleClosecustomer}
        fetchCustomers={fetchCustomers}
        setCustomer={setCustomer}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          {/* breadcrumb   */}
          <div className="d-flex">
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_sales")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Sales</Typography>
              <Typography color="inherit">Edit Party Sale</Typography>
              <Typography sx={{ color: "text.primary" }}>#{id}</Typography>
            </Breadcrumbs>
          </div>
          <div style={{ display: "flex", float: "right", marginTop: "-4vh" }}>
            <Button variant="contained" onClick={handleSubmit}>
              Update Sale
            </Button>
          </div>

          {/* main container  */}

          <div className="row my-2">
            <div className="col-lg-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col-lg-5 my-2">
                    <div style={{ display: "flex" }}>
                      <FormControl fullWidth size="small" required>
                        <Autocomplete
                          value={
                            customers.find((cust) => cust.id === customer) ||
                            null
                          }
                          onChange={handleCustomerChange}
                          inputValue={searchquery}
                          onInputChange={(event, newInputValue) => {
                            setSearchQuery(newInputValue);
                          }}
                          options={customers}
                          getOptionLabel={(option) =>
                            option.customer_name || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Customer"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </FormControl>

                      <Button
                        variant="outlined"
                        style={{ marginLeft: "2px", height: "4.4vh" }}
                        onClick={handleOpencustomer}
                        size="small"
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                  <div className="col-lg-3 my-2">
                    <TextField
                      type="date"
                      value={sale_date}
                      size="small"
                      required
                      fullWidth
                      onChange={(e) => setS_date(e.target.value)}
                    />
                  </div>

                  <div
                    className="col-lg-4 my-2"
                    style={{
                      display: "flex",
                      marginTop: "1px",
                      borderLeft: "1px solid grey",
                      paddingLeft: "8px",
                    }}
                  >
                    <TextField
                      placeholder="Type your message here (if any)"
                      value={notes}
                      multiline
                      rows={3}
                      onChange={(e) => setNotes(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>
              </div>

              <div
                className="card my-2 "
                style={{ padding: "20px 15px 2px 15px" }}
              >
                <div className="row">
                  <div className="col-8">
                    <TextField
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      value={searchInput}
                      fullWidth
                      size="small"
                      id="search-products"
                      label="Search Products"
                      variant="outlined"
                    />
                    {displaySearch ? (
                      <>
                        <div
                          id="searchList"
                          ref={searchListRef}
                          style={{
                            border: "1px solid #dedede",
                            position: "absolute",
                            zIndex: "2",
                            marginTop: "3px",
                            maxHeight: "50vh",
                            overflowY: "scroll",

                            backgroundColor: "white",
                            width: "92.4%",
                          }}
                        >
                          {products.map((product, index) => (
                            <>
                              <div
                                className="card p-3"
                                style={{ display: "flex" }}
                                key={index}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isProductSelected(product)}
                                      onChange={() =>
                                        handleCheckboxChange(product)
                                      }
                                      name="check"
                                    />
                                  }
                                  label={
                                    product.product_sku +
                                    " : " +
                                    product.product_name
                                  }
                                />
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="col-2 ">
                    <Button
                      variant="outlined"
                      style={{
                        marginLeft: "2px",
                        width: "100%",
                        height: "40px",
                      }}
                      size="small"
                      onClick={handleAddProductModal}
                    >
                      Add Product
                    </Button>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3">
                {/* <h5>Sale Summary</h5> */}
                <div className="row" style={{ marginTop: "2px" }}>
                  <div className="col-6 my-2">Total Discount (-)</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <TextField
                      placeholder="0.00"
                      value={discount_amount}
                      // disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      onChange={(e) => setDiscount(e.target.value)}
                      size="small"
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">kg</InputAdornment>
                          ),
                        },
                      }}
                    />
                  </div>
                  <div className="col-6 my-2">Shipping Charges</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <TextField
                      placeholder="0.00"
                      value={shipping_charge}
                      // disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      onChange={(e) => setShippingCharge(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="col-4 my-2">Other Charges</div>
                  <div
                    className="col-8 my-2"
                    style={{ textAlign: "right", display: "flex" }}
                  >
                    <OutlinedInput
                      size="small"
                      fullWidth
                      style={{ maxWidth: "4vw" }}
                      name="discountPercent"
                      value={percentage}
                      onChange={handlePercentageChange}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />

                    <OutlinedInput
                      size="small"
                      placeholder="0.00"
                      style={{ marginLeft: "2px" }}
                      fullWidth
                      name="discountAmount"
                      value={other_charge}
                      onChange={handleOtherChargeChange}
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                    />
                  </div>

                  <div className="col-6 my-2">Grand Total</div>
                  <div
                    className="col-6 my-2"
                    style={{ textAlign: "right", color: "red" }}
                  >
                    <b>₹{calculateTotalMRP().toFixed(2)}</b>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card p-4 my-2">
                <div
                  className="products_table_class"
                  style={{ maxHeight: "60vh" }}
                >
                  <div className="responsive-table1">
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>SKU</th>
                          <th align="left">Product</th>
                          <th align="left">MRP</th>
                          <th align="left">Avail Qty</th>
                          <th align="left">Discount</th>
                          <th align="left">Price</th>
                          <th align="left">Quantity</th>
                          <th align="right">Total</th>
                          <th align="right"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProducts && selectedProducts.length > 0 ? (
                          <>
                            {selectedProducts.map((product, index) => (
                              <tr key={index}>
                                <td style={{ width: "6.5vw" }}>
                                  {product.product_sku}
                                </td>
                                <td align="left">{product.product_name}</td>
                                <td align="left">
                                  {formatCurrency(product.mrp)}
                                </td>
                                <td align="left">
                                  {product.available_stock || 0}
                                </td>
                                <td align="left" style={{ width: "10vw" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {product.last_discountPercentage > 0 && (
                                      <Popup
                                        style={{
                                          backgroundColor: "white",
                                          border: "1px solid grey",
                                          borderRadius: "5px",
                                          color: "black",
                                          padding: "5px",
                                          // marginLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                        on="click"
                                        pinned
                                        content={`${product.last_discountPercentage}%`}
                                        trigger={
                                          <IoAlertCircle
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "5px",
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                          />
                                        }
                                      />
                                    )}

                                    {product.mrp > 0 &&
                                    mrps[product.product_sku] < product.mrp ? (
                                      <>
                                        <OutlinedInput
                                          size="small"
                                          style={{ width: "5vw" }}
                                          name="discountPercentage"
                                          value={
                                            discounts[product.product_sku] || 0
                                          }
                                          onChange={(e) =>
                                            handleDiscountChange(
                                              e,
                                              product,
                                              "percentage"
                                            )
                                          }
                                          endAdornment={
                                            <InputAdornment position="end">
                                              %
                                            </InputAdornment>
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <OutlinedInput
                                          size="small"
                                          style={{ width: "5vw" }}
                                          name="discountPercentage"
                                          value="0"
                                          endAdornment={
                                            <InputAdornment position="end">
                                              %
                                            </InputAdornment>
                                          }
                                        />
                                      </>
                                    )}

                                    {/* ////Numeric Input  */}
                                    {product.mrp > 0 &&
                                    mrps[product.product_sku] < product.mrp ? (
                                      <>
                                        <OutlinedInput
                                          size="small"
                                          style={{
                                            marginLeft: "3px",
                                            width: "6.5vw",
                                          }}
                                          name="discountValue"
                                          value={
                                            numericDiscounts[
                                              product.product_sku
                                            ] || 0
                                          }
                                          onChange={(e) =>
                                            handleDiscountChange(
                                              e,
                                              product,
                                              "numeric"
                                            )
                                          }
                                          startAdornment={
                                            <InputAdornment position="start">
                                              ₹
                                            </InputAdornment>
                                          }
                                          endAdornment={
                                            <InputAdornment position="end">
                                              .00
                                            </InputAdornment>
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <OutlinedInput
                                          size="small"
                                          style={{
                                            marginLeft: "3px",
                                            width: "6.5vw",
                                          }}
                                          name="discountValue"
                                          value="0"
                                          startAdornment={
                                            <InputAdornment position="start">
                                              ₹
                                            </InputAdornment>
                                          }
                                          endAdornment={
                                            <InputAdornment position="end">
                                              .00
                                            </InputAdornment>
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td align="left" style={{ width: "9vw" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        marginLeft: "3px",
                                        width: "6.5vw",
                                      }}
                                      type="text"
                                      value={
                                        numericDiscounts[product.product_sku] ==
                                        0
                                          ? mrps[product.product_sku] !==
                                            undefined
                                            ? mrps[product.product_sku]
                                            : product.mrp
                                          : mrps[product.product_sku] ||
                                            product.mrp
                                      }
                                      onChange={(e) =>
                                        handleMRPChange(e, product)
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          .00
                                        </InputAdornment>
                                      }
                                      onFocus={(e) => e.target.select()}
                                    />

                                    {product.last_price != 0 &&
                                    product.last_price != product.mrp ? (
                                      <Popup
                                        style={{
                                          backgroundColor: "white",
                                          border: "1px solid grey",
                                          borderRadius: "5px",
                                          color: "black",
                                          padding: "5px",
                                          marginLeft: "5px",
                                          cursor: "pointer",
                                        }}
                                        on="click"
                                        pinned
                                        content={`₹${product.last_price}.00`}
                                        trigger={
                                          <IoAlertCircle
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "5px",
                                              fontSize: "18px",
                                              color: "red",
                                            }}
                                          />
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </td>
                                <td align="right" style={{ width: "6vw" }}>
                                  <TextField
                                    type="number"
                                    size="small"
                                    value={quantities[product.product_sku] || 1}
                                    onChange={(e) =>
                                      handleQuantityChange(e, product)
                                    }
                                    variant="outlined"
                                  />
                                </td>
                                <td
                                  align="right"
                                  style={{ width: "5vw", fontWeight: "bold" }}
                                >
                                  {formatCurrency(
                                    calculateProductTotal(product)
                                  )}
                                </td>
                                <td align="center" style={{ width: "2vw" }}>
                                  <FaTrashAlt
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() =>
                                      handleDeleteProduct(product.product_sku)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <tr>
                            <td
                              colSpan="9"
                              style={{ textAlign: "center", color: "red" }}
                            >
                              No Products Selected
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditSaleDetail;

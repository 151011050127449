import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { apiKey, delete_draft_purchase } from "../Api";
import { FaTrashCan } from "react-icons/fa6";
import Box from "@mui/material/Box";
import toast, { Toaster } from "react-hot-toast";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function DeleteDraftPurchase({
  openDelete,
  setOpenDelete,
  selectedPks,
  fetchData,
}) {
  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    axios
      .delete(delete_draft_purchase, {
        headers: {
          "API-Key": apiKey,
        },
        data: {
          draft_purchase_pks: selectedPks,
        },
      })
      .then(() => {
        toast.success(`Draft purchase deleted successfully!`);
        setOpenDelete(false);
        fetchData();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Dialog open={openDelete} onClose={handleClose}>
        <Box sx={style}>
          <DialogTitle style={{ color: "red" }}>
            <FaTrashCan style={{ marginTop: "-6px" }} /> Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDelete}>Confirm</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default DeleteDraftPurchase;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PrintBarcode from "../Purchase/PrintBarcode";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Checkbox } from "@mui/material";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 580 };
  }
};

function SeriesModal({
  open,
  setOpen,
  pseriesname,
  pseriessku,
  serieses,
  sendData,
}) {
  const [openModalBarcode, setOpenModalBarcode] = useState(false);
  const [barcodes, setBarcodes] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrintBarCode = (detail) => {
    console.log(detail);

    // Prepare the data for printing
    const barcodeData = {
      sku: sendData.sku, // Format SKU with series
      series: detail.series, // Use the selected series
      part_number: sendData.part_number,
      name: sendData.name,
      brand: sendData.brand__product_brand,
    };

    // console.log("barcodeData: ", barcodeData);

    // Wrap in an array to maintain consistency
    setBarcodes([barcodeData]);
    setOpenModalBarcode(true);

    // console.log("setOpenModalBarcode TRUE HO GYAS");
  };

  const [selectedSeries, setSelectedSeries] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const handleSelectSeries = (data) => {
    setSelectedSeries((prev) =>
      prev.some((s) => s.series === data.series)
        ? prev.filter((s) => s.series !== data.series)
        : [...prev, data]
    );
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedSeries([]);
    } else {
      setSelectedSeries(serieses);
    }
    setAllSelected(!allSelected);
  };

  const handlePrintAll = () => {
    const formatedData = selectedSeries.map((item) => ({
      sku: sendData.sku, // Assuming `sendData` is available in this scope
      series: item.series,
      part_number: sendData.part_number,
      name: sendData.name,
      brand: sendData.brand__product_brand,
    }));
    setBarcodes(formatedData);
    setOpenModalBarcode(true);
    // console.log("Print Wala Chala");
  };

  return (
    <>
      {/* {console.log("selectedSeries : ", selectedSeries)} */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit" style={{ cursor: "pointer" }}>
                {pseriessku}
              </Typography>
              <Typography sx={{ color: "text.primary" }}>
                {pseriesname}
              </Typography>
            </Breadcrumbs>
          </DialogTitle>
          <DialogContent>
            <div className="responsive-table1" style={{ maxHeight: "60vh" }}>
              <table className="ui table celled" id="c_table">
                <thead className="table-head">
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={handleSelectAll}
                        indeterminate={
                          selectedSeries.length > 0 &&
                          selectedSeries.length < serieses.length
                        }
                      />
                    </th>
                    <th>SKU</th>
                    <th>Warehouse</th>
                    <th>Row</th>
                    <th>Rack</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(serieses) && serieses.length > 0 ? (
                    serieses
                      .sort((a, b) => {
                        return Number(a.series) - Number(b.series); // Sort numerically
                      })
                      .map((data, index) => {
                        const isSelected = selectedSeries.some(
                          (s) => s.series === data.series
                        );
                        return (
                          <tr
                            key={index}
                            style={isSelected ? { background: "#e1ebfd" } : {}}
                          >
                            <td>
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => handleSelectSeries(data)}
                              />
                            </td>
                            <td className="text-nowrap">
                              <span
                                style={{ color: "grey" }}
                              >{`${pseriessku}_`}</span>
                              <b style={{ color: "red" }}>{data.series}</b>
                            </td>
                            <td>{data.ware_house__ware_house}</td>
                            <td>
                              {data.rack__row__row_name
                                ? data.rack__row__row_name
                                : "-"}
                            </td>
                            <td>
                              {data.rack__rack_name
                                ? data.rack__rack_name
                                : "-"}
                            </td>
                            <td align="right">
                              <Button
                                size="small"
                                variant="outlined"
                                onClick={() => handlePrintBarCode(data)}
                              >
                                Print
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan={6} align="center">
                        No series available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </DialogContent>
          <DialogActions className="">
            {selectedSeries && selectedSeries.length > 0 && (
              <Button
                variant="outlined"
                onClick={handlePrintAll}
                color="primary"
              >
                Print All
              </Button>
            )}

            <Button variant="outlined" onClick={handleClose} color="error">
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* PrintRackBarcode Modal */}
      <PrintBarcode
        handleCloseBarcode={setOpenModalBarcode}
        openBarcode={openModalBarcode}
        barcodes={barcodes}
      />
    </>
  );
}

export default SeriesModal;

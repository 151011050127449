import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  fetch_warehouse,
  apiKey,
  printracksBarcode,
  add_warehouse,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignWare from "./UpdateRows/EditWarehouse";
import PrintBarcode from "./PrintRackBarcode";
import DeleteWarehouse from "../../Messages/DeleteWarehouse/DeleteWarehouse";
import AddWarehouseModal from "./AddWarehouseModal.js";
import { IoMdArrowRoundBack } from "react-icons/io";
// import { Pagination, Icon } from "semantic-ui-react";
import { useScreenWidth } from "../../useScreenWidth";
import { PiWarehouseFill } from "react-icons/pi";
import toast, { Toaster } from "react-hot-toast";
import CustomLoader from "../../Components/Loader";

function Warehouses() {
  const isWideScreen = useScreenWidth();
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [warehouse, setWarehouse] = useState("");
  const [address, setAddress] = useState("");
  const [warepk, setWarePk] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalBarcode, setOpenModalBarcode] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  const handleEditClick = (id, ware_house, address) => {
    setOpenModal(true);
    setWarePk(id);
    setWarehouse(ware_house);
    setAddress(address);
  };

  const [racksbar, setRacksBar] = useState("");
  const handlePrintBarcode = (id) => {
    setOpenModalBarcode(true);
    try {
      axios
        .get(printracksBarcode, {
          params: {
            warehouse_pk: parseInt(id),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setRacksBar(response.data.racks_barcode);
          setLoading(false);
        })
        .catch((error) => {
          toast.error(error);
          setLoading(false);
        });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleViewClick = (sku) => {
    navigate(`/row-rack/${sku}`);
  };

  // Delete Warehouse

  const [pks, setPks] = useState([]);
  const [openDeleteWarehouse, setOpenDeleteWarehouse] = useState(false);

  const handleDeleteWarehouse = () => {
    const selectedPks = selectedRows.map((warehouse) => warehouse.id);

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      toast.error("No Warehouse selected!");
      // setOpenError(true)
      return;
    } else {
      // setOpenDelete(true);
      setOpenDeleteWarehouse(true);
    }
  };

  // Too add Warehouse
  const [OpenAddWarehouseModal, setOpenAddWarehouseModal] = useState(false); // State for modal

  const handleAddWarehouse = (warehouse, address) => {
    const formattedData = {
      warehouse: warehouse,
      address: address,
    };

    axios
      .post(add_warehouse, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenAddWarehouseModal(false);
          fetchData();
          toast.success(`A new warehouse has been added successfully!`);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );
      });
  };

  // Selection
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Only include products that are receive_status
      const isDeletable = warehouses.filter(
        (warehouse) => warehouse.deletable !== 0
      );
      setSelectedRows(isDeletable);
    } else {
      // Clear selectedRows if unchecked
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (warehouses) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.find((item) => item.id === warehouses.id)) {
        // If already selected, remove it
        return prevSelected.filter((item) => item.id !== warehouses.id);
      } else {
        // Otherwise, add the full product object
        return [...prevSelected, warehouses];
      }
    });
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <AddWarehouseModal
        open={OpenAddWarehouseModal}
        handleClose={() => setOpenAddWarehouseModal(false)}
        handleAddWarehouse={handleAddWarehouse}
      />

      <DeleteWarehouse
        openDeleteWarehouse={openDeleteWarehouse}
        setOpenDeleteWarehouse={setOpenDeleteWarehouse}
        selectedPks={pks}
        fetchData={fetchData}
      />

      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        warepk={warepk}
        warehouse={warehouse}
        address={address}
        fetchData={fetchData}
      />

      <PrintBarcode
        openModalBarcode={openModalBarcode}
        setOpenModalBarcode={setOpenModalBarcode}
        racksbar={racksbar}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography sx={{ color: "text.primary" }}>Warehouses</Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search warehouse here..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  // value={searchquery}
                  // onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6 mb_btn_hide"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenAddWarehouseModal(true)}
                >
                  Add Warehouse
                </Button>
                {selectedRows && selectedRows.length > 0 ? (
                  <>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleDeleteWarehouse}
                    >
                      Delete Warehouse
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th style={{ width: "2px" }}>
                        <input
                          type="checkbox"
                          checked={
                            selectedRows.length ===
                              warehouses.filter(
                                (warehouse) => warehouse.deletable !== 0
                              ).length &&
                            warehouses.some(
                              (warehouse) => warehouse.deletable !== 0
                            )
                          }
                          onChange={handleSelectAll}
                          disabled={warehouses.every(
                            (warehouse) => warehouse.deletable === 0
                          )}
                        />
                      </th>
                      <th>ID</th>
                      <th>Warehouse</th>
                      <th>Address</th>

                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {warehouses.length > 0 ? (
                      <>
                        {warehouses &&
                          warehouses.map((data) => (
                            <>
                              <tr>
                                <td style={{ width: "2px" }}>
                                  <input
                                    type="checkbox"
                                    checked={selectedRows.some(
                                      (item) => item.id === data.id
                                    )}
                                    onChange={() => handleRowSelect(data)}
                                    disabled={data.deletable === 0}
                                  />
                                </td>
                                <td style={{ width: "50px" }}>{data.id}</td>
                                <td>{data.ware_house}</td>
                                <td>{data.address ? data.address : "-"}</td>
                                <td style={{ width: "200px" }}>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="success"
                                    onClick={() => handleViewClick(data.id)}
                                  >
                                    View
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "5px" }}
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    onClick={() =>
                                      handleEditClick(
                                        data.id,
                                        data.ware_house,
                                        data.address
                                      )
                                    }
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "5px" }}
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    onClick={() => handlePrintBarcode(data.id)}
                                  >
                                    Print
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan="5">
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {warehouses.length > 0 ? (
                  <>
                    {warehouses &&
                      warehouses.map((data) => (
                        <>
                          <div
                            className="product-card"
                            onClick={() => handleViewClick(data.id)}
                          >
                            <div>
                              <PiWarehouseFill className="warehouse-img" />
                            </div>
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5 className="product-card-name">
                                {data.ware_house}
                              </h5>
                              <h5 className="warehouse-card-address">
                                {data.address ? data.address : "-"}
                              </h5>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Warehouses;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, fetch_purchase_detail, fetch_warehouse } from "../../Api";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import ReceiveAllModal from "./RcvAlltoWarehouseModal";
import RcvImg from "../../Assets/Img/handshake.gif";
import { IoMdArrowRoundBack } from "react-icons/io";

function GoodAllocation() {
  const [openModalWarehouse, setOpenModalWarehouse] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [rcvstatus, setRcvStatus] = useState("");
  const [rcvby, setRcvBy] = useState("");
  const [rcvdate, setRcvDate] = useState("");
  const [rcvwarehouse, setRcvWarehouse] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchquery, setSearchQuery] = useState("");

  const [error, setError] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const fetchPurchases = (page = currentPage, query = searchquery) => {
    const bodyData = {
      purchase_pk: parseInt(id),
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
    };
    axios
      .post(fetch_purchase_detail, bodyData, {
        headers: {
          "API-Key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSelectedProducts(response.data.purchase_detail.items_detail);
        setRcvStatus(response.data.purchase_detail.receive_status);
        setRcvBy(response.data.purchase_detail.received_by);
        setRcvDate(response.data.purchase_detail.received_date);
        setRcvWarehouse(response.data.purchase_detail.received_warehouse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPurchases();
  }, [currentPage, pageSize, searchquery]);

  const [warehouses, setWarehouses] = useState([]);

  const handleRcvAll = () => {
    try {
      axios
        .get(fetch_warehouse, {
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          // console.log(response);
          setWarehouses(response.data.warehouses);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch {
      console.log("error");
      setOpenModalWarehouse(false);
    }
    setOpenModalWarehouse(true);
  };

  return (
    <>
      <ReceiveAllModal
        setOpenModalWarehouse={setOpenModalWarehouse}
        openModalWarehouse={openModalWarehouse}
        warehouses={warehouses}
        purchase_pk={id}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_purchases")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit" style={{ cursor: "pointer" }}>
                Purchases
              </Typography>
              <Typography sx={{ color: "text.primary" }}>
                Receive Goods
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-2">
            <div className="col-lg-8">
              <div className="card p-4">
                <div className="products_table_class">
                  <div className="responsive-table1">
                    <table className="ui table celled" id="c_table">
                      <thead className="table-head">
                        <tr>
                          <th>SKU</th>
                          <th>Product</th>
                          <th>Purchased Qty</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProducts && selectedProducts.length > 0 ? (
                          <>
                            {selectedProducts.map((product, index) => (
                              <tr key={index}>
                                <td style={{ width: "6.5vw" }}>
                                  {product.product_sku}
                                </td>
                                <td>{product.product_name}</td>
                                <td style={{ width: "8vw" }}>
                                  {product.quantity}{" "}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td
                                colSpan="7"
                                style={{ textAlign: "center", color: "red" }}
                              >
                                No Products Selected
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card p-4">
                {rcvstatus && rcvstatus === 1 ? (
                  <>
                    <h4>Received Details</h4>
                    <hr />
                    <div className="row">
                      <div className="col-6 my-2">Received Warehouse:</div>
                      <div className="col-6 my-2">
                        <h5>{rcvwarehouse}</h5>
                      </div>

                      <div className="col-6 my-2">Received Date:</div>
                      <div className="col-6 my-2">
                        <h5>{rcvdate}</h5>
                      </div>

                      <div className="col-6 my-2">Received By:</div>
                      <div className="col-6 my-2">
                        <h5>{rcvby}</h5>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card" onClick={() => handleRcvAll()}>
                      <div className="row">
                        <div className="col-3">
                          <img
                            src={RcvImg}
                            className="img-fluid"
                            style={{
                              height: "auto",
                              width: "100%",
                              mixBlendMode: "multiply",
                            }}
                          />
                        </div>
                        <div
                          className="col-9"
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button variant="outlined">Receive All</Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GoodAllocation;

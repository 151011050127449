import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { apiKey, requirement_pickup } from "../../Api";
import CustomLoader from "../../Components/Loader";
import { useScreenWidth } from "../../useScreenWidth";
import { Breadcrumbs, Button, Typography } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import Quagga from "quagga";
import Webcam from "react-webcam";

function PickUp() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const isWideScreen = useScreenWidth();
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const ws = useRef(null);
  const [isCameraOpen, setIsCameraOpen] = useState(true);
  const webcamRef = useRef(null);

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  useEffect(() => {
    // Establish WebSocket connection with API-Key as a query parameter
    ws.current = new WebSocket(
      `${requirement_pickup}?requirement_pk=${id}&API-Key=${apiKey}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connected");
      setLoading(false);
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      console.log(response.items);
      setData(response.items);
      // Assuming response.data.purchase_detail.items_detail is the data you need
      // setData(response.data.purchase_detail.items_detail);
    };

    ws.current.onerror = (error) => {
      console.error(error, "WebSocket error");
    };

    ws.current.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [id, apiKey]);

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
  }, [searchQuery]);

  const fetchData = (query = searchQuery) => {
    const bodyData = {
      requirement_pk: parseInt(id),
      search: query,
    };

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(bodyData));
    }
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Requirement</Typography>
              <Typography sx={{ color: "text.primary" }}>Pick Up</Typography>
            </Breadcrumbs>
          </div>

          {isCameraOpen ? (
            <div className="scannerDiv">
              <Webcam
                audio={false}
                ref={webcamRef}
                width={1920}
                height={220}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  facingMode: "environment",

                  border: "none",
                }}
                style={{
                  marginBottom: "12px",
                  border: "3px dashed green",
                  padding: "0",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
              <Button
                variant="outlined"
                color="error"
                onClick={handleCloseCamera}
              >
                Close
              </Button>
            </div>
          ) : null}

          <div className="row my-1">
            {isWideScreen ? (
              <>
                <div className="responsive-table1">
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Warehouse</th>
                        <th>Row</th>
                        <th>Rack</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        <>
                          {data &&
                            data.map((req, index) => (
                              <>
                                <tr key={index}>
                                  <td style={{ width: "40px" }}>{index + 1}</td>

                                  <td>{req.quantity}</td>
                                  <td>{req.picked_quantity}</td>
                                </tr>
                              </>
                            ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={5}>
                              {!loading ? (
                                <div className="notfound_img_div">
                                  <div className="notfound_img"></div>
                                </div>
                              ) : (
                                <div className="notfound_img_div">
                                  <div className="loadingfound_img"></div>
                                </div>
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="card p-2 responsive-mobile-table"></div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PickUp;

import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { transfer_product_immediately, apiKey } from "../../../Api";
import toast, { Toaster } from "react-hot-toast";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function ConfirmSaleProductTransferModal({
  openConfirmTransfer,
  setOpenConfirmTransfer,
  warehouse,
  setsku,
  addScannedProduct,
  productDatatoModal,
}) {
  const handleClose = () => {
    setOpenConfirmTransfer(false);
  };

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [loading, setLoading] = useState(false);

  const handleTransferProduct = () => {
    setLoading(true);
    const saleData = {
      product_sku: setsku,
      warehouse_pk: parseInt(warehouse),
      user_name: userDetails.name,
    };

    axios
      .post(transfer_product_immediately, saleData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          addScannedProduct(setsku, productDatatoModal);
          toast.success("Product Transferred Successfully");

          setOpenConfirmTransfer(false);
          setLoading(false);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Dialog
        open={openConfirmTransfer}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "#ed4928", fontSize: "1.5rem", fontWeight: "bold" }}
          >
            Transfer Product?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This Product is allocated in different warehouse, want to
              transfer?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleClose}
            >
              Skip
            </Button>
            <Button
              variant="outlined"
              size="small"
              disabled={loading == true}
              onClick={handleTransferProduct}
            >
              Proceed
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default ConfirmSaleProductTransferModal;

import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Box, Button, Modal, Typography } from "@mui/material";
import { apiKey, pending_dispatch } from "../../Api";
import { useNavigate } from "react-router-dom";

const viewDispatchStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  // maxWidth: "80%",
  width: { xs: "90%", sm: "80%", md: "550px" },
  height: "600px",
  overflow: "auto",
};

function AllDispatchModal({
  openViewAllDispatchModal,
  handleCloseViewAllDispatchModal,
}) {
  const [dispatchData, setDispatchData] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const [debounceSearchquery, setdebounceSearchQuery] = useState("");
  const navigate = useNavigate();

  const fetchDispatchData = (query = debounceSearchquery) => {
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(
        pending_dispatch,
        {
          search: query,
        },
        { headers }
      )
      .then((response) => {
        // console.log("Dispatch : ", response.data.data);
        setDispatchData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setdebounceSearchQuery(query);
  };

  useEffect(() => {
    fetchDispatchData();
  }, [debounceSearchquery]);

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        open={openViewAllDispatchModal}
        onClose={handleCloseViewAllDispatchModal}
      >
        <Box sx={viewDispatchStyle}>
          <div className="d-flex align-items-center justify-content-between">
            <Typography className="mb-3" variant="h6" component="h2">
              Pending Dispatches
            </Typography>

            <Button
              style={{ marginLeft: "5px" }}
              className="mb-3"
              onClick={handleCloseViewAllDispatchModal}
              color="error"
              variant="outlined"
            >
              Close
            </Button>
          </div>
          <div className="row ">
            <div className="col-lg-12 mb-2">
              <input
                className="form-control bordered rounded"
                placeholder="Search..."
                style={{ backgroundColor: "white" }}
                type="search"
                value={searchquery}
                onChange={handleSearchChange}
              />
            </div>

            <div
              className="col-md-12 "
              style={{ maxHeight: "400px", overflow: "auto" }}
            >
              {dispatchData && dispatchData.length === 0 ? (
                <div className="text-center py-5 fs-4 text-danger border rounded">
                  No pending dispatch found
                </div>
              ) : (
                dispatchData.map((dispatch) => (
                  <div
                    key={dispatch.id}
                    className="d-flex align-items-center justify-content-between  mb-1 border border-1 rounded p-1 hover-div"
                  >
                    <div>
                      <h5
                        className="mb-0 color-primary"
                        style={{ color: "#2e3955", fontWeight: "500" }}
                      >
                        {dispatch.billing_customer.customer_name}
                      </h5>
                      <p className="text-muted mb-0">
                        {dispatch.total_products} / {dispatch.sale_date}
                      </p>
                    </div>
                    <div>
                      <Button
                        className=" ms-2"
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() =>
                          window.open(`/#/view-sale/${dispatch.id}`, "_blank")
                        }
                      >
                        View
                      </Button>
                      <Button
                        className=" ms-2"
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                          navigate(`/dispatch-sale-scan/${dispatch.id}`)
                        }
                      >
                        dispatch
                      </Button>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AllDispatchModal;

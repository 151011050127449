import React, { useState } from "react";
import axios from "axios";
import { apiKey, import_customers } from "../../../Api";
import {
  Breadcrumbs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";

import { FaTrashAlt } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";

function ImportCustomer() {
  const navigate = useNavigate();

  const [existingGstNumbers, setExistingGstNumbers] = useState([]);
  const [customerNameError, setCustomerNameError] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [isDataPreview, setIsDataPreview] = useState(false);
  const [columnMappings, setColumnMappings] = useState({
    customer_name: "",
    email: "",
    phone: "",
    alternativ_phone: "",
    gst_number: "",
    address_id: "",
    contact_name: "",
    contact_no: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "", // Added Pincode
    category: "",
  });
  const [mappedPreviewData, setMappedPreviewData] = useState([]);

  const headerMapping = {
    "Customer Name": "customer_name",
    Email: "email",
    Phone: "phone",
    "Alternative Phone": "alternativ_phone",
    "GST Number": "gst_number",
    "Address ID": "address_id",
    "Contact Name": "contact_name",
    "Contact No": "contact_no",
    Address: "address",
    City: "city",
    State: "state",
    Country: "country",
    Pincode: "pincode",
    Category: "category",
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      toast.error("Please select a file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });

      // Trim whitespace and filter out empty rows
      const cleanedData = data
        .map((row) =>
          row.map((cell) => (typeof cell === "string" ? cell.trim() : cell))
        )
        .filter((row) => row.some((cell) => cell !== ""));

      // Remove empty columns
      const transposedData = cleanedData[0].map((_, colIndex) =>
        cleanedData.map((row) => row[colIndex])
      );
      const nonEmptyColumns = transposedData.filter((col) =>
        col.some((cell) => cell !== "")
      );
      const finalData = nonEmptyColumns[0].map((_, colIndex) =>
        nonEmptyColumns.map((row) => row[colIndex])
      );

      setExcelData(finalData);
      setIsDataPreview(true);
      autoMapColumns(finalData[0], finalData); // Pass the cleaned data to map and preview
    };

    reader.readAsBinaryString(file);
  };

  const autoMapColumns = (headers, fullData) => {
    const updatedMappings = { ...columnMappings };
    headers.forEach((header, index) => {
      const field = headerMapping[header];
      if (field) {
        updatedMappings[field] = index; // Map the column index
      }
    });
    setColumnMappings(updatedMappings);
    processExcelData(updatedMappings, fullData); // Pass full data to process
  };

  const processExcelData = (mappings, fullData) => {
    const previewData = fullData.slice(1).map((row, index) => ({
      rowNumber: index + 2, // Adjust for header row
      customer_name: row[mappings.customer_name] || "",
      email: row[mappings.email] || "",
      phone: row[mappings.phone] || "",
      alternativ_phone: row[mappings.alternativ_phone] || "",
      gst_number: row[mappings.gst_number] || "",
      address_id: row[mappings.address_id] || "",
      contact_name: row[mappings.contact_name] || "",
      contact_no: row[mappings.contact_no] || "",
      address: row[mappings.address] || "",
      city: row[mappings.city] || "",
      state: row[mappings.state] || "",
      country: row[mappings.country] || "",
      pincode: row[mappings.pincode] || "",
      category: row[mappings.category] || "",
    }));

    setMappedPreviewData(previewData);
  };

  const handleMappingChange = (field, index) => {
    const updatedMappings = { ...columnMappings, [field]: index };
    setColumnMappings(updatedMappings);
    processExcelData(updatedMappings, excelData); // Update preview data based on mapping change
  };

  const handleSubmit = async () => {
    if (mappedPreviewData.length === 0) {
      toast.error("No customer data to upload.");
      return;
    }

    const invalidRows = mappedPreviewData.filter((row) => !row.customer_name);
    if (invalidRows.length > 0) {
      const rowNumbers = invalidRows.map((row) => row.rowNumber).join(", ");
      // setCustomerNameError(`Please fill in Customer names for the following rows: ${rowNumbers}`);
      toast.error(
        `Please add Customer names for the following rows: ${rowNumbers}`
      );
      // setOpenError(true);
      return;
    }

    // Check for duplicate GST numbers
    const gstSet = new Set();
    const duplicateGstRows = [];

    mappedPreviewData.forEach((row, index) => {
      if (row.gst_number) {
        if (gstSet.has(row.gst_number)) {
          duplicateGstRows.push(row.rowNumber); // Store the row number if duplicate
        } else {
          gstSet.add(row.gst_number); // Add to set if not a duplicate
        }
      }
    });

    if (duplicateGstRows.length > 0) {
      const duplicateRows = duplicateGstRows.join(", ");
      toast.error(
        `Duplicate GST numbers found in the following rows: ${duplicateRows}`
      );
      // setOpenError(true);
      return;
    }

    try {
      const customersToUpload = mappedPreviewData.map((customer, index) => ({
        // primary_shipping: {
        contact_name: customer.contact_name,
        contact_no: customer.contact_no,
        address: customer.address,
        city: customer.city,
        state: customer.state,
        country: customer.country || "India", // Use customer country
        pincode: customer.pincode || "",
        // customer_ref: index + 1,
        // },
        customer_name: customer.customer_name,
        email: customer.email,
        phone: customer.phone,
        alternativ_phone: customer.alternativ_phone,
        gst_number: customer.gst_number,
        category: customer.category,
      }));

      console.log(customersToUpload);

      // Uncomment below for actual API call
      const response = await axios.post(
        import_customers,
        { customers: customersToUpload },
        {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        }
      );

      if (response.data.status === 1) {
        // console.log("Upload Response: ", response.data);
        navigate("/all-customers");
        toast.success(
          response.data.message || "Customers Imported Successfully !!"
        );
      } else {
        toast.error(response.data);
        // setOpenError(true);
      }
    } catch (error) {
      console.error(error);
      const existingGstNumbers = error.response.data.existing_gst_numbers || [];
      setExistingGstNumbers(error.response.data.existing_gst_numbers || []);

      const existingGstMessage =
        existingGstNumbers.length > 0
          ? `The following GST numbers already exist: ${existingGstNumbers.join(
              ", "
            )}`
          : error.response.data.message;

      toast.error(existingGstMessage || "Something went wrong");
      // setOpenError(true);
    }
  };

  const handleDeleteRow = (index) => {
    const updatedData = mappedPreviewData.filter((_, i) => i !== index);
    setMappedPreviewData(updatedData);
    toast.success("Row deleted successfully!");
  };

  const gstSet = new Set();
  const gstDuplicates = new Set();

  // First pass to collect GST numbers and find duplicates
  mappedPreviewData.forEach((row) => {
    if (row.gst_number) {
      if (gstSet.has(row.gst_number)) {
        gstDuplicates.add(row.gst_number); // Add to duplicates if already seen
      } else {
        gstSet.add(row.gst_number); // Otherwise, add to the Set
      }
    }
  });

  return (
    <>
      <Toaster position="top-right" />

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="d-flex">
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all-customers")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                onClick={() => navigate("/all-customers")}
                className="cursor-pointer"
              >
                All Customers
              </Typography>
              <Typography>Import Customers</Typography>
            </Breadcrumbs>
          </div>

          <Button
            style={{ float: "right", marginTop: "-3.5vh" }}
            variant="contained"
            size="small"
            onClick={handleSubmit}
          >
            Import Customers
          </Button>

          <div
            className="card my-2 p-2"
            style={{
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <div className="row">
              <div className="col-md-3">
                <div className="mb-3">
                  <label htmlFor="formFile" className="form-label">
                    Upload Your Customers
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                  />
                </div>

                {isDataPreview && (
                  <>
                    <div
                      className="mb-3"
                      style={{ maxHeight: "70vh", overflow: "auto" }}
                    >
                      <h5>Map Your Columns</h5>
                      <div className="row">
                        {Object.keys(columnMappings).map((key) => (
                          <div
                            className="row align-items-center mb-2"
                            key={key}
                          >
                            <div className="col-6">
                              <label>
                                {key.replace(/_/g, " ").toUpperCase()}
                              </label>
                            </div>
                            <div className="col-6">
                              <Select
                                sx={{ m: 0, width: "100%" }}
                                size="small"
                                value={columnMappings[key]}
                                onChange={(e) =>
                                  handleMappingChange(key, e.target.value)
                                }
                              >
                                <MenuItem value="">Select Column</MenuItem>
                                {excelData[0]?.map((header, index) => (
                                  <MenuItem key={index} value={index}>
                                    {header}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="col-md-9">
                {isDataPreview && (
                  <div className="table-wrapper" style={{ marginTop: "2px" }}>
                    <div className="responsive-table1">
                      <table className="ui table celled" id="c_table">
                        <thead className="table-head">
                          <tr>
                            <th>#</th>
                            <th>Customer Name</th>
                            <th>Category</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Alternative Phone</th>
                            <th>GST Number</th>
                            <th>Address ID</th>
                            <th>Contact Name</th>
                            <th>Contact No</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Country</th>
                            <th>Pincode</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mappedPreviewData.map((row, index) => {
                            const isGstNumberExisting =
                              existingGstNumbers.includes(row.gst_number);

                            return (
                              <tr
                                key={index}
                                style={{
                                  background: !row.customer_name
                                    ? "#f8d7da" // Highlight for missing name
                                    : isGstNumberExisting
                                    ? "#ffeeba" // Highlight for existing GST numbers
                                    : "transparent",
                                }}
                              >
                                <td>{index + 1}</td>
                                <td>{row.customer_name || "-"}</td>
                                <td>{row.category || "-"}</td>
                                <td>{row.email || "-"}</td>
                                <td>{row.phone || "-"}</td>
                                <td>{row.alternative_phone || "-"}</td>
                                <td>{row.gst_number || "-"}</td>
                                <td>{row.address_id || "-"}</td>
                                <td>{row.contact_name || "-"}</td>
                                <td>{row.contact_no || "-"}</td>
                                <td>{row.address || "-"}</td>
                                <td>{row.city || "-"}</td>
                                <td>{row.state || "-"}</td>
                                <td>{row.country || "-"}</td>
                                <td>{row.pincode || "-"}</td>
                                <td>
                                  <FaTrashAlt
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => handleDeleteRow(index)}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImportCustomer;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import { apiKey, conversion_history_items } from "../../Api";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Pagination, Icon, Label } from "semantic-ui-react";
import CustomLoader from "../../Components/Loader";
import { useScreenWidth } from "../../useScreenWidth";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";

function ConversionDetail() {
  const { id } = useParams();
  const isWideScreen = useScreenWidth();
  const [conversionDetail, setConversionDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");

  // Other Main Information
  const [conversionOtherDetail, setConversionOtherDetail] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, id]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      convert_history_pk: parseInt(id),
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(conversion_history_items, data, { headers })
      .then((response) => {
        setConversionDetail(response.data.convert_history);
        setConversionOtherDetail(response.data.history_obj);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data?.message);

        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };
  // const debouncedHandleSearchChange = useCallback(debounce(handleSearchChange, 1100), []);

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when page size changes
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  return (
    <>
      <CustomLoader loading={loading} />
      <Toaster position="top-right" />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/conversions")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Conversion</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Conversion History
              </Typography>
              <Typography>#{id}</Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-3">
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div className="col-md-3 my-1">
                <h5>
                  Warehouse :{" "}
                  <b style={{ color: "black" }}>
                    {conversionOtherDetail?.warehouse || "N/A"}
                  </b>
                </h5>
              </div>
              <div className="col-md-3 my-1">
                <h5>
                  Date :
                  <b style={{ color: "black" }}>
                    {conversionOtherDetail?.convert_date.toLocaleString() ||
                      "N/A"}
                  </b>
                </h5>
              </div>
              <div className="col-md-3 my-1">
                <h5>
                  Conversion By :
                  <b style={{ color: "black" }}>
                    {conversionOtherDetail?.user_name || "N/A"}
                  </b>
                </h5>
              </div>

              <div className="col-lg-3 my-1">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <div>
            {isWideScreen ? (
              <>
                <div className="responsive-table1">
                  <table className="ui table celled" id="c_table">
                    <thead className="table-head">
                      <tr>
                        <th>ID</th>
                        <th>Source Product</th>

                        <th>Target Product</th>

                        <th style={{ width: "90px", textAlign: "center" }}>
                          Quantity
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {conversionDetail &&
                        conversionDetail.map((conversion, index) => (
                          <tr key={conversion.id || index}>
                            <td>{conversion.id}</td>
                            <td>
                              <span
                                style={{ color: "grey" }}
                                className="d-block"
                              >
                                #{conversion.source_product.product_sku}
                              </span>

                              <span style={{ fontSize: "13px" }}>
                                {conversion.source_product.product_name}
                              </span>
                            </td>

                            <td>
                              <span
                                style={{ color: "grey" }}
                                className="d-block"
                              >
                                #{conversion.target_product.product_sku}
                              </span>

                              <span style={{ fontSize: "13px" }}>
                                {conversion.target_product.product_name}
                              </span>
                            </td>

                            <td style={{ width: "90px", textAlign: "center" }}>
                              {conversion.quantity}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div className="card p-2 responsive-mobile-table">
                  {conversionDetail.length > 0 ? (
                    <>
                      {conversionDetail &&
                        conversionDetail.map((data, index) => (
                          <>
                            <div
                              className="product-card"
                              key={data.id || index}
                            >
                              <div className="py-1 px-2">
                                <h5 className="product-card-sku">
                                  #{data.source_product.product_sku || "N/A"}
                                </h5>
                                <h5 className="product-card-name">
                                  {data.source_product.product_name}
                                </h5>
                                <h5 style={{ textAlign: "center" }}>
                                  <Label
                                    style={{
                                      margin: "5px 0px",
                                      textAlign: "center",
                                    }}
                                    size="small"
                                  >
                                    <MdKeyboardDoubleArrowDown /> Converted to
                                  </Label>
                                  <Label
                                    color="green"
                                    style={{
                                      margin: "5px 0px",
                                      marginLeft: "5px",
                                    }}
                                    size="small"
                                  >
                                    QTY {data.quantity}
                                  </Label>
                                </h5>
                                <h5 className="product-card-sku">
                                  #{data.target_product.product_sku || "N/A"}
                                </h5>
                                <h5 className="product-card-name">
                                  {data.target_product.product_name}
                                </h5>
                              </div>
                            </div>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div style={{ marginTop: "2px", display: "flex" }}>
              <select
                className="form-control"
                style={{
                  width: "50px",
                  marginRight: "5px",
                  backgroundColor: "white",
                }}
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <Pagination
                size="small"
                defaultActivePage={1}
                onPageChange={handlePageChange}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConversionDetail;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, recieve_all } from "../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function RcvAlltoWarehouseModal({
  setOpenModalWarehouse,
  openModalWarehouse,
  warehouses,
  purchase_pk,
}) {

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const navigate = useNavigate()

  const [primarywarehouse, setPrimaryWare] = useState("");
  const handleClose = () => {
    setOpenModalWarehouse(false);
  };

  const handleReceive = (e) => {
    e.preventDefault();

    const formattedData = {
      purchase_pk: parseInt(purchase_pk),
      warehouse_pk: primarywarehouse,
      user_name: userDetails.name,
    };

    // userDetails.email = primarywarehouse;

    axios
      .post(recieve_all, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          toast.success("Purchase received successfully!");

          setOpenModalWarehouse(false);
          navigate("/all_purchases")
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
        toast.error(
          error.response.data.message ||
          "Something went wrong! Please try again."
        );
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        open={openModalWarehouse}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="p"
            component="p"
            style={{ marginBottom: "15px" }}
          >
            Receive all Products
          </Typography>

          <div
            style={{
              maxHeight: "30vh",
            }}
          >
            <FormControl size="small" fullWidth>
              <InputLabel>Warehouse</InputLabel>
              <Select
                value={primarywarehouse}
                label="Warehouse"
                onChange={(e) => setPrimaryWare(e.target.value)}
              >
                {" "}
                <MenuItem value="">--Select Warehouse--</MenuItem>
                {warehouses.map((ware) => (
                  <MenuItem value={ware.id}>{ware.ware_house}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "5px" }}
              variant="outlined"
              color="primary"
              onClick={handleReceive}
            // disabled={
            //   Boolean(error) ||
            //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
            // }
            >
              Receive
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default RcvAlltoWarehouseModal;

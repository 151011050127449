import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IoIosAddCircle } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Paper from "@mui/material/Paper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "900px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function InvenModal({ setOpenModal, openModal, data }) {
  const handleClose = () => {
    setOpenModal(false);
  };

  console.log(data.obj_send, "is data");
  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Allocate Inventory for <b style={{ color: "black" }}>{warehouse}</b>
          </Typography> */}

          <div
            style={{
              overflowY: "scroll",
              maxHeight: "30vh",
              overflowX: "hidden",
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell align="left">Warehouse</TableCell>
                    <TableCell align="left">Row</TableCell>
                    <TableCell align="left">Rack</TableCell>
                    <TableCell align="left">Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.length > 0 ? (
                    <>
                      {data.map((dataa, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{dataa.obj_send.ware_house}</TableCell>
                          <TableCell>
                            {dataa.obj_send.row ? dataa.obj_send.row : "-"}
                          </TableCell>
                          <TableCell>
                            {dataa.obj_send.rack ? dataa.obj_send.rack : "-"}
                          </TableCell>
                          <TableCell>
                            {dataa.obj_send.quantity
                              ? dataa.obj_send.quantity
                              : "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default InvenModal;

import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import { format, subMonths, startOfYear, endOfYear, subYears , subDays, addDays } from 'date-fns';
import { Button } from '@mui/material';
import { FaCaretDown } from 'react-icons/fa';

function FilterDateRangeModal({ title, startDate, endDate, onDateChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const [tempStartDate, setTempStartDate] = useState(startDate);
    const [tempEndDate, setTempEndDate] = useState(endDate);
    const buttonRef = useRef(null);
    const modalRef = useRef(null);

    useEffect(() => {
        setTempStartDate(startDate);
        setTempEndDate(endDate);
    }, [startDate, endDate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    // const handleApply = () => {
    //     onDateChange(tempStartDate, tempEndDate);
    //     setIsOpen(false);
    // };

    const handleApply = () => {
        const adjustedStartDate = subDays(tempStartDate, 1);
        const adjustedEndDate = addDays(tempEndDate, 1);
        const formattedStartDate = format(adjustedStartDate, "yyyy-MM-dd");
        const formattedEndDate = format(adjustedEndDate, "yyyy-MM-dd");
        onDateChange(formattedStartDate, formattedEndDate);
        setIsOpen(false);
    };

    const handleClear = () => {
        onDateChange(null, null);
        setTempStartDate(null);
        setTempEndDate(null);
        setIsOpen(false);
    };

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const getModalPosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            return {
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            };
        }
        return {};
    };

    const customStaticRanges = createStaticRanges([
        ...defaultStaticRanges,
        {
            label: "6 Months",
            range: () => ({
                startDate: subMonths(new Date(), 6),
                endDate: new Date(),
            }),
        },
        {
            label: "This Year",
            range: () => ({
                startDate: startOfYear(new Date()),
                endDate: endOfYear(new Date()),
            }),
        },
        {
            label: "Previous Year",
            range: () => ({
                startDate: startOfYear(subYears(new Date(), 1)),
                endDate: endOfYear(subYears(new Date(), 1)),
            }),
        },
    ]);

    const modalContent = (
        <div className="filter-modal-date" style={getModalPosition()} ref={modalRef}>
            <DateRangePicker
                onChange={(item) => {
                    setTempStartDate(item.selection.startDate);
                    setTempEndDate(item.selection.endDate);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[{ startDate: tempStartDate, endDate: tempEndDate, key: 'selection' }]}
                direction="horizontal"
                preventSnapRefocus={true}
                staticRanges={customStaticRanges}
            />
            <div className="d-flex justify-content-end mt-2">
                <Button variant="outlined" color="error" onClick={handleClear}>Clear</Button>
                <Button variant="outlined" color="primary" onClick={handleApply} className="ms-2">Apply</Button>
            </div>
        </div>
    );

    return (
        <div ref={buttonRef}>
            <div onClick={handleToggleOpen} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                {title} <FaCaretDown />
            </div>
            {isOpen && ReactDOM.createPortal(
                modalContent,
                document.body
            )}
        </div>
    );
}

export default FilterDateRangeModal;

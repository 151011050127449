import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_all_product,
  fetch_vendor,
  fetch_purchase_detail,
  edit_purchase,
} from "../../Api";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaTrashAlt } from "react-icons/fa";

import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import { useParams, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Breadcrumbs, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "../../Components/CustomToolbar";
import { debounce } from "lodash";

import { Pagination, Icon } from "semantic-ui-react";

function ViewPurchase() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchquery, setSearchQuery] = useState("");

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [purchase, setPurchase] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchListRef = useRef(null);
  const [quantities, setQuantities] = useState({});
  const [vendors, setVendors] = useState([]);
  const [vendor, setVendor] = useState("");
  const [purchase_date, setP_date] = useState("");
  const [reference_no, setRef_no] = useState("");
  const [rcvstatus, setRcvStatus] = useState("");
  const [rcvwarehouse, setRcvWarehouse] = useState("");
  const navigate = useNavigate();
  // const [openDelete, setOpenDelete] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const { id } = useParams();

  useEffect(() => {
    fetchVendors();
    fetchPurchases();
  }, []);

  const fetchVendors = () => {
    axios
      .get(fetch_vendor, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        //console.log(response.data.vendors);
        setVendors(response.data.vendors);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPurchases = (page = currentPage, query = searchquery) => {
    const bodyData = {
      purchase_pk: parseInt(id),
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
    };
    axios
      .post(fetch_purchase_detail, bodyData, {
        headers: {
          "API-Key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Responses : ", response.data);

        setTotalPages(response.data.num_pages);
        setPurchase(response.data.purchase_detail);

        setRcvStatus(response.data.purchase_detail.receive_status);
        setP_date(response.data.purchase_detail.purchase_date);
        setRef_no(response.data.purchase_detail.reference_number);
        setVendor(response.data.purchase_detail.vendor_ref.id);
        setSelectedProducts(response.data.purchase_detail.items_detail);
        setRcvWarehouse(response.data.purchase_detail.received_warehouse);
        const initialQuantities = {};
        response.data.purchase_detail.items_detail.forEach((item) => {
          initialQuantities[item.product_sku] = item.quantity;
        });
        setQuantities(initialQuantities);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPurchases();
  }, [currentPage, pageSize, searchquery]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    // if (query.length > 2){
    setSearchQuery(query);
    setCurrentPage(1);

    // }
  };

  // Pagination

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  return (
    <>
      {console.log("purchase : ", purchase)}
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_purchases")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Purchases</Typography>
              <Typography color="inherit">Purchase Detail</Typography>
              <Typography sx={{ color: "text.primary" }}>#{id}</Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-2">
            <div className="col-lg-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col-lg-3 my-2" style={{ display: "flex" }}>
                    <strong>Vendor : </strong>{" "}
                    <span> {purchase && purchase.vendor_ref?.name}</span>
                  </div>
                  <div className="col-lg-3 my-2" style={{ display: "flex" }}>
                    <strong>Date : </strong>{" "}
                    <span> {purchase && purchase?.purchase_date}</span>
                  </div>
                  <div className="col-lg-3 my-2" style={{ display: "flex" }}>
                    <strong>Created By : </strong>{" "}
                    <span> {purchase && purchase?.created_by}</span>
                  </div>

                  <div
                    className="col-lg-3 my-2"
                    style={{
                      display: "flex",
                      marginTop: "1px",
                      // borderLeft: "1px solid grey",
                    }}
                  >
                    <div>Bill to:</div>
                    <div style={{ marginLeft: "5px" }}>
                      <b>Kuber Turbo Tech</b>
                      <br></br>
                      Delhi, IN
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3">
                <h5>Purchase Summary</h5>
                <div className="row" style={{ marginTop: "13px" }}>
                  <div className="col-6 my-2">Total Products</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    {selectedProducts.length}
                  </div>
                  <div className="col-6 my-2">Received Warehouse:</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <h5>{rcvwarehouse}</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white my-2 pb-1">
              <div className=" card my-2 p-2">
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      className="form-control"
                      placeholder="Search..."
                      style={{ backgroundColor: "white" }}
                      type="search"
                      value={searchquery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>

              <div className=" responsive-table1" style={{ maxHeight: "62vh" }}>
                <table className="ui table celled" id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>SKU</th>
                      <th>Product</th>
                      <th>Part No.</th>
                      <th>OEM No.</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProducts &&
                      selectedProducts.map((data) => (
                        <tr key={data.id}>
                          <td>{data.product_sku}</td>
                          <td>{data.product_name}</td>
                          <td>{data.part_number}</td>
                          <td>{data.oem_number}</td>
                          <td>{data.quantity}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ marginTop: "5px", display: "flex" }}>
              <select
                className="form-control mb_btn_hide"
                style={{
                  width: "50px",
                  marginRight: "5px",
                  backgroundColor: "white",
                }}
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <Pagination
                size="tiny"
                defaultActivePage={1}
                onPageChange={handlePageChange}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewPurchase;

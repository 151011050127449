import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { cancel_sale, apiKey } from "../Api";
import { FaTrashCan } from "react-icons/fa6";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};
function CancelSale({ openDelete, setOpenDelete, cancelpk, fetchData }) {
  const [otp, setOtp] = useState(["", "", "", ""]);

  const [uniqueNumber, setUniqueNumber] = useState(null);
  const [timer, setTimer] = useState(60);
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const generateUniqueNumber = () => {
    const newNumber = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit number
    setUniqueNumber(newNumber);
  };
  useEffect(() => {
    generateUniqueNumber(); // Generate number on component mount
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          setTimer(60); // Reset timer to 60 seconds
          setUniqueNumber(Math.floor(1000 + Math.random() * 9000)); // Generate a new number
          return 60; // Ensure timer is reset to 60 seconds
        } else {
          return prevTimer - 1; // Decrement the timer
        }
      });
    }, 1000); // Update timer every second

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [timer]); // Depend on timer to keep interval running

  const handleClose = () => {
    setOpenDelete(false);
    setOtp(["", "", "", ""]);
    setTimer(60); // Reset the timer
    setUniqueNumber(Math.floor(1000 + Math.random() * 9000)); // Generate a new unique number
  };

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < 3) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const enteredOtp = otp.join("");
    if (enteredOtp == uniqueNumber) {
      const data = {
        sale_pk: parseInt(cancelpk),
      };

      axios
        .post(cancel_sale, data, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then(() => {
          toast.success(`Sale Cancelled Successfully!`);
          setOpenDelete(false);
          fetchData();

          setOtp(["", "", "", ""]);
          setTimer(60); // Reset the timer
          setUniqueNumber(Math.floor(1000 + Math.random() * 9000));
        })
        .catch((error) => {
          toast.error("Oops!", error);
          setOpenDelete(false);

          setTimeout(() => {
            setOtp(["", "", "", ""]);
            setTimer(60); // Reset the timer
            setUniqueNumber(Math.floor(1000 + Math.random() * 9000));
          }, 600);

          console.error("Failed to cancelled sale", error);
        });
    } else {
      toast.error("Oops! OTP does not match");
      setTimeout(() => {
        setOtp(["", "", "", ""]);
        setTimer(60); // Reset the timer
        setUniqueNumber(Math.floor(1000 + Math.random() * 9000));
        otpRefs[0].current.focus();
      }, 600);
    }
  };

  return (
    <>
      <Toaster position="top-right" />

      <Dialog open={openDelete} onClose={handleClose}>
        <Box sx={style}>
          <form onSubmit={handleDelete}>
            <DialogTitle style={{ color: "red" }}>
              <FaTrashCan style={{ marginTop: "-6px" }} /> Cancel
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure? You won't be able to revert this!
                <div
                  className="card p-3 my-3"
                  style={{ border: "1px solid #e3e3e3", borderRadius: "3px" }}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <h3
                        style={{
                          letterSpacing: "5px",
                          textAlign: "center",
                          marginTop: "6px",
                          alignItems: "center",
                          fontSize: "30px",
                        }}
                      >
                        {uniqueNumber !== null ? (
                          <span style={{ color: "black" }}>{uniqueNumber}</span>
                        ) : (
                          <span style={{ color: "grey" }}>Generating...</span>
                        )}

                        <span
                          style={{
                            fontSize: "12px",
                            color: "red",
                            marginLeft: "10px",
                            letterSpacing: "0.5px",
                          }}
                        >
                          ({timer} seconds)
                        </span>
                      </h3>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-between">
                      {otp.map((num, index) => (
                        <TextField
                          key={index}
                          inputRef={otpRefs[index]}
                          type="text"
                          size="small"
                          variant="outlined"
                          value={num}
                          onChange={(e) => handleChange(index, e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                              if (num === "") {
                                e.preventDefault();
                                if (index > 0) {
                                  handleChange(index - 1, ""); // Clear the previous input
                                  otpRefs[index - 1].current.focus();
                                }
                              } else {
                                handleChange(index, "");
                              }
                            } else if (e.key === "ArrowRight") {
                              if (index < otpRefs.length - 1) {
                                otpRefs[index + 1].current.focus();
                                otpRefs[index + 1].current.setSelectionRange(
                                  0,
                                  1
                                ); // Select the value
                              }
                            } else if (e.key === "ArrowLeft") {
                              if (index > 0) {
                                otpRefs[index - 1].current.focus();
                                otpRefs[index - 1].current.setSelectionRange(
                                  0,
                                  1
                                ); // Select the value
                              }
                            } else if (
                              e.key.length === 1 &&
                              /^[a-zA-Z0-9]$/.test(e.key)
                            ) {
                              handleChange(index, e.key);
                              if (index < otpRefs.length - 1) {
                                otpRefs[index + 1].current.focus();
                              }
                              e.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 1,
                            style: { textAlign: "center" },
                          }}
                          sx={{ mx: 0.5 }}
                          autoFocus={index === 0}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="outlined" color="primary" type="submit">
                Confirm
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </>
  );
}

export default CancelSale;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, getNextSKU, getTypeBrand, add_product } from "../../Api";
import { Dropdown, Form, Input, TextArea } from "semantic-ui-react";
import Button from "@mui/material/Button";
import { MdDoubleArrow } from "react-icons/md";
import { Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import AddBrand from "./AddBrand";
import AddType from "./AddType";
import { FaEdit } from "react-icons/fa";
import { FaTrash } from "react-icons/fa6";
import EditTypeModal from "./EditTypeModal";
import DeleteTypeModal from "./DeleteTypeModal";
import EditBrandModal from "./EditBrandModal";
import DeleteBrandModal from "./DeleteBrandModal";
import SubmitLoader from "../../Components/SubmitLoader";
import toast, { Toaster } from "react-hot-toast";

function AddProducts() {
  const navigate = useNavigate();
  const [getsku, setGetSKU] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    fetchSKU();
    fetchBrandType();
  }, []);

  const fetchSKU = () => {
    axios
      .get(getNextSKU, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setGetSKU(response.data.product_sku);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [product_name, setProduct_name] = useState("");
  const [oem_number, setOEM] = useState("");
  const [part_number, setPartNo] = useState("");
  const [onedrive_url, setOneDriveUrl] = useState("");
  const [product_brand, setProductBrand] = useState("");
  const [product_type, setProductType] = useState("");
  const [product_desc, setDesc] = useState("");
  const [mrp, setMrp] = useState("");
  const [stock_alert, setStockAlert] = useState(1);
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [hsn_code, setHSN] = useState("");
  const [gst_percent, setGST] = useState("");

  const fetchBrandType = () => {
    axios
      .get(getTypeBrand, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setTypes(response.data.product_types);
        setBrands(response.data.product_brands);
      })
      .catch((error) => {
        toast.error(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
      });
  };

  const handleAddProduct = () => {
    setSubmitLoader(true);

    let formattedData = {
      product_sku: getsku,
      product_name: product_name,
      oem_number: oem_number || "",
      part_number: part_number,
      onedrive_url: onedrive_url || "",
      product_brand: product_brand,
      product_type: product_type,
      description: product_desc,
      length: length,
      breadth: breadth,
      height: height,
      weight: weight,
      mrp: parseFloat(mrp) || 0,
      in_stock: 0,
      stock_alert: parseInt(stock_alert),
      hsn_code: hsn_code,
      gst_percent: gst_percent,
    };

    axios
      .post(add_product, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success(
            `A new product with SKU: ${getsku} has been created successfully!`
          );
          setProduct_name("");
          setProductBrand("");
          setProductType("");
          setOneDriveUrl("");
          setPartNo("");
          setOEM("");
          setDesc("");
          setHSN("");
          setGST("");
          setLength("");
          setBreadth("");
          setHeight("");
          setWeight("");
          setMrp("");
          setSubmitLoader(false);
          navigate("/all_products");
          fetchSKU();
        } else {
          toast.error(response.data.message);
          setSubmitLoader(false);
        }

        // fetchSKU(); // Refresh data
      })
      .catch((error) => {
        if (error.response.data.status === 3) {
          toast.error(
            "Product SKU Already Exists! You are assigned with a new SKU."
          );
          fetchSKU();
          setSubmitLoader(false);
        } else {
          toast.error(error.response.data.message);
          setSubmitLoader(false);
        }
      });
  };

  // type and Brand

  const [openaddbrand, setOpenAddBrand] = useState(false);
  const [openaddtype, setOpenAddType] = useState(false);

  const [selectedTypeModal, setSelectedTypeModal] = useState(null);
  const [editTypeModalOpen, setEditTypeModalOpen] = useState(false);
  const [deleteTypeModalOpen, setDeleteTypeModalOpen] = useState(false);

  const handleAddBrand = () => {
    setOpenAddBrand(true);
  };

  const handleAddType = () => {
    setOpenAddType(true);
  };

  const handleEditType = (type) => {
    setSelectedTypeModal(type);
    setEditTypeModalOpen(true);
  };

  const handleDeleteType = (typeId) => {
    const type = types.find((t) => t.id === typeId);
    setSelectedTypeModal(type);
    setDeleteTypeModalOpen(true);
  };

  // For brand update and delete
  const [selectedBrandModal, setSelectedBrandModal] = useState(null);
  const [editBrandModalOpen, setEditBrandModalOpen] = useState(false);
  const [deleteBrandModalOpen, setDeleteBrandModalOpen] = useState(false);

  const handleEditBrand = (brand) => {
    setSelectedBrandModal(brand);
    setEditBrandModalOpen(true);
  };

  const handleDeleteBrand = (brandId) => {
    const brand = brands.find((b) => b.id === brandId);
    setSelectedBrandModal(brand);
    setDeleteBrandModalOpen(true);
  };

  const handleSaveEdit = () => {
    setEditTypeModalOpen(false);
  };

  const handleConfirmDelete = () => {
    setDeleteTypeModalOpen(false);
  };

  const staticOption = {
    key: "static", // Unique key for the static option
    value: "", // Value for the static option
    text: "--Select--",
  };

  const typeoptions = [
    staticOption, // Add the static option here
    ...types.map((type) => ({
      key: type.id,
      value: type.product_type,
      text: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {product_type == type.product_type ? (
            type.product_type
          ) : (
            <>
              {type.product_type}
              <div>
                <FaEdit
                  fontSize="small"
                  style={{ color: "grey" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditType(type);
                  }}
                />

                <FaTrash
                  fontSize="small"
                  style={{ marginLeft: "10px" }}
                  color="red"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteType(type.id);
                  }}
                />
              </div>
            </>
          )}
        </div>
      ),
    })),
  ];

  const brandoptions = [
    staticOption, // Add the static option here
    ...brands.map((brand) => ({
      key: brand.id,
      value: brand.product_brand,
      text: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {product_brand === brand.product_brand ? (
            brand.product_brand // Only show the text if selected
          ) : (
            <>
              {brand.product_brand}
              <div>
                <FaEdit
                  fontSize="small"
                  style={{ color: "grey" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditBrand(brand);
                  }}
                />
                <FaTrash
                  fontSize="small"
                  color="red"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteBrand(brand.id);
                  }}
                />
              </div>
            </>
          )}
        </div>
      ),
    })),
  ];

  return (
    <>
      {submitLoader && <SubmitLoader />}
      <Toaster position="top-right" />
      {selectedTypeModal && (
        <EditTypeModal
          open={editTypeModalOpen}
          handleClose={() => setEditTypeModalOpen(false)}
          type={selectedTypeModal}
          onSave={handleSaveEdit}
          fetchBrandType={fetchBrandType}
        />
      )}
      {selectedTypeModal && (
        <DeleteTypeModal
          open={deleteTypeModalOpen}
          handleClose={() => setDeleteTypeModalOpen(false)}
          type={selectedTypeModal}
          onDelete={handleConfirmDelete}
          fetchBrandType={fetchBrandType}
        />
      )}
      {/* // For brand Delete and Update  */}
      {selectedBrandModal && (
        <EditBrandModal
          open={editBrandModalOpen}
          handleClose={() => setEditBrandModalOpen(false)}
          brand={selectedBrandModal}
          onSave={handleSaveEdit}
          fetchBrandType={fetchBrandType}
        />
      )}
      {selectedBrandModal && (
        <DeleteBrandModal
          open={deleteBrandModalOpen}
          handleClose={() => setDeleteBrandModalOpen(false)}
          brand={selectedBrandModal}
          onDelete={handleConfirmDelete}
          fetchBrandType={fetchBrandType}
        />
      )}
      <AddBrand
        setOpenAddBrand={setOpenAddBrand}
        openaddbrand={openaddbrand}
        fetchBrandType={fetchBrandType}
        setProductBrand={setProductBrand}
      />
      <AddType
        setOpenAddType={setOpenAddType}
        openaddtype={openaddtype}
        fetchBrandType={fetchBrandType}
        setProductType={setProductType}
      />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_products")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Products</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Add Product
              </Typography>
            </Breadcrumbs>
          </div>

          <Form onSubmit={handleAddProduct}>
            <div className="row">
              <div className="col-lg-8 my-2">
                <div className="card p-3">
                  <h5>
                    <MdDoubleArrow id="backbtn" /> Product Details
                  </h5>

                  <div className="row">
                    <div className="col-lg-3 my-1">
                      <label className="input-label">
                        Product SKU (Not Editable)
                      </label>
                      <Input
                        placeholder="KTT-0000XX"
                        disabled
                        value="KTT-0000XX"
                      />
                    </div>
                    <div className="col-lg-9 my-1">
                      <label className="input-label">
                        Product Name <span>*</span>
                      </label>
                      <Input
                        placeholder="Product Name"
                        autoFocus
                        required
                        value={product_name}
                        onChange={(e) => setProduct_name(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-6 my-1">
                      <label className="input-label">
                        Product Type <span>*</span>
                      </label>
                      <div style={{ display: "flex" }}>
                        <Dropdown
                          style={{ width: "100%" }}
                          placeholder="Select Product Type"
                          selection
                          required
                          search
                          value={product_type}
                          onChange={(e, { value }) => setProductType(value)}
                          options={typeoptions}
                        />

                        <Button
                          variant="outlined"
                          style={{ marginLeft: "3px" }}
                          onClick={handleAddType}
                        >
                          Add
                        </Button>
                      </div>
                    </div>

                    <div className="col-lg-6 my-1">
                      <label className="input-label">
                        Product Brand <span>*</span>
                      </label>
                      <div style={{ display: "flex" }}>
                        <Dropdown
                          style={{ width: "100%" }}
                          placeholder="Select Product Type"
                          selection
                          required
                          search
                          value={product_brand}
                          onChange={(e, { value }) => setProductBrand(value)}
                          options={brandoptions}
                        />

                        <Button
                          variant="outlined"
                          style={{ marginLeft: "3px" }}
                          onClick={handleAddBrand}
                        >
                          Add
                        </Button>
                      </div>
                    </div>

                    <div className="col-lg-6 my-1">
                      <label className="input-label">
                        Part Number <span>*</span>
                      </label>
                      <Input
                        required
                        placeholder="Part Number"
                        value={part_number}
                        onChange={(e) => setPartNo(e.target.value)}
                      />

                      <label className="input-label mt-2">Onedrive URL</label>
                      <Input
                        placeholder="Onedrive URL"
                        value={onedrive_url}
                        onChange={(e) => setOneDriveUrl(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-6 my-1">
                      <label className="input-label">OEM Numbers</label>
                      <TextArea
                        placeholder="OEM Numbers"
                        value={oem_number}
                        rows={5}
                        onChange={(e) => setOEM(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3 my-3">
                  <h5>
                    <MdDoubleArrow id="backbtn" /> Product Description
                  </h5>

                  <div className="row my-1">
                    <div className="col-lg-12">
                      <TextArea
                        rows={10}
                        placeholder="Description"
                        value={product_desc}
                        onChange={(e) => setDesc(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-2">
                <div className="card p-3">
                  <h5>
                    <MdDoubleArrow id="backbtn" /> Inventory & Price
                  </h5>

                  <div className="row">
                    <div className="col-6 my-1">
                      <label className="input-label">MRP</label>
                      <Input
                        icon="rupee"
                        iconPosition="left"
                        value={mrp}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || Number(value) > 0) {
                            setMrp(value);
                          }
                        }}
                      />
                    </div>

                    <div className="col-6 my-1">
                      <label className="input-label">Stock Alert</label>
                      <Input
                        icon="exclamation triangle"
                        iconPosition="left"
                        value={stock_alert}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || Number(value) > 0) {
                            setStockAlert(value);
                          }
                        }}
                      />
                    </div>

                    <div className="col-lg-6 my-1">
                      <label className="input-label">HSN</label>
                      <Input
                        value={hsn_code}
                        onChange={(e) => setHSN(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-6 my-1">
                      <label className="input-label">GST Percent</label>
                      <Input
                        icon="percent"
                        iconPosition="right"
                        value={gst_percent}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || Number(value) > 0) {
                            setGST(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="card p-3 my-3">
                  <h5>
                    <MdDoubleArrow id="backbtn" /> Dimensions & Weight
                  </h5>

                  <div className="row my-1">
                    <div className="col-lg-6 my-1">
                      <label className="input-label">Length</label>
                      <br />
                      <Input
                        style={{ width: "80%" }}
                        label={{ basic: true, content: "cm" }}
                        labelPosition="right"
                        placeholder="0.00"
                        value={length}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || Number(value) > 0) {
                            setLength(value);
                          }
                        }}
                      />
                    </div>

                    <div className="col-lg-6 my-1">
                      <label className="input-label">Breadth</label>
                      <br />
                      <Input
                        style={{ width: "80%" }}
                        placeholder="0.00"
                        label={{ basic: true, content: "cm" }}
                        labelPosition="right"
                        value={breadth}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || Number(value) > 0) {
                            setBreadth(value);
                          }
                        }}
                      />
                    </div>

                    <div className="col-lg-6 my-1">
                      <label className="input-label">Height</label>
                      <br />
                      <Input
                        style={{ width: "80%" }}
                        label={{ basic: true, content: "cm" }}
                        labelPosition="right"
                        placeholder="0.00"
                        value={height}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || Number(value) > 0) {
                            setHeight(value);
                          }
                        }}
                      />
                    </div>

                    <div className="col-lg-6 my-1">
                      <label className="input-label">Weight</label>
                      <br />
                      <Input
                        style={{ width: "80%" }}
                        label={{ basic: true, content: "kg" }}
                        labelPosition="right"
                        placeholder="0.00"
                        value={weight}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === "" || Number(value) > 0) {
                            setWeight(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="container text-center my-2">
                  {!product_name ||
                  !product_brand ||
                  !part_number ||
                  (!product_type && !mrp) ? (
                    <>
                      <Button
                        variant="outlined"
                        endIcon={<SendIcon />}
                        disabled
                        style={{ cursor: "pointer" }}
                      >
                        Confirm Product
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        endIcon={<SendIcon />}
                        type="submit"
                      >
                        Confirm Product
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      ;
    </>
  );
}

export default AddProducts;

import React from 'react';
import { Typography, Button, Modal } from "@mui/material";
import { Label } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "850px",
    // width: {
    //     xs: "90%", // Mobile size
    //     sm: "80%", // Small size (tablet)
    //     md: "750px" // Medium and larger (desktop)
    // },
    maxWidth: "950px",
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
};

function SalesProductDashboard({ data, onClose }) {

    const navigate = useNavigate()
    return (
        <Modal open onClose={onClose}>
            <div style={modalStyle} className="bg-white py-3 px-3">
                <div className="d-flex justify-content-between align-items-center mb-2" >
                    <Typography variant="h5" component="h2">
                    Sales Details
                    </Typography>
                    <div className="mb_btn_hide">
                        Total Sales : <Label>{data.length}</Label>
                    </div>

                </div>
                <div
                    className="responsive-table1"
                    style={{
                        maxHeight: "300px",
                        height: "max-content",
                        width: "100%",
                    }}
                >
                    <table className="ui table celled" id="c_table">
                        <thead className="table-head">
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.map((item, index) => (
                                    <tr key={index}>
                                         <td style={{cursor:"pointer"}} onClick={() => navigate(`/view-sale/${item.id}`)} >  {item.id}</td>
                                        <td>{item.date}</td>
                                        <td>{item.customer_name}</td>
                                        <td>{item.quantity}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    color="primary"
                    style={{ marginTop: "20px", float: "right" }}
                >
                    Close
                </Button>
            </div>
        </Modal>
    );
}

export default SalesProductDashboard;

import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import { apiKey, deleteBrand } from '../../Api';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

const DeleteBrandModal = ({ open, handleClose, brand, onDelete, fetchBrandType }) => {
    const handleDelete = () => {
        onDelete(brand.id);
        axios
            .delete(deleteBrand, {
                headers: {
                    "API-Key": apiKey,
                },
                data: {
                    brand_pk: brand.id,
                },
            }).then((response) => {
                if (response.data.status === 1) {
                    toast.success(`Product Brand Deleted successfully`);
                    handleClose();
                    fetchBrandType();
                } else {
                    toast.error(response.data?.message);
                }
            })
            .catch((error) => {
                toast.error(error.response?.data?.message);
                console.error("Failed to delete Brand ", error.response);
            });
    };

    return (
        <>
            <Toaster position="top-right" />
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ ...modalStyle }}>
                    <h2 className='h3'>Confirm Deletion</h2>
                    <p>Are you sure you want to delete the product brand "{brand.product_brand}"?</p>
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleDelete}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default DeleteBrandModal;

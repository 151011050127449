import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { apiKey, add_customer, fetch_customer_category } from "../../../Api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import toast, { Toaster } from "react-hot-toast";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1400,
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CreateCustomer({
  opencustomer,
  handleClosecustomer,
  fetchCustomers,
  setCustomer = () => {},
}) {
  const [customer_name, setCustomer_name] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [contact_name, setContact_name] = useState("");
  const [contact_no, setcontact_no] = useState("");
  const [gst, setGST] = useState("");
  const [city, setCity] = useState("");
  const [alt_contact, setAlt_contact] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [country_code, setCountryCode] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [addresses, setAddresses] = useState([]);

  const modalRef = useRef(null);

  useEffect(() => {
    if (pincode.length === 6) {
      const fetchPincodeData = async () => {
        try {
          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${pincode}`
          );
          if (response.data[0].Status === "Success") {
            // setPostOffices(response.data[0].PostOffice);

            setCity(response.data[0].PostOffice[0].District);
            setState(response.data[0].PostOffice[0].State);
            // setCountryCode(response.data[0].PostOffice[0].Country);
            // setAddress(response.data[0].PostOffice[0].Name);
          } else {
            // setPostOffices([]);
          }
        } catch (error) {
          console.error("Error fetching pincode data:", error);
          // setPostOffices([]);
        }
      };
      fetchPincodeData();
    } else {
      // setPostOffices([]); // Clear post offices if pincode is not valid
    }
  }, [pincode]);

  const handleSaveAddress = () => {
    const newAddress = {
      contact_name,
      contact_no,
      address,
      pincode,
      state,
      city,
      country: country_code,
      primary: addresses.length === 0, // New address is primary by default
    };

    setAddresses([...addresses, newAddress]);

    // Clear the fields after saving the address
    setContact_name("");
    setcontact_no("");
    setAddress("");
    setPincode("");
    setCity("");
    setState("");
    setCountryCode("");
  };

  const handleSetPrimary = (index) => {
    setAddresses(
      addresses.map((address, i) => ({
        ...address,
        primary: i === index ? !address.primary : false,
      }))
    );
  };

  const handleDeleteAddress = (index) => {
    setAddresses(addresses.filter((_, i) => i !== index));
  };

  const handleAddCustomer = (e) => {
    e.preventDefault();

    if (!customer_name) {
      toast.error("Please add Customer name");
    }

    const addresses = {
      contact_name,
      contact_no,
      address,
      pincode,
      state,
      city,
      country: country_code,
      primary: 1,
    };

    let customerData = {
      customer_name: customer_name,
      gst_number: gst,
      email: email,
      phone: contact,
      alternativ_phone: alt_contact,
      category_pk: selectedCategory || 0,
    };

    const data_send = {
      customer_data: customerData,
      shipping_detail: pincode ? [addresses] : [],
    };

    // console.log("data_send : ", data_send)

    axios
      .post(add_customer, data_send, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response?.data.status === 1) {
          console.log(response.data);
          toast.success(
            `A new customer ${customer_name} has been created successfully!`
          );

          setCustomer_name("");
          setEmail("");
          setContact("");
          setCity("");
          setAlt_contact("");
          setPincode("");
          setState("");
          setGST("");
          setAddress("");
          setCountryCode("");
          setAddresses([]);
          setCustomer(response.data.data.pk || "");
          setSelectedCategory("");

          handleClosecustomer(true);

          fetchCustomers();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log("Error While Adding the Customer : ", error);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleClose = (e) => {
    // Close modal only if clicking on the overlay

    handleClosecustomer();

    // Clear the fields after saving the address

    setCustomer_name("");
    setEmail("");
    setContact("");
    setContact_name("");
    setcontact_no("");
    setGST("");

    setContact_name("");
    setcontact_no("");
    setAddress("");
    setPincode("");
    setCity("");
    setState("");
    setCountryCode("");
    setAddresses([]);
  };

  // fetch_customer_category
  const [customerCategory, setCustomerCategory] = useState([]);
  const fetchCustomerCategory = () => {
    axios
      .get(fetch_customer_category, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data && response.data.categories) {
          setCustomerCategory(response.data.categories);
        } else {
          console.error("Unexpected response structure", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching customer category:", error);
      });
  };

  useEffect(() => {
    fetchCustomerCategory();
  }, []);

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        open={opencustomer}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClosecustomer();
          }
        }}
        // onClose={handleClosecustomer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Customers</Typography>
            <Typography sx={{ color: "text.primary" }}>Add Customer</Typography>
          </Breadcrumbs>

          <form onSubmit={handleAddCustomer}>
            <div className="row">
              <div className="col-md-6 my-2">
                <TextField
                  value={customer_name}
                  label="Customer Name"
                  size="small"
                  required
                  fullWidth
                  autoFocus
                  onChange={(e) => {
                    setCustomer_name(e.target.value);
                    setContact_name(e.target.value); // Set contact_name as well
                  }}
                />
              </div>
              <div className="col-md-6 my-2">
                <FormControl fullWidth size="small">
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    label="Category"
                  >
                    {customerCategory.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6 my-2">
                <TextField
                  type="email"
                  value={email}
                  label="Email"
                  size="small"
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-1">
                <TextField
                  value={contact}
                  label="Contact"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    setContact(e.target.value);
                    setcontact_no(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 my-1">
                <TextField
                  value={alt_contact}
                  label="Alt Contact No."
                  size="small"
                  fullWidth
                  onChange={(e) => setAlt_contact(e.target.value)}
                />
              </div>
              <div className="col-md-6 my-1">
                <TextField
                  value={gst}
                  label="GST"
                  size="small"
                  fullWidth
                  onChange={(e) => setGST(e.target.value)}
                />
              </div>
            </div>
            <h6 style={{ marginTop: "5px" }}>Shipping Details</h6>
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-6 my-1">
                    <TextField
                      value={contact_name}
                      label="Contact name"
                      size="small"
                      fullWidth
                      onChange={(e) => setContact_name(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 my-1">
                    <TextField
                      value={contact_no}
                      label="Contact Number"
                      size="small"
                      fullWidth
                      onChange={(e) => setcontact_no(e.target.value)}
                    />
                  </div>

                  <div className="col-md-3 col-6 my-1">
                    <TextField
                      value={pincode}
                      label="Pincode"
                      size="small"
                      fullWidth
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3 col-6 my-1">
                    <TextField
                      value={city}
                      label="City"
                      size="small"
                      fullWidth
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>

                  <div className="col-md-3 col-6 my-1">
                    <TextField
                      value={state}
                      label="State"
                      size="small"
                      fullWidth
                      onChange={(e) => setState(e.target.value)}
                    />
                  </div>

                  <div className="col-md-3 col-6 my-1">
                    <TextField
                      value={country_code}
                      label="Country Code"
                      size="small"
                      fullWidth
                      onChange={(e) => setCountryCode(e.target.value)}
                    />
                  </div>

                  <div className="col-md-12 my-1">
                    <TextField
                      multiline
                      rows={3}
                      value={address}
                      label="Address"
                      size="small"
                      fullWidth
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ float: "right" }}>
              <Button
                style={{ marginTop: "8px", marginRight: "5px" }}
                onClick={handleClose}
                color="error"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                style={{ marginTop: "8px" }}
                type="submit"
                variant="contained"
              >
                Create Customer
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default CreateCustomer;

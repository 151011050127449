import React, { useState } from "react";
import axios from "axios";
import { apiKey, add_row } from "../../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import toast, { Toaster } from "react-hot-toast";
import Error from "../../../Messages/Error";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { IoClose } from "react-icons/io5";
import { Breadcrumbs } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "600px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

function AddNewRow({ setOpenModal, openModal, ware_id, warehouse, fetchData }) {
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [newrow, setRowName] = useState("");

  const handleClose = () => {
    setOpenModal(false);
    setError("");
    setRowName("");
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    if (newrow && newrow.length == 0) {
      toast.error("Row cannot be empty!");
      return;
    }

    const formattedData = {
      warehouse_pk: parseInt(ware_id),
      rows: newrow,
    };

    axios
      .post(add_row, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        console.log(response);

        if (response.data.status == 1) {
          // setOpenSuccess(true);
          toast.success(`New Row Added Successfully!`);
          setRowName("");
          fetchData();
          handleClose();
        } else {
          toast.error(response.data.message);
          // setOpenError(true);
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );
        // setOpenError(true);
      });
  };

  return (
    <>
      <Toaster position="top-right" />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Warehouse</Typography>

            <Typography sx={{ color: "text.primary" }}>Add New Row</Typography>
          </Breadcrumbs>

          <IoClose className="closeModalBtn" onClick={handleClose} />

          <div
            style={{
              maxHeight: "30vh",
              marginTop: "8px",
            }}
          >
            <Autocomplete
              multiple
              id="rows"
              value={newrow}
              options={[]}
              freeSolo
              onChange={(event, newValue) => setRowName(newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    key={index}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  rows={4}
                  size="small"
                  variant="outlined"
                  label="Rows"
                  placeholder="Add Row"
                />
              )}
            />
          </div>

          <div className="d-flex justify-content-end mt-1">
            <Button
              style={{ marginTop: "8px", marginLeft: "2px" }}
              variant="outlined"
              color="primary"
              onClick={handleAddRow}
            >
              Add Row
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddNewRow;

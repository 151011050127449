import React, { useState, useEffect } from "react";

function Loader({ loading }) {
  const [meterWidth, setMeterWidth] = useState("0%");
  const [hidden, setHidden] = useState("block");

  useEffect(() => {
    if (loading) {
      setMeterWidth("80%");
    } else if (!loading) {
      setMeterWidth("100%");
      setTimeout(() => {
        setHidden("hidden");
      }, 1000);
    }
  }, [loading]);

  return (
    <>
      <div
        className="meter"
        style={{
          visibility: hidden,
          width: meterWidth,
          transition: loading ? "width 5s" : "width 0.5s",
        }}
      ></div>

    </>
  );
}

export default Loader;
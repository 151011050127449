import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { apiKey, adjustmentHostory, adjustmentBarcodes } from "../../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ViewAdjustmentModal from "./ViewAdjustmentModal.js";
import PrintBarcode from "../../Purchase/PrintBarcode";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useScreenWidth } from "../../../useScreenWidth.js";
import CustomLoader from "../../../Components/Loader";
import { Icon, Label, Pagination } from "semantic-ui-react";
import toast, { Toaster } from "react-hot-toast";
import FilterSelectionModal from "../../../Components/FilterModal/FilterSelectionModal.js";
import FilterSelectionModalByWarehouse from "../../../Components/FilterModal/FilterSelectionModalByWarehouse.js";

function Adjustments() {
  const isWideScreen = useScreenWidth();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");

  // Filter
  const [types, setTypes] = useState([
    { value: 1, label: "stock in" },
    { value: 2, label: "stock out" },
  ]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  // Warehouse Filter
  const [selectedWarehouseIds, setSelectedWarehouseIds] = useState(null);

  const navigate = useNavigate();

  const debouncedFetchData = useCallback(
    debounce((page, query) => {
      fetchData(page, query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setPageSize(25);
    setCurrentPage(1);
    debouncedFetchData(1, query);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, selectedTypes, selectedWarehouseIds]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      selectedType: selectedTypes || null,
      selectedWarehouses: selectedWarehouseIds || null,
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };
    axios
      .post(adjustmentHostory, data, { headers })
      .then((response) => {
        // console.log(response.data.data)
        setdata(response.data.data);
        setTotalPages(response.data.total_pages);

        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        console.log(error.response);
        setLoading(false);
      });
  };

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  // ========= handle View Adjustment Modal

  const [openAdjustmentModal, setOpenAdjustmentModal] = useState(false);
  const [selectedAdjustmentDetail, setSelectedAdjustmentDetail] = useState();

  const handleViewAdjustmentModal = (allDetail) => {
    setSelectedAdjustmentDetail(allDetail);
    setOpenAdjustmentModal(true);
  };

  // ======= handle Print Barcode Of Adjustment

  const [openModalBarcode, setOpenModalBarcode] = useState(false);
  const [barcodes, setBarcodes] = useState([]);

  const handlePrintBarcode = (adjustmentId) => {
    try {
      axios
        .get(adjustmentBarcodes, {
          params: {
            adjustment_pk: adjustmentId,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            setBarcodes(response.data.products);

            const barcodeProducts = response.data.products;

            const barcodeData = barcodeProducts.map((product) => ({
              sku: product.sku,
              series: product.series,
              part_number: product.part_number,
              name: product.name,
              brand: product.brand,
            }));

            // Wrap in an array to maintain consistency
            setBarcodes(barcodeData);
            setOpenModalBarcode(true);
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading && <CustomLoader />}
      <Toaster position="top-right" />
      <ViewAdjustmentModal
        open={openAdjustmentModal}
        setOpenAdjustmentModal={setOpenAdjustmentModal}
        selectedAdjustmentDetail={selectedAdjustmentDetail}
      />

      {/* PrintRackBarcode Modal */}
      <PrintBarcode
        handleCloseBarcode={setOpenModalBarcode}
        openBarcode={openModalBarcode}
        barcodes={barcodes}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Adjustments
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-8 col-lg-4">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-4 col-lg-8"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-stock-adjustment")}
                >
                  Add New
                </Button>
              </div>
            </div>
          </div>

          {isWideScreen ? (
            <>
              <div className="responsive-table1">
                <table className="ui table celled " id="c_table">
                  <thead className="table-head">
                    <tr>
                      <th>ID</th>
                      <th>
                        <FilterSelectionModalByWarehouse
                          title="Warehouse"
                          selectedWarehouseId={selectedWarehouseIds}
                          onSelect={setSelectedWarehouseIds}
                        />
                      </th>
                      <th>
                        <FilterSelectionModal
                          title="Adjustment Type"
                          options={types}
                          selectedOptions={selectedTypes}
                          onSelect={setSelectedTypes}
                        />
                      </th>
                      <th>Notes</th>
                      <th>Products</th>
                      <th>Created By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      <>
                        {data &&
                          data.map((data) => (
                            <>
                              <tr>
                                <td style={{ width: "60px" }}>{data.id}</td>
                                <td>{data.warehouse?.ware_house || "-"}</td>
                                <td style={{ width: "150px" }}>
                                  <Label
                                    style={{ width: "75px" }}
                                    className={`label ${
                                      data.action == "stock in"
                                        ? "green_label"
                                        : data.action == "stock out"
                                        ? "red_label"
                                        : "blue_label"
                                    }`}
                                  >
                                    {/* <LabelDetail>Co-worker</LabelDetail> */}
                                    {data.action ? data.action : "-"}
                                  </Label>
                                </td>
                                <td>{data.note ? data.note : "-"}</td>
                                <td>
                                  {data.total_products
                                    ? data.total_products
                                    : "-"}
                                </td>
                                <td>{data.user_name ? data.user_name : "-"}</td>
                                <td
                                  style={{
                                    width: "200px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="success"
                                    onClick={() =>
                                      handleViewAdjustmentModal(data)
                                    }
                                  >
                                    View
                                  </Button>

                                  <Button
                                    style={{ marginLeft: "5px" }}
                                    variant="outlined"
                                    disabled={
                                      data.action === "stock out" ||
                                      data.print_status === 0
                                    }
                                    size="small"
                                    color="error"
                                    onClick={() => handlePrintBarcode(data.id)}
                                  >
                                    Print
                                  </Button>
                                </td>
                              </tr>
                            </>
                          ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan="7">
                            {!loading ? (
                              <div className="notfound_img_div">
                                <div className="notfound_img"></div>
                              </div>
                            ) : (
                              <div className="notfound_img_div">
                                <div className="loadingfound_img"></div>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="card p-2 responsive-mobile-table">
                {data.length > 0 ? (
                  <>
                    {data &&
                      data.map((data) => (
                        <>
                          <div
                            className="product-card"
                            id="product_card"
                            onClick={() => handleViewAdjustmentModal(data)}
                          >
                            <div className="py-1 px-2">
                              <h5 className="product-card-sku">#{data.id}</h5>
                              <h5 className="product-card-name">
                                {data.warehouse?.ware_house || "-"}
                              </h5>
                              <h5 className="warehouse-card-address">
                                Total Products:{" "}
                                <b style={{ color: "black" }}>
                                  {data.total_products
                                    ? data.total_products
                                    : "-"}
                                </b>
                              </h5>
                            </div>
                            <div>
                              <h5 className="my-1 warehouse-card-label">
                                <Label
                                  className="label"
                                  style={{ width: "75px", textAlign: "center" }}
                                  color={
                                    data.action == "stock in" ? "green" : "red"
                                  }
                                >
                                  {/* <LabelDetail>Co-worker</LabelDetail> */}
                                  {data.action ? data.action : "-"}
                                </Label>
                              </h5>
                            </div>
                          </div>
                        </>
                      ))}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      <div className="notfound_img_div">
                        <div className="notfound_img"></div>
                      </div>
                    ) : (
                      <div className="notfound_img_div">
                        <div className="loadingfound_img"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "2px", display: "flex" }}>
            <select
              className="form-control mb_btn_hide"
              style={{
                width: "50px",
                marginRight: "5px",
                backgroundColor: "white",
              }}
              value={pageSize}
              onChange={handlePageSizeChange}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <Pagination
              size="tiny"
              defaultActivePage={1}
              onPageChange={handlePageChange}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Adjustments;

import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { import_product, apiKey } from "../../Api";
import toast, { Toaster } from "react-hot-toast";
import { Box, Typography } from "@mui/material";
import { MdClear } from "react-icons/md";
import DownloadSample from "./SampleProducts.xlsx";

function ImportProductsModal({ openImport, setOpenImport, fetchData }) {
  const [data, setData] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpenImport(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setFileSelected(false);
      setFileInfo(null);
      return;
    }

    if (
      ![
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.ms-excel", // .xls
        "text/csv", // .csv
      ].includes(file.type)
    ) {
      setFileSelected(false);
      setFileInfo(null);
      toast.error("Only .xls or .csv file format are allowed");

      // Reset the file input field
      e.target.value = "";
      return;
    }

    setFileSelected(true);
    setFileInfo({ name: file.name, size: file.size });

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headers = jsonData[0]; // First row as headers
      const products = jsonData.slice(1).map((row) => {
        const product = {};
        headers.forEach((header, index) => {
          product[header] = row[index];
        });
        return product;
      });

      setData(products);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleImportProduct = () => {
    if (!fileSelected) {
      toast.error("Please select a file before uploading.");
      return;
    }

    setLoading(true);

    axios
      .post(import_product, data, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setLoading(false);

        if (response.data.status === 1) {
          toast.success(`Products imported successfully!`);
          fetchData();
          setOpenImport(false);
          setFileInfo(null);
          setFileSelected(false);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.data.status === 3) {
          toast.error("Product SKU Already Exists! ");
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const handleClearFile = () => {
    setFileSelected(false);
    setFileInfo(null);
    setData([]);
  };
  return (
    <>
      <Toaster position="top-right" />

      <Dialog open={openImport} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontWeight: "bold" }}>Import Products</DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <Typography
              style={{ color: "grey", fontSize: "13px" }}
              sx={{ mb: 2 }}
            >
              Select a file to import products. Only .xls or .csv formats are
              allowed.{" "}
              <a href={DownloadSample} download={DownloadSample}>
                Download Sample
              </a>
            </Typography>

            <TextField
              type="file"
              style={{ cursor: "pointer" }}
              onChange={handleFileChange}
              required
              accept=".xls,.csv"
              fullWidth
              variant="outlined"
            />
            {fileInfo && (
              <div className="card p-2">
                <Typography sx={{ mt: 1 }}>
                  File selected: {fileInfo.name} (
                  {(fileInfo.size / 1024).toFixed(2)} KB)
                </Typography>{" "}
                <MdClear
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "20px",
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={handleClearFile}
                />
              </div>
            )}
          </Box>

          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              style={{ backgroundColor: "#e6e6e6", borderRadius: "10px" }}
              mt={1}
            >
              <div class="loadingg">
                <svg class="pl" width="240" height="240" viewBox="0 0 240 240">
                  <circle
                    class="pl__ring pl__ring--a"
                    cx="120"
                    cy="120"
                    r="105"
                    fill="none"
                    stroke="#000"
                    stroke-width="20"
                    stroke-dasharray="0 660"
                    stroke-dashoffset="-330"
                    stroke-linecap="round"
                  ></circle>
                  <circle
                    class="pl__ring pl__ring--b"
                    cx="120"
                    cy="120"
                    r="35"
                    fill="none"
                    stroke="#000"
                    stroke-width="20"
                    stroke-dasharray="0 220"
                    stroke-dashoffset="-110"
                    stroke-linecap="round"
                  ></circle>
                  <circle
                    class="pl__ring pl__ring--c"
                    cx="85"
                    cy="120"
                    r="70"
                    fill="none"
                    stroke="#000"
                    stroke-width="20"
                    stroke-dasharray="0 440"
                    stroke-linecap="round"
                  ></circle>
                  <circle
                    class="pl__ring pl__ring--d"
                    cx="155"
                    cy="120"
                    r="70"
                    fill="none"
                    stroke="#000"
                    stroke-width="20"
                    stroke-dasharray="0 440"
                    stroke-linecap="round"
                  ></circle>
                </svg>
                <span className="loading-gradient-text">
                  Uploading! Please wait...
                </span>
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading && loading === true}
            onClick={handleClose}
            variant="outlined"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleImportProduct}
            disabled={!fileSelected || loading}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImportProductsModal;
